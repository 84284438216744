import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import useFormValidation from "hooks/useFormValidation";
import { validateForgotPassword } from "lib/form-validation";
import { errorHandler, showToast } from "helper-methods";
import { forgotPassword } from "http/http-calls";

const ForgotPasswordPage = () => {
  // states
  const [loading, setLoading] = useState(false);

  // hooks
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
  } = useFormValidation(
    {
      email: "",
    },
    validateForgotPassword,
    _onSubmit
  );
  const navigate = useNavigate();

  // handle submit
  async function _onSubmit() {
    try {
      setLoading(true);

      const res = await forgotPassword({ handle: formValues["email"] });

      if (!res?.error) {
        showToast(
          "Password reset link has been sent. Please check your email",
          "success",
          2000
        );
        navigate("/login");
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          <div className="leftWrapper">
            <div className="leftInnerWrapper">
              <h2>Forgot Password?</h2>
              <p className="text-secondary">
                Enter your email to receive a code to reset your password
              </p>

              <Form>
                {/* username */}
                <div className="form-group">
                  <Label>Email Address</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      name="email"
                      value={formValues["email"]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    <InputGroupText>
                      <i className="far fa-envelope" />
                    </InputGroupText>
                  </InputGroup>

                  {formErrors["email"] ? (
                    <p className="form-error">{formErrors["email"]}</p>
                  ) : null}
                </div>

                {/* submit button */}
                <Button
                  color="primary"
                  className="btn-submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Spinner />
                      Reset Password
                    </div>
                  ) : (
                    "Reset Password"
                  )}
                </Button>

                {/*infoText*/}
                <div className="text-center fs-14 mt-3 fw-medium">
                  Already have an account?
                  <Link to="/login" className="ms-1">
                    Sign In
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <div className="rightWrapper">
            {/* logo */}
            <img
              src={require("../../assets/img/logo-white.png")}
              alt="Brand Logo"
              className="companyLogo"
            />
          </div>
        </div>

        <PublicFooter />
      </div>
    </>
  );
};

export default ForgotPasswordPage;
