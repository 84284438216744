import { Card } from "reactstrap";

const Summary = ({ heading, content }) => {
  return (
    <section>
      <Card body>
        <h6>{heading}</h6>
        <div className="paraBox">
          <p>{content}</p>
        </div>
      </Card>
    </section>
  );
};

export default Summary;
