import { Button } from "reactstrap";

const LeadCardActions = ({ isLeadLocked, leadId, onUnlockLead, loading }) => {
  return (
    <div className="inlineBtnWrapper">
      {isLeadLocked ? (
        <Button disabled={loading} color="danger" onClick={onUnlockLead}>
          <img
            src={require("../../../assets/img/lock.svg").default}
            alt=""
            width="15px"
            className="me-2"
          />
          Unlock Now
        </Button>
      ) : (
        <Button color="success" id={`UncontrolledPopover-${leadId}`}>
          <img
            src={require("../../../assets/img/unlock.svg").default}
            alt=""
            width="15px"
            className="me-2"
          />
          View Profile
        </Button>
      )}
    </div>
  );
};

export default LeadCardActions;
