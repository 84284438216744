import { createAction } from "@reduxjs/toolkit";

const setReportData = createAction("setReportData");
const setNextRoundDate = createAction("setNextRoundDate");
const setCurrentRound = createAction("setCurrentRound");
const setNoResponseBureauData = createAction("setNoResponseBureauData");
const setNoResponseTradeLineData = createAction("setNoResponseTradeLineData");
const setSentLetters = createAction("setSentLetters");
const setBureauName = createAction("setBureauName");
const setPreviewData = createAction("setPreviewData");
const resetPreviewData = createAction("resetPreviewData");
const resetBureauName = createAction("resetBureauName");
const resetReportData = createAction("resetReportData");

export {
  setReportData,
  setNextRoundDate,
  setCurrentRound,
  setNoResponseBureauData,
  setNoResponseTradeLineData,
  setSentLetters,
  setBureauName,
  resetBureauName,
  resetPreviewData,
  setPreviewData,
  resetReportData,
};
