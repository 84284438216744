import { PDFViewer } from "components/common";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const PreviewLetterModal = ({ isOpen, title, previewLink, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable fullscreen centered>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <PDFViewer className="previewLetterPDF" url={previewLink} />
      </ModalBody>
    </Modal>
  );
};

export default PreviewLetterModal;
