import { CardTitle } from "reactstrap";

const LeadCardHeader = ({ leadName, leadCredits, leadAvatar }) => {
  return (
    <div className="leadCardHeader">
      <div className="leadCardHeaderImg">
        <img src={leadAvatar} alt="" />
      </div>

      <CardTitle>{leadName}</CardTitle>

      <strong className="text-success">{leadCredits}</strong>
    </div>
  );
};

export default LeadCardHeader;
