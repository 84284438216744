import { Button } from "reactstrap";
import { useEffect } from "react";
import useFilters from "hooks/useFilters";
import { SentLettersFilterForm } from ".";

const SentLettersFilter = ({
  letterType,
  tradeLines,
  onApplyFilter,
  onClearFilter,
}) => {
  const letterTypeKey = letterType;
  const {
    filters,
    isFilterApplied,
    setFilters,
    onDatesChange,
    onChangeFilter,
    onRemoveFilters,
  } = useFilters(
    {
      level: "",
      [letterTypeKey]: "",
      isNoResponse: "",
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
    onApplyFilter,
    onClearFilter
  );

  // clear all filters on tab change
  useEffect(() => {
    setFilters({
      level: "",
      [letterTypeKey]: "",
      isNoResponse: "",
      dateRange: {
        startDate: null,
        endDate: null,
      },
    });
  }, [letterTypeKey, setFilters]);

  return (
    <div className="filterWrapper">
      <div className="filterIcon">
        <i className="fas fa-filter" />
      </div>

      <SentLettersFilterForm
        tradeLines={tradeLines}
        letterType={letterType}
        filters={filters}
        onChangeFilter={onChangeFilter}
        onDatesChange={onDatesChange}
      />

      {isFilterApplied(filters) ? (
        <Button
          outline
          color="danger"
          className="btn-eraser"
          onClick={onRemoveFilters}
        >
          <i className="fas fa-eraser fa-lg" />
        </Button>
      ) : null}
    </div>
  );
};

export default SentLettersFilter;
