import { useEffect, useState } from "react";
import { deleteCard, getAllCards, updateDefaultCard } from "http/http-calls";
import { errorHandler, showToast } from "helper-methods";

const usePaymentMethod = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeletingCard, setIsDeletingCard] = useState(false);

  // make card default
  const _makeDefaultCard = async (id) => {
    try {
      setLoading(true);
      const res = await updateDefaultCard(id);

      if (!res?.error) {
        showToast("Default card updated successfully", "success", 2000);
        _getCards();
      }
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  // delete card
  const _deleteCard = async (id, cb) => {
    try {
      setIsDeletingCard(true);
      const res = await deleteCard(id);
      if (!res?.error) {
        showToast("Card deleted successfully", "success", 2000);
        _getCards();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsDeletingCard(false);
      cb();
    }
  };

  // get all cards
  const _getCards = async () => {
    try {
      setLoading(true);
      const res = await getAllCards();
      if (!res?.error) setCards(res?.cards);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // load all cards on mount
  useEffect(() => {
    _getCards();
  }, []);

  return {
    cards,
    loading,
    isDeletingCard,
    makeDefaultCard: _makeDefaultCard,
    deleteCard: _deleteCard,
    getCards: _getCards,
  };
};

export default usePaymentMethod;
