import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import { LeadPopupHeader, LeadPopupInfoList } from ".";

const LeadProfilePopup = ({
  id,
  name,
  avatar,
  credits,
  circuit,
  state,
  caseTypes,
  totalDefendants,
  onViewDetails,
}) => {
  const popupInfoListProps = { circuit, state, caseTypes, totalDefendants };

  return (
    <UncontrolledPopover
      placement="right"
      className="leadsPopover"
      trigger="legacy"
      target={`UncontrolledPopover-${id}`}
    >
      <PopoverHeader tag="div">
        <LeadPopupHeader
          leadAvatar={avatar}
          leadName={name}
          leadCredits={credits}
        />
      </PopoverHeader>

      <PopoverBody>
        <LeadPopupInfoList {...popupInfoListProps} />

        <Button
          color="primary"
          className="mx-auto mt-3"
          onClick={onViewDetails.bind(this, id)}
        >
          See Details
        </Button>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default LeadProfilePopup;
