import {
  Button,
  Card,
  Col,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import useFormValidation from "hooks/useFormValidation";
import { validateProfile } from "lib/form-validation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CityOptions,
  StateOptions,
  YearOptions,
} from "components/form-options";

const BasicInfo = ({ loading, onUpdateProfile }) => {
  // states
  const [showFullSSN, setShowFullSSN] = useState(false);

  // hooks
  const { user } = useSelector((state) => state?.userCredential || {});
  const {
    formErrors,
    formValues,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    setFormValues,
  } = useFormValidation(
    {
      name: "",
      email: "",
      phone: "",
      year: "",
      ssn: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    },
    validateProfile,
    () => onUpdateProfile(formValues)
  );

  // set initial form values
  useEffect(() => {
    if (user) {
      setFormValues({
        name: user?.name?.full || "",
        email: user?.email || "",
        phone: user?.phone || "",
        year: user?.birthYear || "",
        ssn: user?.ssn || "",
        address:
          user?.currentMailingAddress?.address ||
          user?.currentMailingAddress?.streetLine2 +
            " " +
            user?.currentMailingAddress?.streetLine1 ||
          "",
        city: user?.currentMailingAddress?.city || "",
        state: user?.currentMailingAddress?.state || "",
        zip: user?.currentMailingAddress?.zip || "",
      });
    }
  }, [setFormValues, user]);

  return (
    <>
      <Card body>
        <div>
          <h6>Basic Info</h6>

          <Row>
            <Col md="6" lg="4">
              {/* name */}
              <div className="form-group">
                <Label>Name</Label>
                <Input
                  placeholder="Enter"
                  name="name"
                  value={formValues["name"]}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />

                {formErrors["name"] ? (
                  <p className="form-error">{formErrors["name"]}</p>
                ) : null}
              </div>
            </Col>

            <Col md="6" lg="4">
              {/* Email */}
              <div className="form-group">
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Enter"
                  name="email"
                  value={formValues["email"]}
                  readOnly
                />
              </div>
            </Col>

            <Col md="6" lg="4">
              {/* phone number */}
              <div className="form-group">
                <Label>Phone Number</Label>
                <Input
                  placeholder="Enter"
                  name="phone"
                  value={formValues["phone"]}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />

                {formErrors["phone"] ? (
                  <p className="form-error">{formErrors["phone"]}</p>
                ) : null}
              </div>
            </Col>

            <Col md="6" lg="4">
              {/* Year of Birth */}
              <div className="form-group">
                <Label>Year of Birth</Label>
                <Input
                  type="select"
                  name="year"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  value={formValues["year"]}
                >
                  <YearOptions />
                </Input>

                {formErrors["year"] ? (
                  <p className="form-error">{formErrors["year"]}</p>
                ) : null}
              </div>
            </Col>

            <Col md="6" lg="4">
              {/* Last 4 SSN */}
              <div className="form-group">
                <Label className="labelTooltip">
                  SSN Number
                  <i
                    className="fas fa-shield-alt"
                    id="UncontrolledTooltipExample"
                  ></i>
                  <UncontrolledTooltip
                    placement="right"
                    autohide={false}
                    target="UncontrolledTooltipExample"
                  >
                    Your SSN is used to connect your credit report to your
                    profile within the dashboard. This data is both secure and
                    encrypted.
                  </UncontrolledTooltip>
                </Label>
                <InputGroup>
                  <Input
                    placeholder="Enter"
                    // type={`${showPassword ? "text" : "password"}`}
                    type="text"
                    name="ssn"
                    value={
                      user?.ssn
                        ? showFullSSN
                          ? formValues["ssn"]
                          : "XXXXX" + formValues["ssn"]?.slice(-4)
                        : ""
                    }
                    onFocus={(e) => (e.target.value = formValues["ssn"])}
                    onChange={(e) => {
                      handleInputChange(e);
                      if (!showFullSSN) {
                        setShowFullSSN(true);
                      }
                    }}
                    onBlur={(e) => {
                      handleInputBlur(e);
                      if (!formErrors["ssn"]) {
                        setShowFullSSN(false);
                      }
                    }}
                  />
                </InputGroup>

                {formErrors["ssn"] ? (
                  <p className="form-error">{formErrors["ssn"]}</p>
                ) : null}
              </div>
            </Col>
          </Row>

          {/* Current Address */}
          <div className="form-group">
            <Label>Current Address</Label>
            <Input
              type="textarea"
              placeholder="Enter"
              name="address"
              value={formValues["address"]}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />

            {formErrors["address"] ? (
              <p className="form-error">{formErrors["address"]}</p>
            ) : null}
          </div>

          <Row className="gy-3 gy-xl-0">
            <Col lg="6" xl="4">
              {/* State */}
              <div className="form-group mb-0">
                <Label>State</Label>
                <Input
                  type="select"
                  name="state"
                  value={formValues["state"]}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                >
                  <StateOptions />
                </Input>

                {formErrors["state"] ? (
                  <p className="form-error">{formErrors["state"]}</p>
                ) : null}
              </div>
            </Col>

            <Col lg="6" xl="4">
              {/* City */}
              <div className="form-group">
                <Label>City</Label>
                <Input
                  type="select"
                  name="city"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  value={formValues["city"]}
                >
                  <CityOptions state={formValues["state"]} />
                </Input>

                {formErrors["city"] ? (
                  <p className="form-error">{formErrors["city"]}</p>
                ) : null}
              </div>
            </Col>

            <Col lg="6" xl="4">
              {/* Zip */}
              <div className="form-group mb-0">
                <Label>Zip</Label>
                <Input
                  placeholder="Enter"
                  name="zip"
                  value={formValues["zip"]}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />

                {formErrors["zip"] ? (
                  <p className="form-error">{formErrors["zip"]}</p>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      {/* submit button */}
      <div className="inlineBtnWrapper mt-5">
        <Button
          color="primary"
          className="btn-submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Spinner />
              Save
            </div>
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </>
  );
};

export default BasicInfo;
