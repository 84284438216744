import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";

const CreditItems = ({ creditItems }) => {
  return (
    <section>
      <Card>
        <CardHeader>
          <CardTitle>Credit Items</CardTitle>
        </CardHeader>

        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>TransUnion</th>
                <th>Experian</th>
                <th>Equifax</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Positive</td>
                <td>{creditItems?.transUnion?.postive || 0}</td>
                <td>{creditItems?.experian?.postive || 0}</td>
                <td>{creditItems?.equifax?.postive || 0}</td>
              </tr>
              <tr>
                <td>Disputed</td>
                <td>{creditItems?.transUnion?.dispute || 0}</td>
                <td>{creditItems?.experian?.dispute || 0}</td>
                <td>{creditItems?.equifax?.dispute || 0}</td>
              </tr>
              <tr>
                <td>Repaired</td>
                <td>{creditItems?.transUnion?.repaired || 0}</td>
                <td>{creditItems?.experian?.repaired || 0}</td>
                <td>{creditItems?.equifax?.repaired || 0}</td>
              </tr>
            </tbody>
          </Table>

          {/* pagination */}
          {/* <PaginatedItems itemsPerPage={4} /> */}
        </CardBody>
      </Card>
    </section>
  );
};

export default CreditItems;
