import { List } from "reactstrap";
import PaymentCard from "./PaymentCard";

const PaymentCards = ({
  cards,
  onSelectCard,
  onDeleteCard,
  selectedCard,
  isDeletingCard,
}) => {
  return (
    <List>
      {cards?.length
        ? cards?.map((card) => (
            <PaymentCard
              key={card?.id}
              card={card}
              onDeleteCard={onDeleteCard}
              onSelectCard={onSelectCard}
              selectedCard={selectedCard}
              isDeletingCard={isDeletingCard}
            />
          ))
        : null}
    </List>
  );
};

export default PaymentCards;
