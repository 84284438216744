import { Row } from "reactstrap";
import { LeadCard } from "./lead-card";
import { useState } from "react";
import {
  AdditionalCreditsModal,
  UnlockLeadsModal,
} from "components/modals/Attorney";
import { errorHandler } from "helper-methods";
import { unlockLead } from "http/http-calls";
import { useDispatch } from "react-redux";
import { updateUserData } from "redux/actions";

const LeadCards = ({ leads, onUnlockLead }) => {
  const [loading, setLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [unlockLeadModal, setUnlockLeadModal] = useState(false);
  const [addCreditModal, setAddCreditModal] = useState(false);

  const dispatch = useDispatch();

  // function to unlock a lead
  const _unlockLead = async (leadId) => {
    try {
      setLoading(true);
      const res = await unlockLead(leadId);
      if (!res?.error) {
        onUnlockLead();
        dispatch(updateUserData(res?.user));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _toggleUnlockLeadModal = (isOpen = false) => {
    setUnlockLeadModal(isOpen);
  };

  const _toggleAddCreditModal = (isOpen = false) => {
    setAddCreditModal(isOpen);
  };

  const _handleUnlockLead = (lead, hasEnoughCredits) => {
    if (hasEnoughCredits) {
      _unlockLead(lead?._id);
      return;
    }

    _toggleUnlockLeadModal(true);
    setSelectedLead(lead);
  };

  const _handleUnlock = () => {
    _toggleUnlockLeadModal();
    _toggleAddCreditModal(true);
  };

  return (
    <>
      <section className="leadCards">
        {/* lead cards list */}
        <Row className="gy-3">
          {leads?.length
            ? leads.map((lead) => (
                <LeadCard
                  key={lead?._id}
                  lead={lead}
                  loading={loading}
                  onUnlockLead={(hasEnoughCredits) =>
                    _handleUnlockLead(lead, hasEnoughCredits)
                  }
                />
              ))
            : null}
        </Row>
      </section>

      {unlockLeadModal ? (
        <UnlockLeadsModal
          isOpen={unlockLeadModal}
          toggle={() => _toggleUnlockLeadModal()}
          lead={selectedLead}
          onUnlock={_handleUnlock}
        />
      ) : null}

      {addCreditModal ? (
        <AdditionalCreditsModal
          isOpen={addCreditModal}
          toggle={() => _toggleAddCreditModal()}
        />
      ) : null}
    </>
  );
};

export default LeadCards;
