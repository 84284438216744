import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledCollapse,
} from "reactstrap";
import { errorHandler } from "helper-methods";
import { saveLetterAsPDF } from "http/http-calls";
import { useSelector } from "react-redux";
import PreviewLetterCard from "./PreviewLetterCard";
import PreviewLetterModal from "components/modals/PreviewLetterModal";

const LettersPreviewWrapper = () => {
  // states
  const [previewData, setPreviewData] = useState([]);
  const [currentPreview, setCurrentPreview] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { preview } = useSelector((state) => state?.reportData || {});
  const modalTitle = `${
    currentPreview?.tradeLineName || currentPreview?.bureauName
  } Preview Letter`;

  // handle view preview
  const _handleViewPreview = (preview) => {
    setIsOpenModal(true);
    setCurrentPreview(preview);
  };

  // fetch preview letters data on mount
  useEffect(() => {
    const _fetchData = async () => {
      try {
        const res = await saveLetterAsPDF(preview?.previewId || preview?._id);
        if (!res?.error) {
          setPreviewData(res.data);
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    _fetchData();
  }, [preview?._id, preview?.previewId]);

  if (!previewData?.length) return <></>;

  return (
    <>
      <section>
        <Card className="cardToggler">
          <CardHeader id="previewLetterToggler">
            <CardTitle>Preview Letters</CardTitle>
          </CardHeader>
          <UncontrolledCollapse toggler="#previewLetterToggler">
            <CardBody>
              <div className="docReferenceBox">
                {previewData?.length
                  ? previewData?.map((preview) => (
                      <PreviewLetterCard
                        key={preview?._id}
                        btnText={preview?.tradeLineName || preview?.bureauName}
                        onViewPreview={() => _handleViewPreview(preview)}
                      />
                    ))
                  : null}
              </div>
            </CardBody>
          </UncontrolledCollapse>
        </Card>
      </section>

      {isOpenModal ? (
        <PreviewLetterModal
          isOpen={isOpenModal}
          previewLink={currentPreview?.letterAwsLocation}
          title={modalTitle}
          toggle={() => setIsOpenModal(false)}
        />
      ) : null}
    </>
  );
};

export default LettersPreviewWrapper;
