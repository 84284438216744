import { YearOptions } from "components/form-options";
import { isAfterRound2 } from "helper-methods";
import { useSelector } from "react-redux";
import { FormGroup, Input, Label, List } from "reactstrap";

const YearDisputeField = ({
  values,
  errors,
  onChange,
  onBlur,
  year,
  onChangeSuggestion,
}) => {
  // hooks
  const { birthYear } = useSelector((state) => state?.reportData?.report || {});

  return (
    <div className="form-group mb-0">
      <Label for="checkbox3">
        Incorrect Year of Birth{" "}
        <span className="disputeValue">
          {isAfterRound2() ? "" : `(${year?.dataValue})`}
        </span>
      </Label>

      {!isAfterRound2() ? (
        <Input
          type="select"
          className="mt-1"
          name="birthYear"
          value={values["birthYear"]}
          onChange={onChange}
          onBlur={onBlur}
        >
          <YearOptions />
        </Input>
      ) : null}

      {/* Suggested  Incorrect birthYear */}
      <div className="incorrectNameList">
        <Label>{isAfterRound2() ? "Dispute: " : "Suggestions: "}</Label>
        <List>
          <li>
            {isAfterRound2() ? (
              <span>{year?.dataValue}</span>
            ) : (
              <FormGroup check inline>
                <Input
                  id="incorretBirthYear1"
                  type="radio"
                  name="incorrectBirthYear"
                  value={birthYear}
                  onChange={(e) =>
                    onChangeSuggestion("birthYear", e.target.value)
                  }
                  checked={values["birthYear"] === birthYear}
                />
                <Label for="incorretBirthYear1">{birthYear}</Label>
              </FormGroup>
            )}
          </li>
        </List>
      </div>

      {errors["birthYear"] ? (
        <p className="form-error">{errors["birthYear"]}</p>
      ) : null}
    </div>
  );
};

export default YearDisputeField;
