import { useSelector } from "react-redux";
import { Input, Label } from "reactstrap";

const AddressProof = ({ addressProofData, onUploadAddressProof }) => {
  const { user } = useSelector((state) => state?.userCredential || {});

  return (
    <div className="form-group mb-0">
      <Label>Address Proof</Label>
      {user?.addressProof ? (
        <div className="docReferenceBox mb-3 mt-2">
          <div className="mx-auto">
            <img
              src={require("../../assets/img/icon1-primary.svg").default}
              alt=""
            />
            <a
              href={user?.addressProof}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-primary"
            >
              Address Proof
            </a>
          </div>
        </div>
      ) : null}

      <div className="customFileUpload">
        <Input
          type="file"
          id="addressProofUpload"
          accept="image/*"
          onChange={onUploadAddressProof}
        />

        {!addressProofData ? (
          <Label for="addressProofUpload" className="p-3">
            <img
              src={require("../../assets/img/uploadDoc.svg").default}
              alt=""
            />
            <div className="customUploadText">
              <h6>Upload Document</h6>
              <span>File size must be less than 5mb</span>
            </div>
          </Label>
        ) : (
          <Label for="addressProofUpload" className="uploaded p-3">
            <img
              src={require("../../assets/img/sign-up-doc.svg").default}
              alt=""
            />
            <div className="customUploadText">
              <h6>{addressProofData?.uploadData?.name}</h6>
              <span>File size must be less than 5mb</span>
            </div>
          </Label>
        )}
      </div>
    </div>
  );
};

export default AddressProof;
