import { CustomDateRangePicker } from "components/common";
import { Input, Label } from "reactstrap";

const SentLettersFilterForm = ({
  letterType,
  tradeLines,
  filters,
  onChangeFilter,
  onDatesChange,
}) => {
  return (
    <div className="filterForm">
      <div className="formGroup">
        <Label>Date Range</Label>
        <CustomDateRangePicker
          startDate={filters?.dateRange?.startDate}
          endDate={filters?.dateRange?.endDate}
          startDateId={"startDate_kpi_dashboard"}
          endDateId={`endDate_kpi_dashboard`}
          onDatesChange={(startDate, endDate) =>
            onDatesChange(startDate, endDate)
          }
        />
      </div>

      {letterType === "bureau" ? (
        <div className="formGroup">
          <Label>Bureaus</Label>
          <Input
            type="select"
            value={filters["bureau"]}
            onChange={(e) => onChangeFilter("bureau", e.target.value)}
          >
            <option value="">All</option>
            <option value="TransUnion">TransUnion</option>
            <option value="Experian">Experian</option>
            <option value="Equifax">Equifax</option>
          </Input>
        </div>
      ) : (
        <div className="formGroup">
          <Label>Trade Lines</Label>
          <Input
            type="select"
            value={filters["tradeLine"]}
            onChange={(e) => onChangeFilter("tradeLine", e.target.value)}
          >
            <option value="">All</option>
            {tradeLines?.map((tradeLine, index) => (
              <option key={index} value={tradeLine}>
                {tradeLine}
              </option>
            ))}
          </Input>
        </div>
      )}

      <div className="formGroup">
        <Label>Round</Label>
        <Input
          type="select"
          value={filters["level"]}
          onChange={(e) => onChangeFilter("level", e.target.value)}
        >
          <option value="">All</option>
          <option value="level1">1</option>
          <option value="level2">2</option>
          <option value="level3">3</option>
          <option value="level4">4</option>
        </Input>
      </div>

      <div className="formGroup">
        <Label>Letter</Label>
        <Input
          type="select"
          value={filters["isNoResponse"]}
          onChange={(e) => onChangeFilter("isNoResponse", e.target.value)}
        >
          <option value="">All</option>
          <option value="No Response">No Response Letter</option>
          <option value="Dispute">Dispute Letter</option>
        </Input>
      </div>
    </div>
  );
};

export default SentLettersFilterForm;
