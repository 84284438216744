import { Button } from "reactstrap";

const SingleCard = ({ card, makeDefault, onDelete, isDeletingCard }) => {
  return (
    <li key={card?.id}>
      <div>
        <div>
          <img
            src={require(`../../assets/img/Cards/${card?.brand?.toLowerCase()}.png`)}
            alt=""
          />

          <div className="ms-3">
            <p className="text-dark mb-0">**** **** **** {card?.last4}</p>

            {/* Active Since */}
            <div className="d-flex justify-content-between">
              <p className="text-dark mb-0">{`${card?.exp_month}/${String(
                card?.exp_year
              ).slice(-2)}`}</p>

              <p className="text-dark mb-0">{card?.brand}</p>
            </div>
          </div>
        </div>

        <div>
          {card?.isDefault ? (
            <span>Default</span>
          ) : (
            <Button
              color="link"
              className="px-0"
              onClick={() => makeDefault(card?.id)}
            >
              Make Default
            </Button>
          )}

          {!card?.isDefault ? (
            <Button
              color="link"
              className="ms-3"
              onClick={() => onDelete(card?.id)}
              disabled={isDeletingCard}
            >
              <img src={require("../../assets/img/trash.svg").default} alt="" />
            </Button>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default SingleCard;
