import { ListGroupItem } from "reactstrap";
import { formatDateLL } from "helper-methods";

const NotificationsWrapper = ({ notifications }) => {
  return (
    <>
      {notifications?.map((notification) => (
        <ListGroupItem key={notification?._id}>
          <span>{formatDateLL(notification?.createdAt)}</span>
          <p>{notification?.body}</p>
        </ListGroupItem>
      ))}
    </>
  );
};

export default NotificationsWrapper;
