import { STATE_CITY_DATA } from "config/states-and-cities";

const CityOptions = ({ state }) => {
  const selectedState = STATE_CITY_DATA.find((each) => each?.name === state);

  return (
    <>
      <option value="">Select</option>
      {selectedState?.cities?.map((item) => (
        <option key={item?.id} value={item?.name}>
          {item?.name}
        </option>
      ))}
    </>
  );
};

export default CityOptions;
