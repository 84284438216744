import { FeatureList } from "components/common";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Card, CardTitle } from "reactstrap";

const SubscriptionCard = (props) => {
  const {
    className,
    title,
    planAmount,
    planType,
    description,
    tag,
    planTypeText,
    planDuration,
    featuresList,
    userType,
  } = props;
  const navigate = useNavigate();

  const membershipUrl = `/${
    userType === "attorney" ? "membership-attorney" : "membership"
  }?plan=${planType}&amount=${planAmount}&type=${planDuration}`;

  return (
    <Card body className={className}>
      <CardTitle className="d-flex justify-content-between align-items-center">
        {title}{" "}
        {tag ? (
          <Badge color="primary" pill className="ms-2">
            {tag}
          </Badge>
        ) : null}
      </CardTitle>
      <span style={{ minHeight: "42px" }}>{description}</span>

      <div className="planPrice">
        <span className="price">${planAmount}</span>
        <span>{planTypeText}</span>
      </div>

      <Button
        color="primary"
        className="mt-3 btn-submit"
        onClick={() => navigate(membershipUrl)}
      >
        Buy Plan
      </Button>

      <div className="features-list">
        <FeatureList list={featuresList} />
      </div>
    </Card>
  );
};

export default SubscriptionCard;
