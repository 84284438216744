import { Button } from "reactstrap";

const PreviewLetterCard = ({ btnText, onViewPreview }) => {
  return (
    <>
      <div>
        <img src={require("../../assets/img/document.png")} alt="" />
        <Button color="primary" outline onClick={onViewPreview}>
          {btnText}
        </Button>
      </div>
    </>
  );
};

export default PreviewLetterCard;
