import { useState } from "react";

const useFileUpload = () => {
  const [blobs, setBlobs] = useState([]);
  let Payload = [];

  const handleFileUpload = async function handleUpload({ event, field }) {
    let updatedblobs = [];
    setBlobs([]); // this is for only accept one

    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      updatedblobs.push({
        blob: files[i] && URL.createObjectURL(files[i]),
        type: files[i].type,
        field: field || "",
      });

      setBlobs(updatedblobs);
      Payload.push({ uploadData: files[i], field, forKeyName: field });
    }
  };

  return { payload: Payload, handleFileUpload, blobs, setBlobs };
};

export default useFileUpload;
