import { Button, Card, Col, List } from "reactstrap";
import ActivityItem from "./ActivityItem";
import { useState } from "react";
import { groupActivityByDate } from "helper-methods/dashboard-helper";

const INITIAL_ITEMS_COUNT = 1;

const Activity = ({ data, bureau }) => {
  // states
  const [itemsToShow, setItemsToShow] = useState(INITIAL_ITEMS_COUNT);

  const activityData = groupActivityByDate(data);

  const _handleSeeMore = () =>
    setItemsToShow((prev) =>
      prev === Object.keys(activityData)?.length
        ? INITIAL_ITEMS_COUNT
        : prev + INITIAL_ITEMS_COUNT > Object.keys(activityData)?.length
        ? Object.keys(activityData)?.length
        : prev + INITIAL_ITEMS_COUNT
    );

  return (
    <Col xl="4" lg="6">
      <Card body>
        <List>
          {activityData
            ? Object.keys(activityData)
                ?.slice(0, itemsToShow)
                ?.map((key, index) => (
                  <li key={index}>
                    <h6>{key}</h6>
                    <List>
                      {activityData?.[key]?.map((activity) => (
                        <ActivityItem
                          key={activity?._id}
                          category={key}
                          activity={activity}
                          bureau={bureau}
                        />
                      ))}
                    </List>
                  </li>
                ))
            : null}
        </List>

        {activityData &&
        Object.keys(activityData)?.length > INITIAL_ITEMS_COUNT ? (
          <Button color="link" className="h-auto mt-4" onClick={_handleSeeMore}>
            See{" "}
            {itemsToShow === Object.keys(activityData)?.length
              ? "Less"
              : "More"}{" "}
            <i
              className={
                itemsToShow === Object.keys(activityData)?.length
                  ? "fa fa-chevron-up"
                  : "fa fa-chevron-down"
              }
            />
          </Button>
        ) : null}
      </Card>
    </Col>
  );
};

export default Activity;
