import { List } from "reactstrap";
import defaultProfile from "assets/img/default-profile.svg";

const LeadInfo = ({ lead }) => {
  const leadAddress = `${lead?.currentMailingAddress?.streetLine2} ${lead?.currentMailingAddress?.streetLine1} ${lead?.currentMailingAddress?.city} ${lead?.currentMailingAddress?.state} ${lead?.currentMailingAddress?.zip} ${lead?.currentMailingAddress?.country}`;

  return (
    <section>
      <div className="d-flex align-items-center mb-4">
        <div className="userIcon p-0">
          <img
            src={lead?.profilePic || defaultProfile}
            alt=""
            style={{ objectFit: "cover" }}
          />
        </div>
        <h6 className="mb-0">{lead?.name?.full}</h6>
      </div>

      <div className="infoBox">
        <List>
          <li>
            <strong>Email</strong>
            <p>{lead?.email}</p>
          </li>

          <li>
            <strong>Phone</strong>
            <p>{lead?.phone}</p>
          </li>

          <li>
            <strong>Year of Birth</strong>
            <p>{lead?.birthYear}</p>
          </li>

          <li>
            <strong>Location</strong>
            <p>{leadAddress}</p>
          </li>

          <li>
            <strong>SSN</strong>
            <p>{lead?.ssn}</p>
          </li>

          <li>
            <strong>Credits</strong>
            <p>{lead?.credits}</p>
          </li>
        </List>
      </div>
    </section>
  );
};

export default LeadInfo;
