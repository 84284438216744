import { Button, Spinner } from "reactstrap";
import usePreviewActions from "hooks/usePreviewActions";
import { useState } from "react";
import { useSelector } from "react-redux";
import DisputeSendLetterModal from "components/modals/DisputeSendLetterModal";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { getTradelineNamesFromPreview } from "helper-methods/preview-letter-helper";
import CaseTypesModal from "components/modals/CaseTypesModal";
import DamageEstimatesModal from "components/modals/DamageEstimatesModal";
import { InfoTooltip } from "components/common";

const PreviewActions = () => {
  // states
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCaseTypesModal, setIsOpenCaseTypesModal] = useState(false);
  const [isOpenDamageEstimatesModal, setIsOpenDamageEstimatesModal] =
    useState(false);
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [hasSentLetter, setHasSentLetter] = useState(false);
  const [caseTypesData, setCaseTypesData] = useState(null);

  const { loading, saveInProgress, saveAsPDF, sendLetter } =
    usePreviewActions();
  const { currentRound, bureau } = useSelector(
    (state) => state?.reportData || {}
  );

  // constants
  const isFirstRound = currentRound === 1;
  const isForTradeLine = bureau === "Tradeline";
  const tradelineNames = getTradelineNamesFromPreview();
  const videoModalContent = hasSentLetter
    ? `Upload the response sent by ${
        isForTradeLine ? tradelineNames : bureau
      } on Sent Letters page`
    : `You must cross-verify your account details before sending it out to ${
        isForTradeLine ? tradelineNames : bureau
      }`;

  // toggle modal
  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  // send letter
  const _handleSendLetter = () =>
    sendLetter((isLetterSentToAll) => {
      currentRound === 4 && isLetterSentToAll
        ? setIsOpenCaseTypesModal(true)
        : isFirstRound
        ? setInfoVideoModal(true)
        : setIsOpenModal(true);
      setHasSentLetter(true);
    });

  const _handleSubmitCaseTypes = (caseTypesData) => {
    setCaseTypesData(caseTypesData);
    setIsOpenCaseTypesModal(false);
    setIsOpenDamageEstimatesModal(true);
  };

  const _handleSubmitDamageEstimates = () => {
    setIsOpenDamageEstimatesModal(false);
    setIsOpenModal(true);
  };

  return (
    <>
      {/* info tooltip */}
      <InfoTooltip
        content="You don't need to change anything. This will be reflected in the
          letter"
      />

      {/* info video  */}
      <div className="wavesBtnWrapper posFixed">
        <div className="waves wave-1" />
        <div className="waves wave-2" />
        <div className="waves wave-3" />
        <Button
          color="link"
          className="infoVideoBtn"
          onClick={() => setInfoVideoModal(true)}
        >
          <img src={require("../../assets/img/play-button.png")} alt="" />
        </Button>
      </div>

      <div className="inlineBtnWrapper">
        <Button
          color="primary"
          disabled={loading}
          outline
          className="me-3"
          onClick={_handleSendLetter}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Spinner />
              Send Letter
            </div>
          ) : (
            "Send Letter"
          )}
        </Button>

        <Button color="primary" disabled={saveInProgress} onClick={saveAsPDF}>
          {saveInProgress ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Spinner />
              Save PDF
            </div>
          ) : (
            "Save PDF"
          )}
        </Button>
      </div>

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => {
            setInfoVideoModal(false);
            hasSentLetter && setIsOpenModal(true);
          }}
          title={hasSentLetter ? "Reminder Message" : "Preview Letter"}
          content={videoModalContent}
        />
      ) : null}

      {isOpenModal ? (
        <DisputeSendLetterModal
          isOpen={isOpenModal}
          toggle={() => _toggleModal()}
          bureau={bureau}
        />
      ) : null}

      {isOpenCaseTypesModal ? (
        <CaseTypesModal
          isOpen={isOpenCaseTypesModal}
          onSubmit={_handleSubmitCaseTypes}
        />
      ) : null}

      {isOpenDamageEstimatesModal ? (
        <DamageEstimatesModal
          isOpen={isOpenDamageEstimatesModal}
          onSubmit={_handleSubmitDamageEstimates}
          caseTypesData={caseTypesData}
        />
      ) : null}
    </>
  );
};

export default PreviewActions;
