import { Row } from "reactstrap";
import VideoCard from "components/training/VideoCard";
import { Fallback } from "components/common";

const TrainingVideosContainer = ({ loading, videos }) => {
  return (
    <>
      {loading || !videos?.length ? (
        <Fallback loading={loading} />
      ) : (
        <section>
          <Row className="gy-3">
            {videos?.map((video) => (
              <VideoCard
                key={video?._id}
                id={video?._id}
                src={video?.videoAwsLink}
                description={video?.description}
                title={video?.title}
                duration={video?.duration}
              />
            ))}
          </Row>
        </section>
      )}
    </>
  );
};

export default TrainingVideosContainer;
