import { Card, Table } from "reactstrap";
import useSentLetters from "hooks/useSentLetters";
import { SentLetter, SentLettersFilter } from ".";
import { Fallback, PaginatedItems } from "components/common";

const ITEMS_PER_PAGE = 10;

const SentLettersContainer = ({ type, leadId, userType }) => {
  const {
    loading,
    letters,
    tradeLines,
    lettersCount,
    currentPage,
    fetchLetters,
    deleteLetter,
    downloadLetter,
    onUploadResponse,
    onPageChange,
  } = useSentLetters(type, leadId, userType);

  return (
    <>
      {/* filter */}
      <SentLettersFilter
        tradeLines={tradeLines}
        onApplyFilter={(filters) => fetchLetters(filters)}
        onClearFilter={fetchLetters}
        letterType={type}
      />

      {/* Personal Information */}
      <section>
        <Card body>
          {loading || !letters?.length ? (
            <Fallback loading={loading} data={letters} />
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Round</th>
                  <th>Letter</th>
                  <th>{type === "bureau" ? "Bureau" : "Trade Line"}</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {letters?.map((letter) => (
                  <SentLetter
                    key={letter?._id}
                    type={type}
                    userType={userType}
                    letter={letter}
                    loading={loading}
                    onDelete={deleteLetter}
                    onDownload={downloadLetter}
                    onResponseUpload={onUploadResponse}
                    round={letter?.mode?.slice(-1)}
                  />
                ))}
              </tbody>
            </Table>
          )}

          {/* pagination */}
          {!loading && letters?.length && lettersCount > ITEMS_PER_PAGE ? (
            <PaginatedItems
              currentPage={currentPage}
              items={Array(lettersCount)}
              onItemsChange={() => {}}
              onPageChange={onPageChange}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          ) : null}
        </Card>
      </section>
    </>
  );
};

export default SentLettersContainer;
