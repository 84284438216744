import { Modal, ModalHeader, ModalBody } from "reactstrap";

const InfoVideoAlertModal = ({ isOpen, toggle, title, content, url }) => {
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} scrollable centered>
      <ModalHeader toggle={toggle}>{title || "Video Tutorial"}</ModalHeader>

      <ModalBody>
        <p>{content}</p>

        <video
          width="400"
          muted
          controls
          src={url || "https://www.w3schools.com/html/mov_bbb.mp4"}
        >
          Your browser does not support HTML video.
        </video>
      </ModalBody>
    </Modal>
  );
};

export default InfoVideoAlertModal;
