import { Row } from "reactstrap";
import Activity from "./Activity";

const RepairActivity = ({ activity }) => {
  return (
    <section className="repair-activity">
      <h6>Repair Activity</h6>
      <Row className="gy-4">
        <Activity data={activity?.transUnion} bureau="transUnion" />
        <Activity data={activity?.experian} bureau="experian" />
        <Activity data={activity?.equifax} bureau="equifax" />
      </Row>
    </section>
  );
};

export default RepairActivity;
