import { Button, Card, Table } from "reactstrap";
import { useState } from "react";
import { camelCaseToWords } from "helper-methods";

const INITIAL_ITEMS_COUNT = 3;

const TradeLineCard = ({ tradeLine, onOpenModal }) => {
  // states
  const [itemsToShow, setItemsToShow] = useState(INITIAL_ITEMS_COUNT);

  const { bureau: name, data } = tradeLine;

  const _handleSeeMore = () =>
    setItemsToShow((prev) =>
      prev === data?.length
        ? INITIAL_ITEMS_COUNT
        : prev + INITIAL_ITEMS_COUNT > data?.length
        ? data?.length
        : prev + INITIAL_ITEMS_COUNT
    );

  return (
    <Card body className="mb-4">
      <Table responsive>
        <thead>
          <tr>
            <th>{`Trade Line (${name})`}</th>
            <th>Bureaus</th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Issue</th>
            <th>TransUnion</th>
            <th>Experian</th>
            <th>Equifax</th>
          </tr>
        </thead>

        <tbody>
          {data?.length
            ? data?.slice(0, itemsToShow)?.map((data, index) => {
                const issueName = Object.keys(data)[0];

                return (
                  <tr key={index}>
                    <td>{camelCaseToWords(issueName)}</td>
                    {data[issueName]?.length
                      ? data[issueName]?.map((issue) => (
                          <td key={issue?._id}>
                            <span
                              className={
                                issue?.isDispute
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {issue[issueName]}
                            </span>
                          </td>
                        ))
                      : null}
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>

      {/* See More */}
      <Button color="link" className="h-auto mb-2" onClick={_handleSeeMore}>
        See {itemsToShow === data?.length ? "Less" : "More"}{" "}
        <i
          className={
            itemsToShow === data?.length
              ? "fa fa-chevron-up"
              : "fa fa-chevron-down"
          }
        />
      </Button>
    </Card>
  );
};

export default TradeLineCard;
