import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import useFileUpload from "hooks/useFileUpload";
import {
  canProceedToNextRound,
  decodeToken,
  errorHandler,
  getErrorModalText,
  showConfirmModal,
  uploadFileOnServer,
} from "helper-methods";
import moment from "moment";
import defaultProfile from "assets/img/default-profile.svg";
import LoaderModal from "components/modals/LoaderModal";
import { analyzingModalTexts } from "config/helper-config";
import {
  getLatestReportData,
  getUserDetails,
  verifyAccountDetails,
} from "http/http-calls";
import {
  setCurrentRound,
  setNoResponseBureauData,
  setNoResponseTradeLineData,
  setReportData,
  setSentLetters,
  updateUserData,
  updateUserToken,
} from "redux/actions";
import MyProfileModal from "components/modals/Attorney/MyProfileModal";

const DashboardHeader = ({ isShow, setIsShow }) => {
  // states
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);

  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const fileUploadRef = useRef({ current: null });
  const { user } = useSelector((state) => state?.userCredential || {});
  const { unread } = useSelector((state) => state?.notificationData || {});
  const { report, nextRoundDate, sentLetters, currentRound } = useSelector(
    (state) => state?.reportData || {}
  );

  const { handleFileUpload, payload } = useFileUpload();

  const isLetterSentToAll =
    sentLetters?.isTransUnionSent &&
    sentLetters?.isExperianSent &&
    sentLetters?.isEquifaxSent &&
    sentLetters?.isTradeLineSent;
  const isAttorney = user?.userType === "Lawyer";

  // Regular expression to match /lead/ followed by one or more characters
  const leadPathRegex = /^\/lead\/(.+)$/;

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  const _toggleProfileModal = (isOpenModal = false) => {
    setIsOpenProfileModal(isOpenModal);
  };

  const _toggleSidebar = () => {
    console.log("toggled");
    setIsShow(!isShow);
  };

  // analyze report
  async function _analyzeReport() {
    _toggleModal(true);
    try {
      setLoading(true);
      setIsOpenModal(true);

      const payload = {
        pdfLink: localStorage.getItem("pdfLink"),
        firstName: user?.name?.first || "",
        lastName: user?.name?.last || "",
        email: user?.email || "",
        phoneNo: user?.phone || "",
        yearOfBirth: user?.birthYear || "",
        ssn: user?.ssn || "",
        streetLine2: user?.currentMailingAddress?.streetLine2 || "",
        streetLine1: user?.currentMailingAddress?.streetLine1 || "",
        city: user?.currentMailingAddress?.city || "",
        state: user?.currentMailingAddress?.state || "",
        zip: user?.currentMailingAddress?.zip || "",
      };

      const res = await verifyAccountDetails(payload);

      if (!res?.error) {
        // update no response letter status for bureau and tradeline
        dispatch(setNoResponseBureauData(res?.noresponseLetterBureau));
        dispatch(setNoResponseTradeLineData(res?.noresponseLetterTradeLine));

        const data = decodeToken(res?.token);

        // get latest report data
        const reportData = await getLatestReportData(data?._reportId);

        // get latest user details
        const userData = await getUserDetails();

        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }

        dispatch(updateUserToken(res?.token));
        dispatch(setCurrentRound(+data?.round?.slice(-1)));

        if (!reportData?.error) {
          dispatch(setReportData(reportData?.report));
          dispatch(
            setSentLetters({
              isTransUnionSent: reportData?.report?.isTransUnionSent,
              isExperianSent: reportData?.report?.isExperianSent,
              isEquifaxSent: reportData?.report?.isEquifaxSent,
              isTradeLineSent: reportData?.report?.isTradeLineSent,
            })
          );
          localStorage.removeItem("pdfLink");
          localStorage.setItem("hasUploadedReport", true);
          navigate("/credit-report");
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
      setIsOpenModal(false);
    }
  }

  const _handleUploadClick = () => {
    // if user has not completed his profile then don't allow to upload
    if (!user?.isComplete) {
      showConfirmModal({
        icon: "warning",
        title: "Can't Upload",
        text: "Please complete your profile to proceed",
        confirmButtonText: "Complete now",
        showCancelButton: false,
        onConfirm: () => {
          navigate("/user-info");
        },
      });
      return;
    }

    // don't allow to upload if user can't proceed to next round
    if (!canProceedToNextRound()) {
      showConfirmModal({
        icon: "warning",
        title: "Can't Upload",
        text: !isLetterSentToAll
          ? getErrorModalText()
          : `You can upload your next report after ${moment(
              new Date(nextRoundDate)
            ).format("lll")}`,
        confirmButtonText: "Ok",
        showCancelButton: false,
        onConfirm: () => {},
      });

      return;
    }

    fileUploadRef?.current?.click();
  };

  // handle upload report
  const _onUploadReport = async (e) => {
    handleFileUpload({ event: e });

    try {
      setLoading(true);

      const res = await uploadFileOnServer(payload);
      localStorage.setItem("pdfLink", res?.[0]?.url);
      if (currentRound === 0) navigate("/account-verification");
      else _analyzeReport();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _innerHeader = () => {
    if (location?.pathname === "/dashboard") {
      return (
        <div className="innerHeader">
          <h2>Dashboard</h2>
          <div className="d-flex">
            <Button color="primary" outline className="me-3">
              Generate New Report
            </Button>

            <Button
              color="primary"
              onClick={_handleUploadClick}
              disabled={loading || currentRound === 4}
            >
              Upload New report
            </Button>

            <input
              type="file"
              ref={fileUploadRef}
              onChange={_onUploadReport}
              accept=".pdf"
              hidden
            />
          </div>
        </div>
      );
    } else if (location?.pathname === "/credit-report") {
      return (
        <div className="innerHeader justify-content-start">
          <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
            <i className="fa fa-chevron-left"></i>
          </Button>
          <h2>Credit Report Dates {moment(report?.createdAt).format("ll")}</h2>
        </div>
      );
    } else if (location?.pathname === "/payment") {
      return (
        <div className="innerHeader">
          <h2>Payment</h2>
        </div>
      );
    } else if (location?.pathname === "/my-profile") {
      return (
        <div className="innerHeader">
          <h2>My Profile</h2>
        </div>
      );
    } else if (location?.pathname === "/manage-subscription") {
      return (
        <div className="innerHeader">
          <h2>Subscription</h2>
        </div>
      );
    } else if (location?.pathname === "/disputes") {
      return (
        <div className="innerHeader">
          <h2>Dispute History</h2>
        </div>
      );
    } else if (location?.pathname === "/sent-letters") {
      return (
        <div className="innerHeader">
          <h2>Sent Letters</h2>
        </div>
      );
    } else if (location?.pathname === "/training-program") {
      return (
        <div className="innerHeader">
          <h2>Training Program</h2>
        </div>
      );
    } else if (location?.pathname === "/account-verification") {
      return (
        <div className="innerHeader">
          <h2>Account Verification</h2>
        </div>
      );
    } else if (location?.pathname === "/credit-report-analysis") {
      return (
        <div className="innerHeader">
          <h2>Credit Report Analysis</h2>
        </div>
      );
    } else if (location?.pathname === "/generate-letter") {
      return (
        <div className="innerHeader justify-content-start">
          <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
            <i className="fa fa-chevron-left"></i>
          </Button>
          <h2>Generate Letter</h2>
        </div>
      );
    } else if (location?.pathname === "/notifications") {
      return (
        <div className="innerHeader justify-content-start">
          <h2>Notifications</h2>
        </div>
      );
    } else if (location?.pathname === "/preview-letter") {
      return (
        <div className="innerHeader justify-content-start">
          <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
            <i className="fa fa-chevron-left"></i>
          </Button>
          <h2>Preview Letter</h2>
        </div>
      );
    }
    // Affiliate
    else if (location?.pathname === "/affiliate-dashboard") {
      return (
        <div className="innerHeader">
          <h2>Dashboard</h2>
        </div>
      );
    }
    // Attorney
    else if (location?.pathname === "/leads") {
      return (
        <div className="innerHeader justify-content-start">
          <h2>Leads</h2>
        </div>
      );
    } else if (leadPathRegex.test(location.pathname)) {
      return (
        <div className="innerHeader justify-content-start">
          <Button
            color="link"
            className="backBtn"
            onClick={() => navigate("/leads")}
          >
            <i className="fa fa-chevron-left"></i>
          </Button>
          <h2>View Profiles</h2>
        </div>
      );
    } else if (location?.pathname === "/unlocked-leads") {
      return (
        <div className="innerHeader justify-content-start">
          <h2>Unlocked Leads</h2>
        </div>
      );
    } else if (location?.pathname === "/manage-subscription-attorney") {
      return (
        <div className="innerHeader justify-content-start">
          <h2>Subscription</h2>
        </div>
      );
    } else if (location?.pathname === "/view-profile") {
      return (
        <div className="innerHeader justify-content-start">
          <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
            <i className="fa fa-chevron-left"></i>
          </Button>
          <h2>View Profile</h2>
        </div>
      );
    } else {
      return <div className="innerHeader"></div>;
    }
  };

  useEffect(() => {
    _innerHeader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div className="projectHeader">
        {/* page title */}
        {_innerHeader()}

        <div className="toggleSidebarRight">
          <Button onClick={() => _toggleSidebar()} className="toggleIcon">
            <span></span>
          </Button>
          <div className="right">
            <Button
              color="link"
              className="bellIcon active hoverBtn"
              onClick={() => navigate("/notifications")}
            >
              {/* unread notification count */}
              {unread ? <p className="unreadCount">{unread}</p> : null}
              <img
                src={require("../../assets/img/notification.svg").default}
                alt=""
              />
            </Button>
            <div
              className="userInfo ms-2"
              onClick={
                isAttorney
                  ? () => _toggleProfileModal(true)
                  : () => navigate("/my-profile")
              }
            >
              <div className="userAvatar">
                <img src={user?.profilePic || defaultProfile} alt="Profile" />
              </div>
              <div className="userName">{user?.name?.full}</div>
            </div>
          </div>
        </div>
      </div>

      {isOpenModal ? (
        <LoaderModal
          title="Analyzing Credit Report"
          texts={analyzingModalTexts}
          isOpen={isOpenModal}
          allowClose={false}
          toggle={() => _toggleModal()}
        />
      ) : null}

      {isOpenProfileModal ? (
        <MyProfileModal
          isOpen={isOpenProfileModal}
          toggle={() => _toggleProfileModal()}
        />
      ) : null}
    </>
  );
};

export default DashboardHeader;
