import { Row, Col } from "reactstrap";
import { hasSubscription } from "guards/subscription";
import { Navigate } from "react-router-dom";
import UserProfile from "components/profile/UserProfile";
import UserDocs from "components/profile/UserDocs";
import BasicInfo from "components/profile/BasicInfo";
import useProfile from "hooks/useProfile";

const MyProfile = () => {
  const {
    loading,
    addressProofData,
    photoIdData,
    onUpdateProfile,
    onUploadAddressProof,
    onUploadPhotoId,
    onUploadProfilePic,
  } = useProfile();

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      <section>
        <Row className="gy-3 gy-xl-0">
          <Col xl="4">
            <UserProfile onUploadProfilePic={onUploadProfilePic} />
            <hr />
            <UserDocs
              onUploadAddressProof={onUploadAddressProof}
              onUploadPhotoId={onUploadPhotoId}
              addressProofData={addressProofData}
              photoIdData={photoIdData}
            />
          </Col>

          <Col xl="8">
            <BasicInfo loading={loading} onUpdateProfile={onUpdateProfile} />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default MyProfile;
