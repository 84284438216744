import { generateBirthYearFrom18To100 } from "helper-methods";

const YearOptions = () => {
  const yearsData = generateBirthYearFrom18To100();

  return (
    <>
      <option value="">Select</option>
      {yearsData?.map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </>
  );
};

export default YearOptions;
