import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "./http-service";
import { BASE_URL } from "config/index";

// AUTH API CALLS
export const registerUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/login`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateProfile = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/update/user/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateUserInfo = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/update/info`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getUserDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user-profile`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/forgotpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const resetPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resetpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const checkDuplicate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/duplicate/email`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const generateOtp = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/generate/otp`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const validateOtp = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/validate/otp`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// CARD API CALLS
export const getCardToken = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/card-token`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addCard = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add-card`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addDefaultCard = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add-default-card`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateDefaultCard = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/set-default-card/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteCard = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/delete-card/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllCards = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/all-cards`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// PAYMENT HISTORY API CALLS
export const getPaymentHistory = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/payment/history`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// SUBSCRIPTION API CALLS
export const buySubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/buy/subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const buySubscriptionWithIntent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/payment/intent/subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const upgradeSubscription = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/upgrade/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const upgradeSubscriptionWithIntent = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/payment/intent/upgrade/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const renewSubscription = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/renew/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const resumeSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resume/subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const renewSubscriptionWithIntent = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/payment/intent/renew/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const pauseSubscription = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/pause/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// SUBSCRIPTION API CALLS FOR ATTORNEY
export const buySubscriptionAttorney = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/lawyer/buy/subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const buySubscriptionAttorneyWithIntent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/payment/intent/lawyer/subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const renewSubscriptionAttorney = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/lawyer/renew/subscription
    `,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const renewSubscriptionAttorneyWithIntent = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/payment/intent/lawyer/renew/subscription`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const pauseSubscriptionAttorney = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/pause/lawyer/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const upgradeSubscriptionAttorney = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/upgrade/lawyer/subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// DASHBOARD API CALLS
export const getDashboardData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/dashboard`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getCreditItems = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/dashboard/credit/items`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getReportData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/dashboard/report`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getHistoryData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/dashboard/history`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const dismissReminderAlert = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/dashboard/reminder/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// REPORT API CALLS
export const verifyAccountDetails = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/report/pdf`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLatestReportData = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/report/latest/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const saveReportData = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/report/save/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const generatePreviewLetter = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/letter/preview`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const generateLetter = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/letter/sent`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const markLetterAsSent = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/letter/sent/marked/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const saveLetterAsPDF = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/letter/save/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllReports = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/disputes`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getReport = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/dispute/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getReportForAttorney = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/lead/dispute/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// LETTER API CALLS
export const getAllLetters = ({ payload, letterType }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/letters/${letterType}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const uploadLetterResponse = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/letter/upload/response`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteLetter = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/letter/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// ONE SIGNAL
export const getIpData = () => {
  return new Promise((resolve, reject) => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

export const addDeviceForNotification = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/device/add`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// NOTIFICATION API CALLS
export const getAllNotifications = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/notifications`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const markAllNotificationsAsRead = () => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/notification/read`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// TRAINING API CALLS
export const fetchTrainingVideos = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/trainings`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateWatchStatus = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/training/watch/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// API CALLS FOR QUESTIONNAIRE
export const sendQuestionnaireData = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/question`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// API CALLS FOR CRM
export const fetchAllLeads = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/leads`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchUnlockedLeads = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/unlocked/leads`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const unlockLead = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/unlock/lead/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getUnlockedLeadDetails = (id, payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/view/lead/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const buyCredits = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/lawyer/buy/credit`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * @param {string} platform - google or facebook
 * @param {object} payload - {accessToken: google or facefook response token}
 * @returns
 */
export const socialLogin = (platform, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/${platform}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * used for check availability of phone or email or username
 *
 * @param {string} payload - phone or email or username
 * @returns
 */
export const checkAvailability = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/unique`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
