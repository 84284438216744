import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const CongratulationsCreditReportModal = ({ isOpen, toggle, onAddCard }) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>
        <img
          src={require("../../assets/img/successCheck.svg").default}
          alt=""
          width="20px"
          className="me-2"
        />
        Congratulations!
      </ModalHeader>

      <ModalBody>
        <p className="text-center">
          Your Credit Report has been analyzed. Now you can view the disputes in
          your credit report & generate a letter.
        </p>
      </ModalBody>
    </Modal>
  );
};

export default CongratulationsCreditReportModal;
