import { Button, Col, Spinner } from "reactstrap";
import useSubscription from "hooks/useSubscription";
import moment from "moment";

const SubscriptionActions = ({
  subscription,
  toggleModal,
  isAttorney = false,
}) => {
  const { loading, pauseSubscription, renewSubscription, resumeSubscription } =
    useSubscription(true);

  const isSubscriptionActive = subscription?.isAuto;
  const subscriptionStartDate = moment(subscription?.start).format(
    "MMM DD, YYYY"
  );
  const subscriptionEndDate = moment(subscription?.end)
    .add(1, "d")
    .format("MMM DD, YYYY");

  // render button text based on subscription status
  const _renderButtonText = () => {
    return subscription?.isExpired
      ? "Renew Subscription"
      : !isSubscriptionActive
      ? "Resume Subscription"
      : "Pause Subscription";
  };

  // handle subscription action
  const _handleSubscriptionAction = () => {
    subscription?.isExpired
      ? renewSubscription()
      : isSubscriptionActive
      ? pauseSubscription(isAttorney)
      : resumeSubscription();
  };

  return (
    <Col md="12" xl="6" className="rounded mt-4 mt-lg-0">
      {/* Active Since */}
      <div className="d-flex justify-content-between">
        <p className="text-dark mb-0">Active Since</p>
        <strong className="text-dark mb-0">{subscriptionStartDate}</strong>
      </div>

      {/* Next Bill */}
      <div className="mt-3 d-flex justify-content-between">
        <p className="text-dark mb-0">Next Bill</p>
        <strong className="text-dark mb-0">{subscriptionEndDate}</strong>
      </div>

      {/* submit button */}
      <div className="d-flex flex-column align-items-center">
        <Button
          color="primary"
          outline
          className="btn-submit mt-5"
          onClick={_handleSubscriptionAction}
          disabled={loading}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Spinner />
              {_renderButtonText()}
            </div>
          ) : (
            _renderButtonText()
          )}
        </Button>

        {subscription?.tier === "Basic" && (
          <Button
            color="primary"
            className="btn-submit mt-3"
            onClick={() => toggleModal(true)}
            disabled={subscription?.isExpired}
          >
            Upgrade To Premium
          </Button>
        )}
      </div>
    </Col>
  );
};

export default SubscriptionActions;
