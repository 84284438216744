import moment from "moment";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const UploadedResonsesModal = ({ isOpen, toggle, responses }) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => _closeModal()}
      scrollable
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>Preview Uploads</ModalHeader>

      <ModalBody>
        <Table responsive>
          <thead>
            <tr>
              <th>S No.</th>
              <th>Name</th>
              <th>Type</th>
              <th>Date Added</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {responses?.length
              ? responses.map((response) => (
                  <tr key={response?._id}>
                    <td>{response?.slNo}</td>
                    <td>{response?.letterName}</td>
                    <td>{response?.fileType || "N/A"}</td>
                    <td>
                      {moment(new Date(response?.uploadDate)).format("lll")}
                    </td>
                    <td>
                      <div className="action">
                        <Button
                          color="link"
                          onClick={() => window.open(response?.uploadLink)}
                        >
                          <i className="fa fa-eye" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default UploadedResonsesModal;
