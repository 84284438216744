import { CASE_TYPES_DATA } from "config/questionnaire/case-types-data";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const CaseTypesModal = ({ isOpen, onSubmit }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [caseTypeValues, setCaseTypeValues] = useState({});
  const [error, setError] = useState(null);
  const { isCaseType3Present, isCaseType7Present, isCaseType8Present } =
    useSelector((state) => state?.reportData?.report?.caseType || {});

  const currentCaseType = CASE_TYPES_DATA[currentIndex];
  const isLastIndex = currentIndex === CASE_TYPES_DATA.length - 1;

  const _handleNext = () => {
    // if no value is selected then prevent from going next and show error
    if (
      CASE_TYPES_DATA[currentIndex]?.actions &&
      !caseTypeValues[`caseType${currentIndex + 1}`]
    ) {
      setError("*Please select a value");
      return;
    }

    setCurrentIndex((prev) => {
      if (
        (currentIndex === 1 && !isCaseType3Present) ||
        (currentIndex === 5 && !isCaseType7Present) ||
        (currentIndex === 6 && !isCaseType8Present)
      ) {
        return prev + 2;
      } else {
        return prev + 1;
      }
    });
  };

  const _handlePrevious = () => {
    // remove error (if any)
    if (error) setError(null);
    setCurrentIndex((prev) => {
      if (
        (currentIndex === 3 && !isCaseType3Present) ||
        (currentIndex === 7 && !isCaseType7Present) ||
        (currentIndex === 9 && !isCaseType8Present)
      ) {
        return prev - 2;
      } else {
        return prev - 1;
      }
    });
  };

  const _handleChange = (value) => {
    setCaseTypeValues((prev) => ({
      ...prev,
      [`caseType${currentIndex + 1}`]: value,
    }));

    // remove error(if any)
    if (error) setError(null);
  };

  const _handleSubmit = () => {
    let payload = Object.keys(caseTypeValues)
      .filter((key) => caseTypeValues[key] === "Yes")
      .map((key) => ({
        caseType: key,
        ans: caseTypeValues[key],
      }));

    if (isCaseType3Present) {
      payload.push({ caseType: "caseType3", ans: "Yes" });
    }
    if (isCaseType7Present) {
      payload.push({ caseType: "caseType7", ans: "Yes" });
    }
    if (isCaseType8Present) {
      payload.push({ caseType: "caseType8", ans: "Yes" });
    }

    onSubmit(payload);
  };

  return (
    <Modal isOpen={isOpen} scrollable centered>
      <ModalHeader>{currentCaseType?.title}</ModalHeader>

      <ModalBody>
        <p className="text-center">{currentCaseType?.content}</p>

        {currentCaseType?.actions ? (
          <div className="inlineBtnWrapper questionnaireRadioInput">
            {currentCaseType?.actions?.map((action, index) => (
              <FormGroup check inline key={index}>
                <Input
                  type="radio"
                  id={action}
                  value={action}
                  name={`caseType${currentIndex + 1}`}
                  checked={
                    caseTypeValues[`caseType${currentIndex + 1}`] === action
                  }
                  onChange={() => _handleChange(action)}
                />
                <Label for={action}>{action}</Label>
              </FormGroup>
            ))}
          </div>
        ) : null}

        {error ? (
          <p className="mt-4 text-danger text-center mb-0">{error}</p>
        ) : null}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center my-2">
        {currentIndex > 0 && (
          <Button color="primary" outline onClick={_handlePrevious}>
            Previous
          </Button>
        )}

        <Button
          color="primary"
          className="ms-2"
          onClick={isLastIndex ? _handleSubmit : _handleNext}
        >
          {isLastIndex ? "Submit" : "Next"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CaseTypesModal;
