import React, { useState } from "react";
import {
  Label,
  Input,
  Card,
  Table,
  InputGroup,
  InputGroupText,
  List,
  CardTitle,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { hasSubscription } from "guards/subscription";
import { Navigate } from "react-router-dom";
import { CustomDateRangePicker, PaginatedItems } from "components/common";

const ViewProfile = () => {
  const [chartOptions] = useState({
    series: [
      {
        name: "series1",
        data: [300, 550, 500, 450, 700, 450, 600],
      },
      {
        name: "series2",
        data: [600, 550, 350, 350, 500, 650, 700],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "month",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      tooltip: {
        x: {
          format: "MM",
        },
      },
    },
  });

  // date range
  const [filters, setFilters] = useState({
    dateRange: {
      startDate: null,
      endDate: null,
    },
  });

  const _onDatesChange = (startDate = null, endDate = null) => {
    const newFilters = { ...filters };

    newFilters["dateRange"] = {
      startDate,
      endDate,
    };
    setFilters(newFilters);
  };

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription-attorney"} />;
  }

  return (
    <>
      {/* info box */}
      <div className="infoBox">
        <List className="outline bg-white">
          <li>
            <strong>Name</strong>
            <p>
              <img
                src="https://images.unsplash.com/photo-1530268729831-4b0b9e170218?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
                alt=""
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "100%",
                }}
                className="me-2"
              />
              John Doe
            </p>
          </li>
          <li>
            <strong>Email</strong>
            <p>testmail@gmail.com</p>
          </li>
          <li>
            <strong>Phone</strong>
            <p>9658745145</p>
          </li>
          <li>
            <strong>Year of Birth</strong>
            <p>1995</p>
          </li>
          <li className="flex-grow-1">
            <strong>Location</strong>
            <p>9300 Venice Blvd Culver City, United States</p>
          </li>
          <li>
            <strong>SSN</strong>
            <p>9898</p>
          </li>
          <li>
            <strong>Credit</strong>
            <p>3</p>
          </li>
        </List>
      </div>

      <Row className="gy-3">
        <Col md="6">
          {/* Credit Reports */}
          <Card>
            <CardHeader>
              <CardTitle>Credit Reports</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Equifax</th>
                    <th>Experian</th>
                    <th>TransUnion</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>June 30</td>
                    <td>767</td>
                    <td>723</td>
                    <td>712</td>
                  </tr>
                  <tr>
                    <td>June 30</td>
                    <td>767</td>
                    <td>723</td>
                    <td>712</td>
                  </tr>
                  <tr>
                    <td>June 30</td>
                    <td>767</td>
                    <td>723</td>
                    <td>712</td>
                  </tr>
                  <tr>
                    <td>June 30</td>
                    <td>767</td>
                    <td>723</td>
                    <td>712</td>
                  </tr>
                </tbody>
              </Table>

              {/* pagination */}
              <PaginatedItems itemsPerPage={4} />
            </CardBody>
          </Card>

          {/* documents */}
          <Card className="mt-3">
            <CardHeader>Documents</CardHeader>
            <CardBody>
              <div className="paraBox docReference">
                <div>
                  <strong>Identity Proof</strong>
                  <img
                    src={
                      require("../../../assets/img/identityProof.svg").default
                    }
                    alt=""
                  />
                </div>
                <div>
                  <strong>Address Proof</strong>
                  <img
                    src={
                      require("../../../assets/img/addressProof.svg").default
                    }
                    alt=""
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          {/* History */}
          <Card>
            <CardHeader>
              <CardTitle>History</CardTitle>

              <div className="card-header-inputs">
                <div className="formGroup">
                  <Label>Bureau</Label>
                  <Input type="select" bsSize="sm">
                    <option>Select</option>
                    <option>TransUnion</option>
                    <option>Experian</option>
                    <option>Equifax</option>
                  </Input>
                </div>
                <div className="formGroup">
                  <Label>Date Range</Label>
                  <CustomDateRangePicker
                    startDate={filters.dateRange.startDate}
                    endDate={filters.dateRange.endDate}
                    startDateId={"startDate_kpi_dashboard"}
                    endDateId={`endDate_kpi_dashboard`}
                    onDatesChange={(startDate, endDate) =>
                      _onDatesChange(startDate, endDate)
                    }
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <ReactApexChart
                options={chartOptions.options}
                series={chartOptions.series}
                type="area"
                height={350}
              />
            </CardBody>
          </Card>

          {/* Credit Items */}
          <Card className="mt-3">
            <CardHeader>
              <CardTitle>Credit Items</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>TransUnion</th>
                    <th>Experian</th>
                    <th>Equifax</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="text-dark">Positive</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Disputed</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Reapired</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Deleted</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </Table>

              {/* pagination */}
              <PaginatedItems itemsPerPage={4} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Disputes */}
      <section className="mt-4">
        <h6>Disputes</h6>

        {/* filter */}
        <div className="filterWrapper">
          <div className="filterIcon">
            <i className="fas fa-filter" />
          </div>

          <div className="filterForm">
            <div className="formGroup">
              <Label>Date Range</Label>
              <CustomDateRangePicker
                startDate={filters.dateRange.startDate}
                endDate={filters.dateRange.endDate}
                startDateId={"startDate_kpi_dashboard"}
                endDateId={`endDate_kpi_dashboard`}
                onDatesChange={(startDate, endDate) =>
                  _onDatesChange(startDate, endDate)
                }
              />
            </div>

            <div className="formGroup">
              <Label>Bureaus</Label>
              <Input type="select">
                <option>All</option>
                <option>TransUnion </option>
                <option>Experian </option>
                <option>Equifax</option>
              </Input>
            </div>

            <div className="formGroup">
              <Label>Trade Lines</Label>
              <Input type="select">
                <option>All</option>
                <option>Amex</option>
                <option>Wills Fargo</option>
              </Input>
            </div>

            <div className="formGroup">
              <Label>Status</Label>
              <Input type="select">
                <option>All</option>
                <option>Active</option>
                <option>Inactive</option>
              </Input>
            </div>

            {/* search */}
            <div className="formGroup searchbar">
              <Label>Search</Label>
              <InputGroup>
                <Input placeholder="Search..." />
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroup>
            </div>
          </div>
        </div>

        <Card body>
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Dispute Items</th>
                <th>Type</th>
                <th>Bureaus</th>
                <th>Tradelines</th>
                <th>User Input</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Jul 12. 2023</td>
                <td>Account Number</td>
                <td>Account History</td>
                <td>Experion</td>
                <td>AMEX</td>
                <td>There are many variations of passages of Lorem</td>
                <td>
                  <span className="badge-success">Active</span>
                </td>
                <td>
                  <div className="action">
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/writing.png")}
                        alt=""
                        width="18px"
                      />
                    </Button>
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/download.png")}
                        alt=""
                        width="18px"
                      />
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Jul 12. 2023</td>
                <td>Account Number</td>
                <td>Account History</td>
                <td>Experion</td>
                <td>AMEX</td>
                <td>There are many variations of passages of Lorem</td>
                <td>
                  <span className="badge-success">Active</span>
                </td>
                <td>
                  <div className="action">
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/writing.png")}
                        alt=""
                        width="18px"
                      />
                    </Button>
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/download.png")}
                        alt=""
                        width="18px"
                      />
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Jul 12. 2023</td>
                <td>Account Number</td>
                <td>Account History</td>
                <td>Experion</td>
                <td>AMEX</td>
                <td>There are many variations of passages of Lorem</td>
                <td>
                  <span className="badge-danger">Inactive</span>
                </td>
                <td>
                  <div className="action">
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/writing.png")}
                        alt=""
                        width="18px"
                      />
                    </Button>
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/download.png")}
                        alt=""
                        width="18px"
                      />
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>

          {/* pagination */}
          <PaginatedItems itemsPerPage={4} />
        </Card>
      </section>
    </>
  );
};

export default ViewProfile;
