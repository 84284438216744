import { List } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { getActivityIcon } from "helper-methods/dashboard-helper";

const ActivityItem = ({ category, activity, bureau }) => {
  return (
    <li
      key={activity?._id}
      className={
        activity?.activityStatus?.toLowerCase()?.includes("sent")
          ? "sent"
          : "received"
      }
    >
      <div className="cardBox">
        <div className="imgBox">
          <img src={getActivityIcon(activity?.activityStatus)} alt="" />
        </div>

        <div className="textBox">
          <Link to="/credit-report" id="UncontrolledTooltipExample6">
            {activity?.activityStatus}
          </Link>

          {category === "Last 7 days" ? (
            <small>{moment(new Date(activity?.date)).format("ll")}</small>
          ) : null}
        </div>
      </div>

      {activity?.activityStatus === "Dispute Suggested" ? (
        <List>
          {activity?.[`${bureau}Dispute`]?.map((dispute, index) => (
            <li key={index} className="danger">
              <div className="cardBox">
                <div className="imgBox">
                  <img
                    src={require("../../assets/img/not.svg").default}
                    alt=""
                  />
                </div>

                <Link to="/credit-report" className="h-auto px-0 fw-normal">
                  {dispute}
                </Link>
              </div>
            </li>
          ))}
        </List>
      ) : null}
    </li>
  );
};

export default ActivityItem;
