import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import UpgradeYourSubscriptionModal from "components/modals/UpgradeYourSubscriptionModal";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { hasSubscription } from "guards/subscription";
import {
  SubscriptionActions,
  SubscriptionDetails,
} from "components/subscription";

const ManageSubscription = () => {
  // states
  const [isOpenModal, setIsOpenModal] = useState(false);

  // hooks
  const { user } = useSelector((state) => state?.userCredential || {});

  const subscription = user?._subscription?.[0];

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      {/* cards */}
      <section className="mergeDualCard subscriptionPlanCards">
        <Card body className="shadow">
          <Row>
            <SubscriptionDetails subscription={subscription} />
            <SubscriptionActions
              subscription={subscription}
              toggleModal={_toggleModal}
            />
          </Row>
        </Card>
      </section>

      {isOpenModal ? (
        <UpgradeYourSubscriptionModal
          isOpen={isOpenModal}
          toggle={() => _toggleModal()}
        />
      ) : null}
    </>
  );
};

export default ManageSubscription;
