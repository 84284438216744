import { getPersonalInfoDisputes } from "helper-methods/generate-letter-helper";
import { useSelector } from "react-redux";
import { Card, Table } from "reactstrap";

const PersonalInfo = () => {
  // hooks
  const { personalInfo } = useSelector(
    (state) => state?.reportData?.report || {}
  );

  const {
    transUnion: personalInfoTransUnion,
    equifax: personalInfoEquifax,
    experian: personalInfoExperian,
  } = getPersonalInfoDisputes(personalInfo);

  return (
    <section>
      <h6>Personal Information</h6>

      <Card body>
        <Table responsive>
          <thead>
            <tr>
              <th>Items</th>
              <th>Bureaus</th>
              <th></th>
              <th></th>
            </tr>

            <tr>
              <th></th>
              <th>TransUnion</th>
              <th>Experian</th>
              <th>Equifax</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Name</td>

              <td>
                <span
                  className={
                    personalInfoTransUnion?.nameHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoTransUnion?.nameHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoTransUnion?.nameHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>

              <td>
                <span
                  className={
                    personalInfoExperian?.nameHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoExperian?.nameHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoExperian?.nameHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>

              <td>
                <span
                  className={
                    personalInfoEquifax?.nameHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoEquifax?.nameHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoEquifax?.nameHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>
            </tr>

            <tr>
              <td>Year of Birth</td>

              <td>
                <span
                  className={
                    personalInfoTransUnion?.yobHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoTransUnion?.yobHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoTransUnion?.yobHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>

              <td>
                <span
                  className={
                    personalInfoExperian?.yobHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoExperian?.yobHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoExperian?.yobHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>

              <td>
                <span
                  className={
                    personalInfoEquifax?.yobHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoEquifax?.yobHasDispute ? "Incorrect" : "Correct"}
                </span>

                {/* {personalInfoEquifax?.yobHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>
            </tr>

            <tr>
              <td>Current Address</td>

              <td>
                <span
                  className={
                    personalInfoTransUnion?.addressHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoTransUnion?.addressHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoTransUnion?.addressHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>

              <td>
                <span
                  className={
                    personalInfoExperian?.addressHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoExperian?.addressHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoExperian?.addressHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>

              <td>
                <span
                  className={
                    personalInfoEquifax?.addressHasDispute
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {personalInfoEquifax?.addressHasDispute
                    ? "Incorrect"
                    : "Correct"}
                </span>

                {/* {personalInfoEquifax?.addressHasDispute && (
                  <i className="fa fa-check-circle text-primary ms-2" />
                )} */}
              </td>
            </tr>
          </tbody>
        </Table>

        {/* See More */}
        {/* <Button color="link" className="h-auto mb-2">
          See More <i className="fa fa-chevron-down" />
        </Button> */}
      </Card>
    </section>
  );
};

export default PersonalInfo;
