import { store } from "redux/store";
import { checkForPersonalInfoDisputes } from "./preview-letter-helper";

export const getPersonalInfoDisputes = (personalInfo) => {
  const [TransUnion, Experian, Equifax] = personalInfo;
  const [TransUnionName, TransUnionAddress, TransUnionDob] = TransUnion?.data;
  const [ExperianName, ExperianAddress, ExperianDob] = Experian?.data;
  const [EquifaxName, EquifaxAddress, EquifaxDob] = Equifax?.data;

  return {
    transUnion: {
      nameHasDispute: TransUnionName?.isDispute,
      yobHasDispute: TransUnionDob?.isDispute,
      addressHasDispute: TransUnionAddress?.isDispute,
    },
    experian: {
      nameHasDispute: ExperianName?.isDispute,
      yobHasDispute: ExperianDob?.isDispute,
      addressHasDispute: ExperianAddress?.isDispute,
    },
    equifax: {
      nameHasDispute: EquifaxName?.isDispute,
      yobHasDispute: EquifaxDob?.isDispute,
      addressHasDispute: EquifaxAddress?.isDispute,
    },
  };
};

export const getAccountHistoryDisputes = (accountHistory) => {
  const result = accountHistory?.map((each) => {
    const value = {
      bureau: each?.tradeLine || each?.bureau,
      balance: each?.balance,
      data: [],
    };

    each?.data?.forEach((data) => {
      for (const key in data) {
        if (Array?.isArray(data[key]) && data[key]?.length) {
          value.data.push({ [key]: data[key] });
        }
      }
    });

    return value;
  });

  return result;
};

const getDisputes = (data, currentBureau) => {
  let result = [];

  data?.forEach((item) => {
    const key = Object.keys(item)[0];

    // check if that particulay field has any disputes
    const hasDispute = item[key].some((value) => value?.isDispute);

    if (hasDispute) {
      const originalValue = item[key].find(
        (value) => value?.tradeline === currentBureau
      )?.[key];

      let val = {
        disputeName: key,
        originalValue,
        suggestions: [],
      };

      // add suggestions that have disputes and are not equal to original value
      item[key].forEach((value) => {
        if (
          (value[key] || value["dateLastActive"]) !== originalValue &&
          value.isDispute
        )
          val.suggestions.push({ bureau: value.tradeline, value: value[key] });
      });

      // add a single suggestion for all other fields that match and don't have any dispute and are not equal to original value
      const noDisputeItem = item[key].find(
        (value) =>
          !value?.isDispute &&
          (value[key] || value["dateLastActive"]) !== originalValue
      );
      if (noDisputeItem) {
        val.suggestions.push({
          bureau: noDisputeItem?.tradeline,
          value: noDisputeItem[key],
        });
      }

      result.push(val);
    }
  });

  return result;
};

export const getAccountDisputeFields = () => {
  const state = store.getState();
  const accountDisputes = getAccountHistoryDisputes(
    state?.reportData?.report?.accountHistory
  );

  const currentBureau = state?.reportData?.bureau;

  const result = [];

  accountDisputes.forEach((item) => {
    let value = {};
    const disputes = getDisputes(item?.data, currentBureau);

    if (disputes?.length) {
      value.bureau = item.bureau;
      value.balance = item.balance;
      value.disputes = disputes;
      result.push(value);
    }
  });

  return result;
};

export const getInquiryDisputeFields = () => {
  const state = store.getState();
  const inquiryDisputes = state?.reportData?.report?.inquiriesStr;
  const bureau = state?.reportData?.bureau;

  return inquiryDisputes?.filter((item) => item?.bureau === bureau);
};

export const getInitialGenerateLetterValues = () => {
  const state = store.getState();
  const personalInfo = state?.reportData?.report?.personalInfo;
  const { bureau, noResponseLetterBureau, currentRound } =
    state?.reportData || {};
  const { nameHasDispute, addressHasDispute, yobHasDispute } =
    checkForPersonalInfoDisputes(personalInfo);

  const isForTradeLine = bureau === "Tradeline";

  if (isForTradeLine || currentRound > 2) return {};

  // check if no response letter was sent to bureau
  const isNoResponseSentToBureau = noResponseLetterBureau?.find(
    (item) => item?.bureauName === bureau
  );

  let initialFormValues = {};

  // only add personal info values if no response letter was not sent to bureau
  if (!isNoResponseSentToBureau) {
    if (nameHasDispute) {
      initialFormValues["name"] = "";
    }
    if (addressHasDispute) {
      initialFormValues["address"] = "";
    }
    if (yobHasDispute) {
      initialFormValues["birthYear"] = "";
    }
  }

  return initialFormValues;
};

export const getBureauLogo = (bureau) => {
  switch (bureau) {
    case "TransUnion":
      return require("../../src/assets/img/transunion.svg").default;
    case "Experian":
      return require("../../src/assets/img/experian.svg").default;
    case "Equifax":
      return require("../../src/assets/img/equifax.svg").default;
    default:
  }
};

export const isNoResponseSent = (bureau) => {
  const state = store.getState();
  const { report, noResponseLetterBureau, noResponseLetterTradeLine } =
    state?.reportData || {};

  if (bureau === "TradeLine")
    return noResponseLetterTradeLine.length === report?.accountHistory?.length;

  const noResponseBureau = noResponseLetterBureau?.find(
    (item) => item?.bureauName === bureau
  );

  if (noResponseBureau) return true;
  return false;
};

export const getDataForTradeLineLetter = () => {
  const state = store.getState();
  const accountDisputes = getAccountHistoryDisputes(
    state?.reportData?.report?.accountHistory
  );

  // map over all the data and find the fields which have disputes
  accountDisputes?.forEach((item) => {
    const data = item?.data?.filter((field) => {
      const key = Object.keys(field)[0];
      const hasDispute = field[key].some((value) => value?.isDispute);
      return hasDispute;
    });
    item.data = data;
  });

  return accountDisputes;
};
