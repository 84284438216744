import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import useFormValidation from "hooks/useFormValidation";
import { validateUserInput } from "lib/form-validation";
import { useDispatch, useSelector } from "react-redux";
import { setReportData } from "redux/actions";

const CreditReportUserInputModal = ({
  isOpen,
  toggle,
  userInput,
  issueName,
  tradeLine,
  issueData,
  disputes,
  inquiryIndex,
}) => {
  // states
  const {
    formValues,
    formErrors,
    handleInputChange,
    handleInputBlur,
    handleSubmit,
  } = useFormValidation(
    {
      userInput: "",
    },
    validateUserInput,
    _onSave
  );

  // hooks
  const { report } = useSelector((state) => state?.reportData || {});
  const dispatch = useDispatch();

  const inquiries = report?.inquiries;

  const _closeModal = () => {
    toggle();
  };

  // update inquiry data of the particular inquiry
  const _updateInquiryData = () => {
    const inquiriesCopy = structuredClone(inquiries);
    const currentInquiry = inquiriesCopy[inquiryIndex];

    const valueIndex = currentInquiry?.data?.findIndex(
      (value) => value !== null
    );

    if (
      formValues["userInput"] === "yes" &&
      currentInquiry?.data?.[valueIndex] === false
    ) {
      currentInquiry?.data?.splice(valueIndex, 1, true);
    } else if (
      formValues["userInput"] === "no" &&
      currentInquiry?.data?.[valueIndex] === true
    ) {
      currentInquiry?.data?.splice(valueIndex, 1, false);
    } else {
      _closeModal();
      return;
    }

    dispatch(setReportData({ ...report, inquiries: inquiriesCopy }));
    _closeModal();
  };

  // update account history data of the particular issue
  const _updateAccountHistory = (value) => {
    const newIssueData = issueData?.map((issue) => {
      const issueCopy = { ...issue };
      if (issueCopy[issueName] !== value) {
        issueCopy[issueName] = value;
        issueCopy.isDispute = true;
      }
      return issueCopy;
    });

    const newAccountHistory = structuredClone(disputes);
    const tradelineIndex = newAccountHistory?.findIndex(
      (dispute) => dispute?.bureau === tradeLine
    );
    const issueIndex = newAccountHistory[tradelineIndex]?.data?.findIndex(
      (issue) => Object.keys(issue)?.[0] === issueName
    );
    newAccountHistory[tradelineIndex]?.data?.splice(issueIndex, 1, {
      [issueName]: newIssueData,
    });

    dispatch(setReportData({ ...report, accountHistory: newAccountHistory }));
    _closeModal();
  };

  // handle save
  function _onSave() {
    switch (userInput) {
      case "Account":
        _updateAccountHistory(formValues["userInput"]);
        return;
      case "Inquiry":
        _updateInquiryData();
        return;
      default:
        return;
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader>
        {userInput === "Inquiry" ? "Invalid Inquiry" : "User Input"}
      </ModalHeader>
      <ModalBody>
        {userInput === "Credit" ? (
          <>
            {/* Credit */}
            <div className="form-group">
              <Label>Enter your correct Account Number?</Label>
              <Input
                type="text"
                name="userInput"
                placeholder="Enter"
                value={formValues["userInput"]}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["userInput"] && (
                <p className="form-error">{formErrors["userInput"]}</p>
              )}
            </div>
          </>
        ) : userInput === "Account" ? (
          <>
            {/* Account */}
            <div className="form-group">
              <Label>
                Enter your correct{" "}
                <span className="text-capitalize">{`${issueName}`}</span>:
              </Label>
              <Input
                type="text"
                name="userInput"
                placeholder="Enter"
                value={formValues["userInput"]}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["userInput"] && (
                <p className="form-error">{formErrors["userInput"]}</p>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Invalid */}
            <div className="form-group">
              <p>Did you make this inquiry?</p>
              <div className="d-flex gap-2">
                <div>
                  <Input
                    type="radio"
                    name="userInput"
                    id="radio1"
                    value="yes"
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  <Label for="radio1" className="ms-1">
                    Yes
                  </Label>
                </div>

                <div>
                  <Input
                    type="radio"
                    name="userInput"
                    id="radio2"
                    value="no"
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  <Label for="radio2" className="ms-1">
                    No
                  </Label>
                </div>
              </div>

              {formErrors["userInput"] && (
                <p className="form-error">{formErrors["userInput"]}</p>
              )}
            </div>
          </>
        )}

        {/* submit button */}
        <div className="inlineBtnWrapper">
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button color="primary" className="ms-3" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreditReportUserInputModal;
