export const BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? ""
    : "https://api-sc.logic-square.com/api/v1";

export const SOCKET_BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live" ? "" : "";

export const STRIPE_API_KEY =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? ""
    : "pk_test_51J2aemGbmF3o6wbBym2X2PoGgzlmaqYKhDag9CwgDQMWlHrlA3KbCUq1VMQBWmXftzQ0v79gvfysh78LBTLF4tI600I2EiO6Bg";

export const SOCIAL_CREDENTIALS = {
  facebookAppId: process.env.REACT_APP_BACKEND_ENV === "live" ? "" : "",
  googleClientId: process.env.REACT_APP_BACKEND_ENV === "live" ? "" : "",
};

export const AWS_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_BACKEND_ENV === "live" ? "" : "scoreclear";

export const ONE_SIGNAL_APP_ID = "24dd886b-d4dc-42d3-a641-80f0ce961b11";

export const DEFAULT_PROFILE_PICTURE =
  require("../assets/img/default-profile.svg").default;
