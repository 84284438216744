import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const InfoTooltip = ({ content, style }) => {
  return (
    <div className="infoTooltip" id="UncontrolledTooltipExample" style={style}>
      <img src={require("../../assets/img/tooltip.png")} alt="" />
      <UncontrolledTooltip
        placement="left"
        autohide={false}
        target="UncontrolledTooltipExample"
      >
        {content}
      </UncontrolledTooltip>
    </div>
  );
};

export default InfoTooltip;
