import StripeCardElement from "components/stripe/StripeCardElement";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Col, Form, Input, Label, Row, UncontrolledTooltip } from "reactstrap";

const PaymentDetailsForm = forwardRef(
  (
    { formValues, formErrors, onChange, onBlur, setFormValues, onAddCard },
    ref
  ) => {
    const stripeCardRef = useRef();

    useImperativeHandle(ref, () => ({
      getCardToken: stripeCardRef.current.getCardToken,
    }));

    return (
      <Form>
        {/* Card Number */}
        <div className="form-group">
          <Label className="labelTooltip">
            Card
            <i className="fa fa-lock" id="UncontrolledTooltipExample2"></i>
            <UncontrolledTooltip
              placement="left"
              autohide={false}
              target="UncontrolledTooltipExample2"
            >
              Your data is safeguarded using the highest level of industry-grade
              encryption for protection.
            </UncontrolledTooltip>
          </Label>
          <StripeCardElement ref={stripeCardRef} />
        </div>

        <Row>
          <Col>
            {/* Country */}
            <div className="form-group">
              <Label>Country</Label>
              <Input
                type="select"
                name="cardCountry"
                value={formValues["cardCountry"]}
                onChange={onChange}
                onBlur={onBlur}
              >
                <option value="usa">USA</option>
              </Input>

              {formErrors["cardCountry"] ? (
                <p className="form-error">{formErrors["cardCountry"]}</p>
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default PaymentDetailsForm;
