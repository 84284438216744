import { useSelector } from "react-redux";
import { Card, CardTitle, Input, Label } from "reactstrap";
import defaultProfile from "assets/img/default-profile.svg";

const UserProfile = ({ onUploadProfilePic }) => {
  const { user } = useSelector((state) => state?.userCredential || {});

  return (
    <Card body className="profileCard">
      <div className="position-relative">
        <div className="cardImg">
          <img src={user?.profilePic || defaultProfile} alt="" />
        </div>

        {/* pencil */}
        <Label className="editProfileCard">
          <Input
            type="file"
            className="d-none"
            accept="image/*"
            onChange={onUploadProfilePic}
          />
          <i className="fa fa-pencil-alt" />
        </Label>
      </div>
      <CardTitle>{user?.name?.full}</CardTitle>
      <span>{user?.email}</span>
    </Card>
  );
};

export default UserProfile;
