import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import AddNewCardModal from "./AddNewCardModal";
import { showConfirmModal } from "helper-methods";
import { useSelector } from "react-redux";
import { USER_TYPES } from "config/helper-config";
import usePaymentMethod from "hooks/usePaymentMethod";
import { upgradeSubscriptionFeatures } from "config/helper-config";
import useSubscription from "hooks/useSubscription";
import { PaymentCards } from "components/subscription";
import {
  getCurrentSubscriptionAmount,
  getUpgradePlanAmount,
} from "helper-methods/subscription-helper";
import { FeatureList } from "components/common";

const UpgradeYourSubscriptionModal = ({ isOpen, toggle, isAttorney }) => {
  // states
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  // hooks
  const { _subscription } = useSelector(
    (state) => state?.userCredential?.user || {}
  );
  const {
    loading: loadingCards,
    isDeletingCard,
    cards,
    deleteCard,
    getCards,
  } = usePaymentMethod();
  const { loading, upgradeSubscription } = useSubscription(isAttorney);

  const subscription = _subscription?.[0];
  const upgradeTier =
    subscription?.tierLawyer === "Basic"
      ? "Pro"
      : subscription?.tierLawyer === "Pro"
      ? "Elite"
      : null;
  const upgradeAmount = getUpgradePlanAmount(
    subscription,
    isAttorney ? USER_TYPES?.lawyer : USER_TYPES?.user
  );

  const subscriptionAmount = getCurrentSubscriptionAmount();

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  const _closeModal = () => {
    toggle();
  };

  // handle delete card
  const _handleDeleteCard = (id) => {
    showConfirmModal({
      title: "Delete Card",
      text: "Are you sure you want to delete this card?",
      icon: "warning",
      onConfirm: () => deleteCard(id),
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      size="xl"
    >
      <ModalHeader>Upgrade Your Subscription</ModalHeader>

      <ModalBody className="p-0">
        <div className="mergeDualCard subscriptionPlanCards">
          <Row>
            <Col md="12" xl="6">
              {/* Current Plan */}
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-dark mb-1">Current Plan</p>
                  <p className="text-dark mb-0 fw-bold">
                    {subscription?.tier || subscription?.tierLawyer}
                  </p>
                </div>
                <p className="text-dark mb-0">${subscriptionAmount}</p>
              </div>

              {/* Upgrade Plan */}
              <div className="mt-3 d-flex justify-content-between">
                <div>
                  <p className="text-dark mb-1">Upgrade Plan</p>
                  <p className="text-dark mb-0 fw-bold">
                    {isAttorney ? upgradeTier : "Premium"}
                  </p>
                </div>
                <p className="text-dark mb-0">${upgradeAmount}</p>
              </div>

              {/* features list */}
              <div className="mt-4 features-list inline-list bg">
                <h6>Features</h6>
                <FeatureList list={upgradeSubscriptionFeatures} />
              </div>

              {/* Pay Today */}
              <div className="d-flex justify-content-between mt-4">
                <p className="text-dark mb-1">Pay Today</p>
                <p className="text-dark mb-0 fw-600">
                  ${upgradeAmount - subscriptionAmount}
                </p>
              </div>
            </Col>

            <Col md="12" xl="6" className="mt-4 mt-lg-0">
              {/* Select Payment Method */}
              <div className="d-flex justify-content-between">
                <p className="mb-1 text-dark">Select Payment Method</p>
                <Button
                  color="link"
                  className="h-auto"
                  onClick={() => _toggleModal(true)}
                >
                  + Add New Card
                </Button>
              </div>

              <div className="selectCardList">
                {loadingCards ? (
                  <div className="loader">
                    <Spinner />
                  </div>
                ) : (
                  <PaymentCards
                    cards={cards}
                    onSelectCard={(card) => setSelectedCard(card)}
                    onDeleteCard={_handleDeleteCard}
                    selectedCard={selectedCard}
                    isDeletingCard={isDeletingCard}
                  />
                )}
              </div>

              {/* submit button */}
              <div className="inlineBtnWrapper">
                <Button color="primary" outline onClick={() => _closeModal()}>
                  Cancel
                </Button>

                <Button
                  color="primary"
                  className="ms-3"
                  disabled={!selectedCard || loading}
                  onClick={() => upgradeSubscription(toggle)}
                >
                  {loading ? (
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <Spinner />
                      Upgrade
                    </div>
                  ) : (
                    "Upgrade"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>

      {isOpenModal ? (
        <AddNewCardModal
          isOpen={isOpenModal}
          toggle={() => _toggleModal()}
          onAddCard={getCards}
        />
      ) : null}
    </Modal>
  );
};

export default UpgradeYourSubscriptionModal;
