import { useState } from "react";

const useFormValidation = (initialValues, validate, submitHandler) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    // check for phone, income, zip, ssn, cardNumber, and cvv
    // check for field types that are number
    const isNumberField =
      String(name).includes("Count") || String(name).includes("AppliedFor");
    if (
      (name === "income" ||
        name === "ssn" ||
        name === "zip" ||
        name === "cardNumber" ||
        name === "cvv" ||
        isNumberField) &&
      isNaN(+value)
    )
      return;

    // for phone number
    if (name === "phone" && isNaN(+value?.slice(3))) return;

    if (name === "terms")
      setFormValues({
        ...formValues,
        [name]: checked,
      });
    else
      setFormValues({
        ...formValues,
        [name]: value,
      });

    const errorMessage = validate(name, value);
    const newErrors = { ...formErrors, [name]: errorMessage };

    // delete the error if no error occurred
    if (!errorMessage) {
      delete newErrors[name];
    }

    setFormErrors(newErrors);

    // Clear the error for this input when the user starts typing again
    // const newErrors = { ...formErrors };
    // delete newErrors[name];
    // setFormErrors(newErrors);
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validate(name, value);
    const newErrors = { ...formErrors, [name]: errorMessage };
    // delete the error if no error occurred
    if (!errorMessage) {
      delete newErrors[name];
    }
    setFormErrors(newErrors);
  };

  const handleSubmit = (e, ...args) => {
    if (e) e.preventDefault();

    // if any errors are present then don't proceed
    // if (Object.keys(formErrors).length) return;

    // Validate all fields before submitting
    const errors = {};

    for (const key in formValues) {
      const errorMessage = validate(key, formValues[key]);
      if (errorMessage) {
        errors[key] = errorMessage;
      }
    }

    setFormErrors(errors);

    // Proceed to form submission process if no errors were found
    if (Object.keys(errors).length === 0) {
      submitHandler(...args);
    }
  };

  return {
    formValues,
    formErrors,
    handleInputChange,
    handleInputBlur,
    handleSubmit,
    setFormValues,
    setFormErrors,
  };
};

export default useFormValidation;
