export const CASE_TYPES_DATA = [
  {
    title: "Case Type 1 (Inaccurate Zero Balance)",
    content:
      "Q. Does your Credit Report show a Balance on a Settled or Paid Account?",
    actions: ["Yes", "No", "Skip"],
  },
  {
    title: "Case Type 2 (1099-C With Balance)",
    content:
      "Q. Does your Credit Report show a Balance after the creditor discharged the debt with a 1099-C?",
    actions: ["Yes", "No", "Skip"],
  },
  {
    title: "Case Type 3 (Multi Reporting)",
    content: "Debt Buyer & Original Creator both report a Balance Due",
    actions: null,
  },
  {
    title: "Case Type 4 (Unknown Tradeline)",
    content: "Q. Does your Credit Report show an Unknown Account?",
    actions: ["Yes", "No", "Skip"],
  },
  {
    title: "Case Type 5 (Shortsale/Foreclosure)",
    content:
      "Q. Short Sale Listed as Foreclosure - After a short sale, does the mortgage account report as a foreclosure and not as a short sale?",
    actions: ["Yes", "No", "Skip"],
  },
  {
    title: "Case Type 6 (Bankruptcy)",
    content:
      "Q. Are there any accounts listed that should have been included in your bankruptcy that are showing a balance due?",
    actions: ["Yes", "No", "Skip"],
  },
  {
    title: "Case Type 7 (Old Account)",
    content:
      "Your Account is too old to report - Negative accounts must be removed after 7 years.",
    actions: null,
  },
  {
    title: "Case Type 8 (Mixed File)",
    content:
      "Your information is mixed with someone else's information on your report.",
    actions: null,
  },
  {
    title: "Case Type 9 (Authorized User)",
    content:
      "Does Authorized user's report show all user activities and not authorized user's?",
    actions: ["Yes", "No", "Skip"],
  },
];

export const CASE_TYPES = {
  caseType1: "Inaccurate Zero Balance",
  caseType2: "1099-C With Balance",
  caseType3: "Multi Reporting",
  caseType4: "Unknown Tradeline",
  caseType5: "Shortsale/Foreclosure",
  caseType6: "Bankruptcy",
  caseType7: "Old Account",
  caseType8: "Mixed File",
  caseType9: "Authorized User",
};
