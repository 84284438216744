import { useEffect, useState } from "react";
import { Card, CardBody, ListGroup } from "reactstrap";
import { errorHandler } from "helper-methods";
import {
  getAllNotifications,
  markAllNotificationsAsRead,
} from "http/http-calls";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications, setNotificationsUnreadCount } from "redux/actions";
import NotificationsWrapper from "components/notifications/NotificationsWrapper";
import { Fallback, PaginatedItems } from "components/common";

const ITEMS_PER_PAGE = 10;

const Notifications = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // hooks
  const { notifications, unread, count } = useSelector(
    (state) => state?.notificationData || {}
  );
  const dispatch = useDispatch();

  // handle page change
  const _onPageChange = async (pageNumber) => {
    try {
      setLoading(true);
      setCurrentPage(pageNumber);

      const res = await getAllNotifications({
        pageSize: ITEMS_PER_PAGE,
        pageNumber,
      });

      if (!res?.error) {
        dispatch(setNotifications(res?.notifications));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // mark all notifications as read (if any)
  useEffect(() => {
    const _markAsRead = async () => {
      try {
        await markAllNotificationsAsRead();
      } catch (error) {
        errorHandler(error);
      }
    };
    if (unread) {
      _markAsRead();
      dispatch(setNotificationsUnreadCount(0));
    }
  }, [dispatch, unread]);

  return (
    <>
      <section>
        {/* notifications */}
        <Card className="commonCard notificationsCard">
          <CardBody>
            {loading || !notifications?.length ? (
              <Fallback loading={loading} />
            ) : (
              <ListGroup>
                <NotificationsWrapper notifications={notifications} />
              </ListGroup>
            )}

            {/* pagination */}
            {notifications?.length && count > ITEMS_PER_PAGE ? (
              <PaginatedItems
                items={Array(count)}
                currentPage={currentPage}
                onItemsChange={() => {}}
                onPageChange={_onPageChange}
                itemsPerPage={ITEMS_PER_PAGE}
              />
            ) : null}
          </CardBody>
        </Card>
      </section>
    </>
  );
};

export default Notifications;
