import { Card, Table } from "reactstrap";
import PaymentHistoryFilters from "./PaymentHistoryFilters";
import { useEffect, useState } from "react";
import { errorHandler } from "helper-methods";
import { getPaymentHistory } from "http/http-calls";
import PaymentHistoryData from "components/payment-history/PaymentHistoryData";
import { Fallback, PaginatedItems } from "components/common";

const ITEMS_PER_PAGE = 10;

const PaymentHistory = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);

  // fetch payment history data
  const _fetchPayementHistoryData = async (filters) => {
    try {
      setLoading(true);

      const res = await getPaymentHistory(filters);

      if (!res?.error) {
        setPaymentHistoryData(res?.transactions);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    _fetchPayementHistoryData();
  }, []);

  return (
    <>
      {/* filter */}
      <PaymentHistoryFilters
        onApplyFilter={(filters) => _fetchPayementHistoryData(filters)}
        onClearFilter={_fetchPayementHistoryData}
      />

      <section>
        <Card body>
          {loading || !paymentHistoryData?.length ? (
            <Fallback loading={loading} data={paymentHistoryData} />
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>Transaction Type</th>
                  <th>Transaction ID</th>
                  <th>Payment Method</th>
                  <th>Fee</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {paymentHistoryData?.map((data) => (
                  <PaymentHistoryData key={data?._id} data={data} />
                ))}
              </tbody>
            </Table>
          )}

          {/* pagination */}
          {paymentHistoryData?.length &&
          paymentHistoryData?.length > ITEMS_PER_PAGE ? (
            <PaginatedItems
              items={paymentHistoryData}
              currentPage={currentPage}
              onItemsChange={(items) => setPaymentHistoryData(items)}
              onPageChange={(page) => setCurrentPage(page)}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          ) : null}
        </Card>
      </section>
    </>
  );
};

export default PaymentHistory;
