import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import useFormValidation from "hooks/useFormValidation";
import { validateResetPassword } from "lib/form-validation";

const ResetPasswordPage = () => {
  // states
  const [showPassword, setShowPassword] = useState(false);

  // hooks
  const navigate = useNavigate();
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
  } = useFormValidation(
    {
      password: "",
      confirmPassword: "",
    },
    validateResetPassword,
    _onResetpassword
  );

  // reset password
  function _onResetpassword() {
    // make api call to reset password here...

    navigate("/login");
  }

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          <div className="leftWrapper">
            <div className="leftInnerWrapper">
              <h2>Reset Password?</h2>

              <Form>
                {/* Enter New Password */}
                <div className="form-group">
                  <Label>Enter New Password</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      type={`${showPassword ? "text" : "password"}`}
                      name="password"
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      value={formValues["password"]}
                    />
                    <InputGroupText
                      className="cursorPointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </InputGroupText>
                  </InputGroup>

                  {formErrors["password"] ? (
                    <p className="form-error">{formErrors["password"]}</p>
                  ) : null}
                </div>

                {/* Re-Enter New Password */}
                <div className="mb-2">
                  <Label>Re-Enter New Password</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      type={`${showPassword ? "text" : "password"}`}
                      name="confirmPassword"
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      value={formValues["confirmPassword"]}
                    />
                    <InputGroupText
                      className="cursorPointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </InputGroupText>
                  </InputGroup>

                  {formErrors["confirmPassword"] ? (
                    <p className="form-error">
                      {formErrors["confirmPassword"]}
                    </p>
                  ) : null}
                </div>

                {/* submit button */}
                <Button
                  color="primary"
                  className="btn-submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
          <div className="rightWrapper">
            {/* logo */}
            <img
              src={require("../../assets/img/logo-white.png")}
              alt="Brand Logo"
              className="companyLogo"
            />
          </div>
        </div>

        <PublicFooter />
      </div>
    </>
  );
};

export default ResetPasswordPage;
