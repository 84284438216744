import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { forwardRef, useImperativeHandle, useState } from "react";
import { InputGroup } from "reactstrap";

const StripeCardElement = forwardRef((_, ref) => {
  const [stripeError, setStripeError] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  // function to handle stripe error
  const _onStripeError = (error) => {
    setStripeError(error?.message);
  };

  const _handleChange = (e) => {
    if (e?.error?.message) setStripeError(e.error.message);
    else setStripeError("");
  };

  // function to get card token
  const _getCardToken = async () => {
    try {
      // return if stripe has not loaded
      if (!stripe || !elements) return;

      // Generate token and send to server
      const card = elements.getElement(CardElement);
      const payload = await stripe.createToken(card);
      if (payload.error && payload.error.message) {
        _onStripeError(payload.error);
      } else {
        return payload?.token?.id;
      }
    } catch (error) {
      _onStripeError(error);
    }
  };

  // Expose the getCardToken method to the parent component
  useImperativeHandle(ref, () => ({
    getCardToken: _getCardToken,
  }));

  return (
    <>
      <InputGroup>
        <CardElement className="stripeCardElement" onChange={_handleChange} />
      </InputGroup>
      {stripeError ? <p className="form-error">*{stripeError}</p> : null}
    </>
  );
});

export default StripeCardElement;
