import React, { useRef } from "react";
import { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { errorHandler, showToast } from "helper-methods";
import { addCard } from "http/http-calls";
import StripeCardElement from "components/stripe/StripeCardElement";

const AddNewCardModal = ({ isOpen, toggle, onAddCard }) => {
  // states
  const [loading, setLoading] = useState(false);

  // hooks
  const stripeCardRef = useRef();

  const _closeModal = () => {
    toggle();
  };

  // add card
  async function _addCard() {
    try {
      // call api to get card token
      const token = await stripeCardRef.current.getCardToken();
      if (!token) return;

      setLoading(true);

      // call api to add default card
      const data = await addCard({ token });

      if (!data?.error) {
        showToast("Card added successfully", "success", 2000);
        _closeModal();
        onAddCard();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered>
      <ModalHeader>Securely Manage Your Card Details</ModalHeader>

      <ModalBody>
        {/* Card Number */}
        <div className="form-group">
          <Label className="labelTooltip">
            Enter Card
            <i className="fa fa-lock" id="UncontrolledTooltipExample1" />
            <UncontrolledTooltip
              placement="left"
              autohide={false}
              target="UncontrolledTooltipExample1"
            >
              Your data is safeguarded using the highest level of industry-grade
              encryption for protection.
            </UncontrolledTooltip>
          </Label>

          <StripeCardElement ref={stripeCardRef} />
        </div>

        {/* submit button */}
        <div className="inlineBtnWrapper">
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="ms-3"
            disabled={loading}
            onClick={_addCard}
          >
            {loading ? (
              <div className="d-flex justify-content-center align-items-center gap-2">
                <Spinner />
                Add Card
              </div>
            ) : (
              "Add Card"
            )}
          </Button>
        </div>

        {/* data secure lock icon */}
        <div className="dataSecureLockIcon align-items-center mt-3">
          <i className="fas fa-shield-alt mt-0"></i>
          <p>Card data is securely encrypted using 256-bit encryption.</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddNewCardModal;
