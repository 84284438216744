import { Button, Input } from "reactstrap";
import { Navigate, useNavigate } from "react-router-dom";
import {
  AccountHistory,
  Inquiries,
  PersonalInfo,
  ReportSummary,
} from "components/credit-report";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBureauName } from "redux/actions";
import CongratulationsCreditReportModal from "components/modals/CongratulationsCreditReportModal";
import { hasSubscription } from "guards/subscription";
import { isNoResponseSent } from "helper-methods/generate-letter-helper";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { InfoTooltip } from "components/common";

const CreditReport = () => {
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const { report, sentLetters, currentRound } = useSelector(
    (state) => state?.reportData || {}
  );
  const { user } = useSelector((state) => state?.userCredential || {});

  // states
  const [bureau, setBureau] = useState(_getInitialBureau());
  const [isOpenModal, setIsOpenModal] = useState(() => {
    const hasUploadedReport = localStorage.getItem("hasUploadedReport");
    return hasUploadedReport ? true : false;
  });

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLetterGeneratedForAll =
    sentLetters?.isTransUnionSent &&
    sentLetters?.isExperianSent &&
    sentLetters?.isEquifaxSent &&
    sentLetters?.isTradeLineSent;
  const isAttorney = user?.userType === "Lawyer";

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
    localStorage.removeItem("hasUploadedReport");
  };

  // get initial bureau value based on letter sent state
  function _getInitialBureau() {
    if (
      (isNoResponseSent("TransUnion") || sentLetters?.isTransUnionSent) &&
      (isNoResponseSent("Experian") || sentLetters?.isExperianSent) &&
      (isNoResponseSent("Equifax") || sentLetters?.isEquifaxSent)
    ) {
      return "Tradeline";
    }

    if (currentRound === 2) {
      return isNoResponseSent("TransUnion") || sentLetters?.isTransUnionSent
        ? isNoResponseSent("Experian") || sentLetters?.isExperianSent
          ? "Equifax"
          : "Experian"
        : isNoResponseSent("Experian") || sentLetters?.isExperianSent
        ? "TransUnion"
        : "TransUnion";
    } else
      return sentLetters?.isTransUnionSent
        ? sentLetters?.isExperianSent
          ? "Equifax"
          : "Experian"
        : sentLetters?.isExperianSent
        ? "TransUnion"
        : "TransUnion";
  }

  const _handleProceed = () => {
    dispatch(setBureauName(bureau));
    navigate("/generate-letter");
  };

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  // redirect to dashboard if report data not found
  if (!report) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <>
      {/* info tooltip */}
      <InfoTooltip content="Items marked in red are identified as disputes by the AI" />

      {/* info video */}
      {!isAttorney && (
        <div className="wavesBtnWrapper posFixed">
          <div className="waves wave-1" />
          <div className="waves wave-2" />
          <div className="waves wave-3" />
          <Button
            color="link"
            className="infoVideoBtn"
            onClick={() => setInfoVideoModal(true)}
          >
            <img src={require("../../assets/img/play-button.png")} alt="" />
          </Button>
        </div>
      )}

      <div className="d-flex justify-content-end">
        {!isAttorney && !isLetterGeneratedForAll ? (
          <div className="formGroup">
            <Input
              type="select"
              value={bureau}
              onChange={(e) => setBureau(e?.target?.value)}
            >
              {!isNoResponseSent("TransUnion") &&
              !sentLetters?.isTransUnionSent ? (
                <option value="TransUnion">Create TransUnion Letter</option>
              ) : null}
              {!isNoResponseSent("Experian") && !sentLetters?.isExperianSent ? (
                <option value="Experian">Create Experian Letter</option>
              ) : null}
              {!isNoResponseSent("Equifax") && !sentLetters?.isEquifaxSent ? (
                <option value="Equifax">Create Equifax Letter</option>
              ) : null}
              {!isNoResponseSent("TradeLine") &&
              !sentLetters?.isTradeLineSent ? (
                <option value="Tradeline">Create Tradeline Letter</option>
              ) : null}
            </Input>
          </div>
        ) : null}
      </div>

      {/* Report Summary */}
      <ReportSummary />

      {/* Personal Information */}
      {report?.personalInfo?.length ? <PersonalInfo /> : null}

      {/* Account History */}
      {report?.accountHistory?.length ? <AccountHistory /> : null}

      {/* Inquiries */}
      {report?.inquiries?.length ? <Inquiries /> : null}

      {!isAttorney ? (
        <div className="d-flex justify-content-center align-items-center">
          {!isLetterGeneratedForAll ? (
            <Button color="primary" onClick={_handleProceed}>
              Proceed
            </Button>
          ) : null}
        </div>
      ) : null}

      {isOpenModal ? (
        <CongratulationsCreditReportModal
          isOpen={isOpenModal}
          toggle={() => _toggleModal()}
        />
      ) : null}

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title="Dispute Analysis"
          content="Your credit report has been analyzed by AI and now you can create Bureau & Tradeline letters by selecting them from dropdown"
        />
      ) : null}
    </>
  );
};

export default CreditReport;
