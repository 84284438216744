import { Button, Card, Row } from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentDetails from "components/membership/PaymentDetails";
import BillingAddress from "components/membership/BillingAddress";
import useMembership from "hooks/useMembership";
import { useRef } from "react";
import { errorHandler } from "helper-methods";

const MembershipAttorney = () => {
  // hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const plan = searchParams.get("plan");
  const planAmount = searchParams.get("amount");
  const planType = searchParams.get("type");

  const {
    loading,
    formValues,
    formErrors,
    setLoading,
    handleInputChange,
    handleInputBlur,
    onBuyMembership,
  } = useMembership(plan, planType, true);
  const paymentRef = useRef();

  const _handleBuyMembership = async (e) => {
    try {
      setLoading(true);
      const token = await paymentRef.current.getCardToken();
      onBuyMembership(e, token);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap membership flex-column">
          <div className="authWrapper-banner">
            <h2>
              <Button
                color="link"
                className="backBtn"
                onClick={() => navigate(-1)}
              >
                <i className="fa fa-chevron-left text-white" />
              </Button>
              Start Your {plan} Membership
            </h2>
          </div>

          {/* cards */}
          <section className="mergeDualCard">
            <Card body className="shadow">
              <Row>
                <PaymentDetails
                  ref={paymentRef}
                  amount={planAmount}
                  formValues={formValues}
                  formErrors={formErrors}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  planType={planType}
                />

                <BillingAddress
                  isAttorney={true}
                  formValues={formValues}
                  formErrors={formErrors}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  plan={plan}
                  loading={loading}
                  onBuyMembership={_handleBuyMembership}
                />
              </Row>
            </Card>
          </section>
        </div>

        {/* footer */}
        <PublicFooter />
      </div>
    </>
  );
};

export default MembershipAttorney;
