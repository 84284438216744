import { Button, Label } from "reactstrap";
import useFilters from "hooks/useFilters";
import { CustomDateRangePicker } from "components/common";

const DisputeHistoryFilter = ({ onApplyFilter, onClearFilter }) => {
  const { filters, isFilterApplied, onDatesChange, onRemoveFilters } =
    useFilters(
      {
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
      onApplyFilter,
      onClearFilter
    );

  return (
    <div className="filterWrapper">
      <div className="filterIcon">
        <i className="fas fa-filter" />
      </div>

      <div className="filterForm">
        <div className="formGroup">
          <Label>Date Range</Label>
          <CustomDateRangePicker
            startDate={filters.dateRange.startDate}
            endDate={filters.dateRange.endDate}
            startDateId={"startDate_kpi_dashboard"}
            endDateId={`endDate_kpi_dashboard`}
            onDatesChange={(startDate, endDate) =>
              onDatesChange(startDate, endDate)
            }
          />
        </div>
      </div>

      {isFilterApplied(filters) ? (
        <Button
          outline
          color="danger"
          className="btn-eraser"
          onClick={onRemoveFilters}
        >
          <i className="fas fa-eraser fa-lg" />
        </Button>
      ) : null}
    </div>
  );
};

export default DisputeHistoryFilter;
