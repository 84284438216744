import { Button } from "reactstrap";
import useFilters from "hooks/useFilters";
import { PaymentHistoryFiltersForm } from ".";

const PaymentHistoryFilters = ({ onApplyFilter, onClearFilter }) => {
  const {
    filters,
    isFilterApplied,
    onChangeFilter,
    onDatesChange,
    onRemoveFilters,
  } = useFilters(
    {
      transactionType: "",
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
    onApplyFilter,
    onClearFilter
  );

  return (
    <div className="filterWrapper">
      <div className="filterIcon">
        <i className="fas fa-filter" />
      </div>

      <PaymentHistoryFiltersForm
        filters={filters}
        onDatesChange={onDatesChange}
        onChangeFilter={onChangeFilter}
      />

      {isFilterApplied(filters) ? (
        <Button
          outline
          color="danger"
          className="btn-eraser"
          onClick={onRemoveFilters}
        >
          <i className="fas fa-eraser fa-lg" />
        </Button>
      ) : null}
    </div>
  );
};

export default PaymentHistoryFilters;
