import { camelCaseToWords } from "helper-methods";
import { getBureauLogo } from "helper-methods/generate-letter-helper";
import { Fragment } from "react";
import { Label, List } from "reactstrap";

const TradeLineAccountHistoryDisputes = ({ data }) => {
  return (
    <>
      {data?.map((item, index) => (
        <Fragment key={index}>
          <h6 className="mb-2 text-secondary">Trade Line : {item?.bureau}</h6>
          {item?.data?.map((field, index) => {
            const keyName = Object.keys(field)[0];

            return (
              <div key={index} className="mb-4 form-group">
                <span className="disputeName">
                  Incorrect {camelCaseToWords(keyName)}
                </span>
                <div className="incorrectNameList">
                  <Label>Disputes: </Label>
                  <List>
                    {field[keyName]?.map((dispute, index) => (
                      <li key={index} className="me-3">
                        <img
                          className="bureauLogo"
                          src={getBureauLogo(dispute?.tradeline)}
                          alt={dispute.tradeline}
                        />
                        <span className="disputeValue">{dispute[keyName]}</span>
                      </li>
                    ))}
                  </List>
                </div>
              </div>
            );
          })}
        </Fragment>
      ))}
    </>
  );
};

export default TradeLineAccountHistoryDisputes;
