import { useCallback } from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";
import moment from "moment";
import { Fallback, PaginatedItems } from "components/common";

const Reports = ({ reports }) => {
  // states
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // handle items change
  const _handleItemsChange = useCallback((items) => {
    setItems(items);
  }, []);

  return (
    <section>
      <Card>
        <CardHeader>
          <CardTitle>Reports</CardTitle>
        </CardHeader>

        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>TransUnion</th>
                <th>Experian</th>
                <th>Equifax</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {items?.length ? (
                items?.map((report) => (
                  <tr key={report?._id}>
                    <td>
                      {moment(new Date(report?.equifax?.date)).format("ll")}
                    </td>
                    <td>{report?.transunion?.creditScore}</td>
                    <td>{report?.experian?.creditScore}</td>
                    <td>{report?.equifax?.creditScore}</td>
                    <td>
                      <div className="action">
                        <Button
                          color="link"
                          onClick={() => window.open(report?.reportPdflink)}
                        >
                          <i className="fa fa-eye"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <Fallback />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* pagination */}
          {reports?.length ? (
            <PaginatedItems
              items={reports}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              onItemsChange={_handleItemsChange}
              itemsPerPage={3}
            />
          ) : null}
        </CardBody>
      </Card>
    </section>
  );
};

export default Reports;
