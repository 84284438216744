import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Button,
  Progress,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import useFormValidation from "hooks/useFormValidation";
import { validateSignup } from "lib/form-validation";
import { StateOptions, YearOptions } from "components/form-options";
import { errorHandler, showToast, uploadFileOnServer } from "helper-methods";
import { checkDuplicate, updateUserInfo } from "http/http-calls";
import { useDispatch, useSelector } from "react-redux";
import { addUserCredential } from "redux/actions";
import useDebounce from "hooks/useDebounce";
import { useEffect } from "react";
import CityOptions from "components/form-options/CityOptions";
import VerifyMobileNumberModal from "components/modals/VerifyMobileNumberModal";
import { regexConfig } from "config/regex-config";
import useFileUpload from "hooks/useFileUpload";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { VideoAlertCard } from "components/common";

const UserInfoPage = () => {
  // states
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [showSSN, setShowSSN] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [isVerifyMobileModal, setIsVerifyMobileModal] = useState(false);
  const [uploadPayload, setUploadPayload] = useState([]);
  const [error, setError] = useState("");

  // hooks
  const { handleFileUpload, payload } = useFileUpload();
  const { user } = useSelector((state) => state?.userCredential || {});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    formValues,
    formErrors,
    setFormValues,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    setFormErrors,
  } = useFormValidation(
    {
      phone: "",
      income: "",
      year: "",
      ssn: "",
      address: "",
      state: "",
      city: "",
      zip: "",
    },
    validateSignup,
    _onCompleteStepOne
  );

  const debouncedPhoneValue = useDebounce(formValues["phone"], 300);
  const showVerifyNumberText = formValues["phone"] && !formErrors["phone"];
  const isPhoneVerified = false; // NOTE: Need to make this flag dynamic later
  const photoIdData = uploadPayload?.find((item) => item?.field === "photoId");
  const addressProofData = uploadPayload?.find(
    (item) => item?.field === "addressProof"
  );
  const alertModalTitle =
    activeTab === "1" ? "Why do we need your details?" : "Why Upload Docs?";
  const alertModalContent =
    activeTab === "1"
      ? "Please enter the correct details below. This information will be taken as a reference during dispute analysis."
      : "Photo ID & Address proof is required as a reference of your identity & address to be sent out on the first letter.";

  const _toggleTab = (newTab = "1") => {
    if (activeTab !== newTab) setActiveTab(newTab);
  };

  const _toggleVerifyMobileModal = (isVerifyMobileModal = false) => {
    setIsVerifyMobileModal(isVerifyMobileModal);
  };

  // handle blur logic separately for phone due to api call
  const _handleBlur = (e) => {
    const { value } = e?.target;
    const trimmedValue = value?.trim();

    if (!trimmedValue?.length) {
      setFormErrors((prev) => ({ ...prev, phone: "*Required" }));
    } else if (!regexConfig?.phone?.test(trimmedValue)) {
      setFormErrors((prev) => ({
        ...prev,
        phone: "*Please enter a valid phone number",
      }));
    }
  };

  // check duplicate
  const _checkDuplicate = async (type) => {
    try {
      const payload = type === "phone" ? { [type]: debouncedPhoneValue } : null;

      const res = await checkDuplicate(payload);

      if (!res?.error) {
        const newErrors = { ...formErrors };
        delete newErrors[type];
        setFormErrors(newErrors);
      }
    } catch (error) {
      setFormErrors({ ...formErrors, [type]: `*${error?.reason}` });
    } finally {
    }
  };

  // handle upload doc
  const _onUploadDoc = async (e, field) => {
    handleFileUpload({ event: e, field });
    const docIndex = uploadPayload?.findIndex((each) => each?.field === field);
    /* Check if doc is already uploaded for this field.
       If yes, then replace it with the new file and if not,
       then simply add it to the upload payload
    */
    if (docIndex > -1) {
      const newUploadPayload = structuredClone(uploadPayload);
      newUploadPayload.splice(docIndex, 1, payload?.[0]);
      setUploadPayload(newUploadPayload);
    } else {
      setUploadPayload((prev) => [...prev, payload?.[0]]);
    }
  };

  // handle step 1 form submission logic
  function _onCompleteStepOne() {
    _toggleTab("2");
  }

  // handle signup
  const _onCompleteProfile = async () => {
    // do not proceed if documents are missing
    if (!photoIdData || !addressProofData) {
      setError("*Please upload all documents to proceed");
      return;
    }

    try {
      setLoading(true);
      const uploadRes = await uploadFileOnServer(uploadPayload);

      const photoId = uploadRes?.find(
        (item) => item?.forKeyName === "photoId"
      )?.url;
      const addressProof = uploadRes?.find(
        (item) => item?.forKeyName === "addressProof"
      )?.url;
      const address = formValues["address"]?.trim();
      const index = address?.indexOf(" ");
      const streetLine1 = address?.slice(index, address?.length)?.trim();
      const streetLine2 = address?.split(" ")?.[0]?.trim();

      const payload = {
        phone: `+1-${formValues["phone"]?.trim()}`,
        birthYear: formValues["year"],
        streetLine1,
        streetLine2,
        ssn: formValues["ssn"]?.trim(),
        city: formValues["city"],
        state: formValues["state"],
        zip: formValues["zip"]?.trim(),
        photoId,
        addressProof,
      };

      const res = await updateUserInfo(payload);

      if (!res?.error) {
        dispatch(
          addUserCredential({
            token: res?.token,
            user: res?.user,
          })
        );
        showToast("Profile updated successfully", "success", 2000);
        navigate("/dashboard");
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // effect to reset error if both documents are uploaded
  useEffect(() => {
    if (photoIdData && addressProofData) {
      setError("");
    }
  }, [photoIdData, addressProofData]);

  // effect to check for duplicate phone
  useEffect(() => {
    if (debouncedPhoneValue?.length && !formErrors["phone"]) {
      _checkDuplicate("phone");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPhoneValue]);

  // effect to prefill form data
  useEffect(() => {
    if (user) {
      setFormValues((prev) => ({
        ...prev,
        address: `${user?.currentMailingAddress?.streetLine2} ${user?.currentMailingAddress?.streetLine1}`,
        state: user?.currentMailingAddress?.state,
        city: user?.currentMailingAddress?.city,
        zip: user?.currentMailingAddress?.zip,
      }));
    }
  }, [setFormValues, user]);

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          <div className="leftWrapper justify-content-start">
            <div className="leftInnerWrapper">
              <div className="sign-up-info">
                <div className="sign-up-info-header mb-4">
                  <Nav pills>
                    <NavItem className={activeTab === "1" ? "active" : ""}>
                      <NavLink>
                        <div className="navImg">
                          {activeTab === "1" ? (
                            <img
                              src={
                                require("../../assets/img/sign-up-user.svg")
                                  .default
                              }
                              alt=""
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/img/successCheck.svg")
                                  .default
                              }
                              alt=""
                            />
                          )}
                        </div>
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() =>
                        activeTab === "1" ? handleSubmit() : null
                      }
                    >
                      <NavLink>
                        <div className="navImg">
                          <img
                            src={
                              require("../../assets/img/sign-up-doc.svg")
                                .default
                            }
                            alt=""
                          />
                        </div>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Progress
                    color="success"
                    value={activeTab === "1" ? 0 : 50}
                  />
                </div>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="mb-4">
                      <h2>Basic Info</h2>
                      <p className="text-secondary">
                        Please complete your sign up process.
                      </p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                      {/* contact number */}
                      <div className="form-group">
                        <Label>Contact Number</Label>
                        <InputGroup>
                          <InputGroupText className="cursorPointer">
                            +1
                          </InputGroupText>
                          <Input
                            placeholder="Enter"
                            type="text"
                            name="phone"
                            onChange={handleInputChange}
                            onBlur={_handleBlur}
                            value={formValues["phone"]}
                          />
                        </InputGroup>

                        {showVerifyNumberText && (
                          <div
                            className={`d-inline-flex align-items-center fs-12 ${
                              isPhoneVerified ? "text-primary" : ""
                            }`}
                            {...(!isPhoneVerified && {
                              style: { color: "#ff7900", cursor: "pointer" },
                            })}
                            onClick={
                              isPhoneVerified
                                ? () => {}
                                : () => _toggleVerifyMobileModal(true)
                            }
                          >
                            <img
                              src={
                                isPhoneVerified
                                  ? require("../../assets/img/verify.png")
                                  : require("../../assets/img/warning1.png")
                              }
                              alt=""
                              width="20px"
                              className="me-1"
                            />
                            {isPhoneVerified
                              ? "Mobile Number Verified"
                              : "Please Verify Your Mobile Number"}
                          </div>
                        )}

                        {formErrors["phone"] ? (
                          <p className="form-error">{formErrors["phone"]}</p>
                        ) : null}
                      </div>

                      {/* Annual Income */}
                      <div className="form-group">
                        <Label className="labelTooltip">
                          Annual Income
                          <i className="fa fa-info-circle" id="annualIncome" />
                          <UncontrolledTooltip
                            placement="right"
                            autohide={false}
                            target="annualIncome"
                          >
                            Your Estimate Annual Income
                          </UncontrolledTooltip>
                        </Label>
                        <InputGroup>
                          <InputGroupText>$</InputGroupText>
                          <Input
                            placeholder="Enter"
                            type="text"
                            name="income"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formValues["income"]}
                          />
                        </InputGroup>

                        {formErrors["income"] ? (
                          <p className="form-error">{formErrors["income"]}</p>
                        ) : null}
                      </div>

                      <Row>
                        <Col md="6">
                          {/* Year of Birth */}
                          <div className="form-group">
                            <Label>Year of Birth</Label>
                            <Input
                              type="select"
                              name="year"
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                              value={formValues["year"]}
                            >
                              <YearOptions />
                            </Input>
                            {formErrors["year"] ? (
                              <p className="form-error">{formErrors["year"]}</p>
                            ) : null}
                          </div>
                        </Col>

                        <Col md="6">
                          {/* SSN Number */}
                          <div className="form-group">
                            <Label className="labelTooltip">
                              SSN Number
                              <i
                                className="fas fa-shield-alt"
                                id="UncontrolledTooltipExample3"
                              />
                              <UncontrolledTooltip
                                placement="right"
                                autohide={false}
                                target="UncontrolledTooltipExample3"
                              >
                                Your SSN is used to connect your credit report
                                to your profile within the dashboard. This data
                                is both secure and encrypted.
                              </UncontrolledTooltip>
                            </Label>
                            <InputGroup>
                              <Input
                                placeholder="Enter"
                                type={`${showSSN ? "text" : "password"}`}
                                name="ssn"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={formValues["ssn"]}
                              />
                              <InputGroupText
                                className="cursorPointer"
                                onClick={() => {
                                  setShowSSN(!showSSN);
                                }}
                              >
                                <i
                                  className={`far ${
                                    showSSN ? "fa-eye" : "fa-eye-slash"
                                  }`}
                                />
                              </InputGroupText>
                            </InputGroup>

                            {formErrors["ssn"] ? (
                              <p className="form-error">{formErrors["ssn"]}</p>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {/* Current Address */}
                      <div className="form-group">
                        <Label className="labelTooltip">
                          Current Address
                          <i
                            className="fa fa-info-circle"
                            id="UncontrolledTooltipExample4"
                          />
                          <UncontrolledTooltip
                            placement="right"
                            autohide={false}
                            target="UncontrolledTooltipExample4"
                          >
                            Please enter the address you are currently residing
                          </UncontrolledTooltip>
                        </Label>

                        <Input
                          placeholder="Eg: 40/6 Rajendar Nagar"
                          name="address"
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          value={formValues["address"]}
                        />

                        {formErrors["address"] ? (
                          <p className="form-error">{formErrors["address"]}</p>
                        ) : null}
                      </div>

                      <Row>
                        <Col md="4">
                          {/* State */}
                          <div className="form-group">
                            <Label>State</Label>
                            <Input
                              type="select"
                              name="state"
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                              value={formValues["state"]}
                            >
                              <StateOptions />
                            </Input>
                            {formErrors["state"] ? (
                              <p className="form-error">
                                {formErrors["state"]}
                              </p>
                            ) : null}
                          </div>
                        </Col>

                        <Col md="4">
                          {/* City */}
                          <div className="form-group">
                            <Label>City</Label>
                            <Input
                              type="select"
                              name="city"
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                              value={formValues["city"]}
                            >
                              <CityOptions state={formValues["state"]} />
                            </Input>

                            {formErrors["city"] ? (
                              <p className="form-error">{formErrors["city"]}</p>
                            ) : null}
                          </div>
                        </Col>

                        <Col md="4">
                          {/* Zip */}
                          <div className="form-group">
                            <Label>Zip</Label>
                            <Input
                              placeholder="Enter"
                              name="zip"
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                              value={formValues["zip"]}
                            />
                            {formErrors["zip"] ? (
                              <p className="form-error">{formErrors["zip"]}</p>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {/* Info video alert */}
                      <VideoAlertCard
                        title="Why do we need your details?"
                        className="mt-4"
                        content="Please enter the correct details above. This information will be taken as a reference during dispute analysis."
                        onOpenVideo={() => setInfoVideoModal(true)}
                      />

                      {/* submit button */}
                      <Button color="primary" className="btn-submit">
                        Next
                      </Button>

                      {/* data secure lock icon */}
                      <div className="dataSecureLockIcon mt-3">
                        <i className="fas fa-shield-alt mt-0" />
                        <p>
                          Your data is safeguarded using the highest level of
                          industry-grade encryption for protection.
                        </p>
                      </div>
                    </Form>
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="mb-4">
                      <h2>Upload Docs</h2>
                      <p className="text-secondary">
                        Please upload the documents below.
                      </p>
                    </div>
                    <Form>
                      <section>
                        {/* Upload Photo ID (Drivers license) */}
                        <div className="form-group">
                          <Label className="labelTooltip">
                            Upload Photo ID (Front page of Drivers
                            License/Passport/ID)
                            <i className="fas fa-shield-alt" id="photoId" />
                            <UncontrolledTooltip
                              placement="right"
                              autohide={false}
                              target="photoId"
                            >
                              Your data is safeguarded using the highest level
                              of industry-grade encryption for protection
                            </UncontrolledTooltip>
                          </Label>
                          <div className="customFileUpload">
                            <Input
                              type="file"
                              id="photoIdUpload"
                              accept="image/*"
                              onChange={(e) => _onUploadDoc(e, "photoId")}
                            />
                            {!photoIdData ? (
                              <Label for="photoIdUpload">
                                <img
                                  src={
                                    require("../../assets/img/uploadDoc.svg")
                                      .default
                                  }
                                  alt=""
                                />
                                <div className="customUploadText">
                                  <h6>Upload Document</h6>
                                  <span>File size must be less than 5mb</span>
                                </div>
                              </Label>
                            ) : (
                              <Label for="photoIdUpload" className="uploaded">
                                <img
                                  src={
                                    require("../../assets/img/sign-up-doc.svg")
                                      .default
                                  }
                                  alt=""
                                />
                                <div className="customUploadText">
                                  <h6>{photoIdData?.uploadData?.name}</h6>
                                  <span>File size must be less than 5mb</span>
                                </div>
                              </Label>
                            )}
                          </div>
                        </div>

                        {/* Upload Address Proof (Utility Bill) */}
                        <div className="form-group">
                          <Label className="labelTooltip">
                            Upload Address Proof (Front page of Utility Bill)
                            <i
                              className="fas fa-shield-alt"
                              id="addressProof"
                            />
                            <UncontrolledTooltip
                              placement="right"
                              autohide={false}
                              target="addressProof"
                            >
                              Your data is safeguarded using the highest level
                              of industry-grade encryption for protection
                            </UncontrolledTooltip>
                          </Label>
                          <div className="customFileUpload">
                            <Input
                              type="file"
                              id="addressProofUpload"
                              accept="image/*"
                              onChange={(e) => _onUploadDoc(e, "addressProof")}
                            />
                            {!addressProofData ? (
                              <Label for="addressProofUpload">
                                <img
                                  src={
                                    require("../../assets/img/uploadDoc.svg")
                                      .default
                                  }
                                  alt=""
                                />
                                <div className="customUploadText">
                                  <h6>Upload Document</h6>
                                  <span>File size must be less than 5mb</span>
                                </div>
                              </Label>
                            ) : (
                              <Label
                                for="addressProofUpload"
                                className="uploaded"
                              >
                                <img
                                  src={
                                    require("../../assets/img/sign-up-doc.svg")
                                      .default
                                  }
                                  alt=""
                                />
                                <div className="customUploadText">
                                  <h6>{addressProofData?.uploadData?.name}</h6>
                                  <span>File size must be less than 5mb</span>
                                </div>
                              </Label>
                            )}
                            {error?.length ? (
                              <p className="form-error">{error}</p>
                            ) : null}
                          </div>
                        </div>
                      </section>

                      {/* Info video alert */}
                      <VideoAlertCard
                        title="Why Upload Docs?"
                        content="Photo ID & Address proof is required as a reference
                            of your identity & address to be sent out on the
                            first letter."
                        onOpenVideo={() => setInfoVideoModal(true)}
                      />

                      {/* submit button */}
                      <Button
                        color="primary"
                        className="btn-submit"
                        onClick={_onCompleteProfile}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <Spinner />
                            Proceed
                          </div>
                        ) : (
                          "Proceed"
                        )}
                      </Button>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>

          <div className="rightWrapper">
            {/* logo */}
            <img
              src={require("../../assets/img/logo-white.png")}
              alt="Brand Logo"
              className="companyLogo"
            />
          </div>
        </div>

        {/* footer */}
        <PublicFooter />
      </div>

      <VerifyMobileNumberModal
        isOpen={isVerifyMobileModal}
        toggle={() => _toggleVerifyMobileModal()}
        phone={formValues["phone"]?.trim()}
      />

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title={alertModalTitle}
          content={alertModalContent}
        />
      ) : null}
    </>
  );
};

export default UserInfoPage;
