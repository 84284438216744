import React from "react";
import { Card, CardBody, CardHeader, List, Spinner } from "reactstrap";
// import { copyToClipBoard } from "config/helper-config";
// import SkeletonLoader from "SkeletonLoader";

const AffiliateReferralLink = ({ affiliate, isLoading }) => {
  return (
    <>
      <Card className="commonCard">
        <CardHeader>Referral Links</CardHeader>
        <CardBody>
          <List className="userInfoList">
            <li>
              <div className="userIcon">
                <img src={require("../../assets/img/share.png")} alt="" />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div>Refer New Clients</div>
                  <a href={affiliate?.qrCodeUrl} download>
                    Download QR Code
                  </a>
                </div>

                <img
                  src={require("../../assets/img/copy.png")}
                  alt=""
                  role="button"
                  width="20px"
                  className="ms-5"
                  // onClick={() =>
                  //   copyToClipBoard(
                  //     `http://isbdc-development.surge.sh/onboarding/650d66ee8f09840302ec5783/${affiliate?.referCode}`
                  //   )
                  // }
                />
              </div>
            </li>
            <li>
              <div className="userIcon">
                <img src={require("../../assets/img/share.png")} alt="" />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div>Refer New Partners</div>
                  <a href={affiliate?.affiliateqrCodeUrl} download>
                    Download QR Code
                  </a>
                </div>
                <img
                  src={require("../../assets/img/copy.png")}
                  alt=""
                  role="button"
                  width="20px"
                  className="ms-5"
                  // onClick={() =>
                  //   copyToClipBoard(
                  //     `http://isbdc-development.surge.sh/affiliate-application/${affiliate?.referCode}`
                  //   )
                  // }
                />
              </div>
            </li>
          </List>
        </CardBody>
      </Card>
    </>
  );
};

export default AffiliateReferralLink;
