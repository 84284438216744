import { Card, CardFooter, CardHeader, Col } from "reactstrap";
import { errorHandler, formatDuration } from "helper-methods";
import { updateWatchStatus } from "http/http-calls";
import { useEffect, useState } from "react";

const VideoCard = ({ id, src, title, description, duration }) => {
  const [needStatusUpdate, setNeedStatusUpdate] = useState(false);

  const formattedDuration = formatDuration(duration);

  const _handlePlay = (e) => {
    // pause all other videos except for current one
    const videos = document.querySelectorAll("video");

    videos?.forEach((video) => {
      if (video?.id !== e?.target?.id) video?.pause();
    });
  };

  const _handleTimeUpdate = (e) => {
    if (e.target?.currentTime > 10 && !needStatusUpdate) {
      setNeedStatusUpdate(true);
    }
  };

  // effect to update watch status if video has been played for more than 10 seconds
  useEffect(() => {
    const _updateWatchStatus = async () => {
      try {
        await updateWatchStatus(id);
      } catch (error) {
        errorHandler(error);
      }
    };

    if (needStatusUpdate) {
      _updateWatchStatus();
    }
  }, [id, needStatusUpdate]);

  return (
    <Col xxl="4" lg="6" md="12">
      <Card>
        <video
          id={`video-${id}`}
          width="400"
          controls
          src={src}
          onPlay={_handlePlay}
          onTimeUpdate={_handleTimeUpdate}
        >
          Your browser does not support HTML video.
        </video>
        <CardHeader>{title || "N/A"}</CardHeader>
        <CardFooter>
          <span>{description || "N/A"}</span>
          <span>{formattedDuration}</span>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default VideoCard;
