import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { GenerateLetterInfo } from "components/credit-report";
import { errorHandler, isAfterRound2 } from "helper-methods";
import { useState } from "react";
import { generatePreviewLetter } from "http/http-calls";
import { setPreviewData } from "redux/actions";
import LoaderModal from "components/modals/LoaderModal";
import { generateLetterTexts } from "config/helper-config";
import { hasSubscription } from "guards/subscription";
import {
  getInitialGenerateLetterValues,
  isNoResponseSent,
} from "helper-methods/generate-letter-helper";
import {
  createGenerateLetterPayload,
  createPayloadForTradeline,
} from "helper-methods/preview-letter-helper";
import Summary from "components/preview-letter/Summary";
import GenerateLetterAccordion from "components/generate-letter/GenerateLetterAccordion";
import useFormValidation from "hooks/useFormValidation";
import { validateGenerateLetter } from "lib/form-validation";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { InfoTooltip } from "components/common";

const GenerateLetter = () => {
  // cache values
  const formValuesCache = JSON.parse(
    localStorage.getItem("generateLetterValues")
  );

  // states
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // hooks
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    setFormValues,
    setFormErrors,
  } = useFormValidation(
    formValuesCache || getInitialGenerateLetterValues(),
    validateGenerateLetter,
    _handleGenerateLetter
  );
  const { report, bureau, sentLetters, noResponseLetterBureau } = useSelector(
    (state) => state?.reportData || {}
  );
  const { user } = useSelector((state) => state?.userCredential || {});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // check if letter is being created for a tradeline
  const isForTradeLine = bureau === "Tradeline";

  // video modal content
  const videoModalContent = isAfterRound2()
    ? "All the disputed items will be sent out on the letter"
    : isForTradeLine
    ? "All the disputed items will be sent out on the letter"
    : `Enter the correct information against the disputed items to generate your letter`;

  // check if no response letter was sent to bureau
  const isNoResponseSentToBureau = noResponseLetterBureau?.find(
    (item) => item?.bureauName === bureau
  );

  const isLetterGeneratedForAll =
    sentLetters?.isTransUnionSent &&
    sentLetters?.isExperianSent &&
    sentLetters?.isEquifaxSent &&
    sentLetters?.isTradeLineSent;

  const allValuesFilled = Object.keys(formValues).every(
    (key) => formValues[key] !== ""
  );
  const showTooltip = !isForTradeLine && !isAfterRound2();

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  // generate letter
  async function _handleGenerateLetter() {
    try {
      setLoading(true);
      setIsOpenModal(true);
      const payload = isForTradeLine
        ? createPayloadForTradeline()
        : createGenerateLetterPayload(formValues);

      const res = await generatePreviewLetter(payload);

      if (!res?.error) {
        dispatch(setPreviewData(res?.data));
        localStorage.setItem(
          "generateLetterValues",
          JSON.stringify(formValues)
        );
        navigate("/preview-letter");
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
      setIsOpenModal(false);
    }
  }

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  // redirect to dashboard if no response letter was sent to all tradeline including bureaus or if bureauName does not exist or letter was sent to all
  if (isNoResponseSent(bureau) || !bureau || isLetterGeneratedForAll) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <>
      {/* info tooltip => show only for bureaus and not for tradelines */}
      {showTooltip && (
        <InfoTooltip
          content="Please choose or enter the correct information in the Personal
          Information section for stated disputes"
        />
      )}

      {/* info video */}
      <div className="wavesBtnWrapper posFixed">
        <div className="waves wave-1" />
        <div className="waves wave-2" />
        <div className="waves wave-3" />
        <Button
          color="link"
          className="infoVideoBtn"
          onClick={() => setInfoVideoModal(true)}
        >
          <img src={require("../../assets/img/play-button.png")} alt="" />
        </Button>
      </div>

      {/* info box */}
      <GenerateLetterInfo />

      {/* Bureau Experian */}
      {!isForTradeLine && !isNoResponseSentToBureau && (
        <Summary
          heading={`Bureau : ${bureau}`}
          content="I am writing to dispute certain inaccuracies on my credit report, as is my right under the Fair Credit Reporting Act (FCRA), 15 U.S.C. § 1681. The inaccuracies are causing undue harm and need to be corrected as soon as possible."
        />
      )}

      {/* accordion */}
      <GenerateLetterAccordion
        isForTradeLine={isForTradeLine}
        isNoResponseSentToBureau={isNoResponseSentToBureau}
        report={report}
        values={formValues}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={formErrors}
        setFormValues={setFormValues}
        setFormErrors={setFormErrors}
      />

      <Summary
        heading="Conclusion"
        content={`I have enclosed copies of documents that provide proof of my identity.
I expect a prompt correction of these inaccuracies as mandated by 15 U.S.C. § 1681i(a), which requires a reasonable reinvestigation within 30 days of receiving a dispute. I am available for any further clarification you may require. You may reach me at ${user?.phone} or ${user?.email}`}
      />

      {/* submit button */}
      <div className="inlineBtnWrapper">
        <Button
          color="primary"
          disabled={
            loading ||
            (isForTradeLine || isAfterRound2() ? false : !allValuesFilled)
          }
          onClick={handleSubmit}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Spinner />
              Proceed
            </div>
          ) : (
            "Proceed"
          )}
        </Button>
      </div>

      {isOpenModal ? (
        <LoaderModal
          title="Generating Preview"
          isOpen={isOpenModal}
          toggle={_toggleModal}
          texts={generateLetterTexts}
        />
      ) : null}

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title="Generate Letter"
          content={videoModalContent}
        />
      ) : null}
    </>
  );
};

export default GenerateLetter;
