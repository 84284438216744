import { List } from "reactstrap";

const LeadPopupInfoList = ({ circuit, state, caseTypes, totalDefendants }) => {
  return (
    <div className="features-list">
      <List className="mt-0">
        <li>
          <strong>Circuit</strong>
          <span>{circuit}</span>
        </li>

        <li>
          <strong>State:</strong>
          <span>{state}</span>
        </li>

        <li>
          <strong style={{ whiteSpace: "nowrap" }}>Case Types:</strong>
          <span style={{ maxWidth: "30ch" }}>{caseTypes}</span>
        </li>

        <li>
          <strong>Total Defendant:</strong>
          <span>{totalDefendants}</span>
        </li>
      </List>
    </div>
  );
};

export default LeadPopupInfoList;
