import { useState } from "react";
import { Button, Card, Table } from "reactstrap";
import { useSelector } from "react-redux";

const INITIAL_ITEMS_COUNT = 3;

const Inquiries = () => {
  // states
  // const [isOpenCreditModal, setIsOpenCreditModal] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(INITIAL_ITEMS_COUNT);
  // const [inquiryIndex, setInquiryIndex] = useState(null);

  // hooks
  const { inquiries } = useSelector((state) => state?.reportData?.report || {});

  // const _toggleCreditModal = (isOpenCreditModal = false) => {
  //   setIsOpenCreditModal(isOpenCreditModal);
  // };

  const _handleSeeMore = () =>
    setItemsToShow((prev) =>
      prev === inquiries?.length
        ? INITIAL_ITEMS_COUNT
        : prev + INITIAL_ITEMS_COUNT > inquiries?.length
        ? inquiries?.length
        : prev + INITIAL_ITEMS_COUNT
    );

  return (
    <>
      <section>
        <h6>Inquiries</h6>

        {/* Trade Line (AMEX) */}
        <Card body>
          <Table responsive>
            <thead>
              <tr>
                <th>Items</th>
                <th>Bureaus</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th></th>
                <th>TransUnion</th>
                <th>Experian</th>
                <th>Equifax</th>
              </tr>
            </thead>

            <tbody>
              {inquiries?.length
                ? inquiries?.slice(0, itemsToShow)?.map((inquiry, index) => (
                    <tr key={index}>
                      <td>{inquiry?.creditorName}</td>
                      {inquiry?.data?.length
                        ? inquiry?.data?.map((data, index) => (
                            <td key={index}>
                              {data === null ? (
                                "-"
                              ) : (
                                <span
                                  className={
                                    data ? "text-success" : "text-danger"
                                  }
                                >
                                  {data ? "Correct" : "Incorrect"}
                                </span>
                              )}
                            </td>
                          ))
                        : null}
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>

          {/* See More */}
          <Button color="link" className="h-auto mb-2" onClick={_handleSeeMore}>
            See {itemsToShow === inquiries?.length ? "Less" : "More"}{" "}
            <i
              className={
                itemsToShow === inquiries?.length
                  ? "fa fa-chevron-up"
                  : "fa fa-chevron-down"
              }
            />
          </Button>
        </Card>
      </section>

      {/* {isOpenCreditModal && (
        <CreditReportUserInputModal
          isOpen={isOpenCreditModal}
          toggle={() => _toggleCreditModal()}
          userInput="Inquiry"
          inquiryIndex={inquiryIndex}
        />
      )} */}
    </>
  );
};

export default Inquiries;
