import { DamageEstimateForm } from "components/forms/DamageEstimateForm";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const DamageEstimatesModal = ({ isOpen, onSubmit, caseTypesData }) => {
  return (
    <Modal isOpen={isOpen} scrollable centered>
      <ModalHeader>Questionnaire</ModalHeader>

      <ModalBody>
        <DamageEstimateForm
          onSubmit={onSubmit}
          caseTypePayload={caseTypesData}
        />
      </ModalBody>
    </Modal>
  );
};

export default DamageEstimatesModal;
