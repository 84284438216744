import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css/animate.min.css";
import App from "./App";
import { persistor, store } from "./redux/store";
import "./assets/styles/scss/index.scss";
import "react-circular-progressbar/dist/styles.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-datepicker/dist/react-datepicker.css";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_API_KEY } from "config";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(STRIPE_API_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </PersistGate>
  </Provider>
);
