import { useState } from "react";
import { generateLetter, saveLetterAsPDF } from "http/http-calls";
import { errorHandler, showToast } from "helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { setSentLetters } from "redux/actions";
import { isNoResponseSent } from "helper-methods/generate-letter-helper";

const usePreviewActions = () => {
  const [loading, setLoading] = useState(false);
  const [saveInProgress, setSaveInprogress] = useState(false);

  const { preview } = useSelector((state) => state?.reportData || {});
  const { bureau } = preview || {};

  const dispatch = useDispatch();

  const _sendLetter = async (cb) => {
    try {
      setLoading(true);
      const res = await generateLetter({
        bureau,
        isAllExperianBlocked: isNoResponseSent("Experian"),
        isAllEquifaxBlocked: isNoResponseSent("Equifax"),
        isAllTransUnionBlocked: isNoResponseSent("TransUnion"),
        isAllTradelineBlocked: isNoResponseSent("TradeLine"),
      });

      if (!res?.error) {
        showToast("Letter sent successfully", "success", 2000);
        // clear cache values for generate letter
        localStorage.removeItem("generateLetterValues");
        localStorage.removeItem("checkboxState");
        // update sentLetters state in redux
        const isLetterSentToAll =
          res?.isTransUnionSent &&
          res?.isExperianSent &&
          res?.isEquifaxSent &&
          res?.isTradeLineSent;

        dispatch(
          setSentLetters({
            isTransUnionSent: res?.isTransUnionSent,
            isExperianSent: res?.isExperianSent,
            isEquifaxSent: res?.isEquifaxSent,
            isTradeLineSent: res?.isTradeLineSent,
          })
        );

        cb(isLetterSentToAll);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _saveAsPDF = async () => {
    try {
      setSaveInprogress(true);
      const res = await saveLetterAsPDF(preview?.previewId || preview?._id);
      if (!res?.error && res?.data?.length) {
        res?.data?.forEach((item) => window.open(item?.letterAwsLocation));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setSaveInprogress(false);
    }
  };

  return {
    loading,
    saveInProgress,
    sendLetter: _sendLetter,
    saveAsPDF: _saveAsPDF,
  };
};

export default usePreviewActions;
