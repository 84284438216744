import { Button, Alert } from "reactstrap";

const VideoAlertCard = ({
  title,
  content,
  onOpenVideo,
  showAlert,
  onDismiss,
  className,
}) => {
  return (
    <Alert
      color="info"
      isOpen={showAlert}
      className={"infoVideoAlert " + className}
      toggle={onDismiss}
    >
      {title && <h6>{title}</h6>}
      <div className="infoVideoBox">
        <div className="wavesBtnWrapper">
          <div className="waves wave-1" />
          <div className="waves wave-2" />
          <div className="waves wave-3" />
          <Button color="link" className="infoVideoBtn" onClick={onOpenVideo}>
            <img
              src={require("../../assets/img/play-button.png")}
              alt="play button"
            />
          </Button>
        </div>
        <p>{content}</p>
      </div>
    </Alert>
  );
};

export default VideoAlertCard;
