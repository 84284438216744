import Disputes from "pages/protected/Disputes";
import SentLetters from "pages/protected/SentLetters";
import { Input } from "reactstrap";

const LeadDisputeAnalysis = ({ lead, data, type, setSearchParams }) => {
  const _handleChangeDisputeType = (e) => {
    if (e.target.value === "history") setSearchParams({ type: e.target.value });
    else setSearchParams({ type: e.target.value, letterType: "bureau" });
  };

  return (
    <section>
      {/* heading */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="mb-0">Dispute Analysis</h6>
        <div className="formGroup">
          <Input type="select" value={type} onChange={_handleChangeDisputeType}>
            <option value="history">Dispute History</option>
            <option value="letter">Sent Letters</option>
          </Input>
        </div>
      </div>

      {/* dispute history */}
      {type === "history" ? (
        <Disputes leadId={lead?._id} userType="Attorney" data={data} />
      ) : (
        <SentLetters leadId={lead?._id} userType="Attorney" />
      )}
    </section>
  );
};

export default LeadDisputeAnalysis;
