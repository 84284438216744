import { Button } from "reactstrap";
import {
  CreditItems,
  CreditScores,
  History,
  ReminderAlerts,
  RepairActivity,
  Reports,
} from "components/dashboard";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { canProceedToNextRound, errorHandler } from "helper-methods";
import { getDashboardData } from "http/http-calls";
import { hasSubscription } from "guards/subscription";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  setCurrentRound,
  setNextRoundDate,
  setSentLetters,
  showLoader,
} from "redux/actions";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { bureaus } from "config/helper-config";
import { InfoTooltip, VideoAlertCard } from "components/common";

const DashboardPage = () => {
  // states
  const [dashboardData, setDashboardData] = useState();
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [showAlertCard, setShowAlertCard] = useState(
    !JSON.parse(sessionStorage.getItem("hadDismissedDashboardAlert")) &&
      canProceedToNextRound() // if 40 days has passed and user has not closed the alert then show alert
  );

  // hooks
  const dispatch = useDispatch();
  const { currentRound, sentLetters } = useSelector(
    (state) => state?.reportData || {}
  );

  const isReminderMessagePresent = dashboardData?.user?.message?.length > 0;
  const reports = dashboardData?.user?.creditScoreHistory?.reverse();
  const historyData = dashboardData?.user?.graphData?.[0];
  const creditItems = {
    transUnion: dashboardData?.user?.transCreditItem,
    experian: dashboardData?.user?.expCreditItem,
    equifax: dashboardData?.user?.equiCreditItem,
  };
  const repairActivity = {
    transUnion: dashboardData?.tranActivity,
    experian: dashboardData?.expActivity,
    equifax: dashboardData?.equiActivity,
  };

  const isLetterSentToAll =
    sentLetters?.isTransUnionSent &&
    sentLetters?.isExperianSent &&
    sentLetters?.isEquifaxSent &&
    sentLetters?.isTradeLineSent;

  const videoModalContent = !isLetterSentToAll
    ? "Generate & send the pending letters to complete the process"
    : "Difference between Generating & Uploading a new report";

  const videoModalTitle = !isLetterSentToAll
    ? "Alert Message"
    : "Video Tutorial";

  let notSentNames;

  const _getTooltipText = () => {
    const notSent = bureaus.filter((item) => !sentLetters?.[`is${item}Sent`]);
    if (!sentLetters?.isTradeLineSent) notSent.push("Tradelines");

    notSentNames = new Intl.ListFormat("en", {
      style: "long",
      type: "conjunction",
    }).format(notSent);

    return `You need to send the dispute letters to ${notSentNames} to generate your report after 40 days. Please go to ‘Dispute History’ page to generate letters`;
  };

  /* 
     - when user dismisses alert then save the state in session storage
     - this will prevent the alert to come up again if the user revisits the page
  */
  const _onDismiss = () => {
    sessionStorage.setItem("hadDismissedDashboardAlert", JSON.stringify(true));
    setShowAlertCard(false);
  };

  useEffect(() => {
    const _fetchDashboardData = async () => {
      try {
        dispatch(showLoader());
        const res = await getDashboardData();
        if (!res?.error) {
          const data = { ...res };
          delete data.error;
          setDashboardData(data);
          dispatch(setNextRoundDate(data?.user?.nextRoundDate));
          dispatch(setCurrentRound(+data?.user?.round?.slice(-1)));
          dispatch(
            setSentLetters({
              isTransUnionGenerated: !data?.isProceedPendingTransunion,
              isExperianGenerated: !data?.isProceedPendingExperian,
              isEquifaxGenerated: !data?.isProceedPendingEquifax,
              isTradeLineGenerated: !data?.isProceedPendingTradeLine,
              isTransUnionSent: !data?.isLetterSentPendingTransunion,
              isExperianSent: !data?.isLetterSentPendingExperian,
              isEquifaxSent: !data?.isLetterSentPendingEquifax,
              isTradeLineSent: !data?.isLetterSentPendingTradeLine,
            })
          );
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        dispatch(hideLoader());
      }
    };

    _fetchDashboardData();
  }, [dispatch]);

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      {/* info tooltip */}
      {!isLetterSentToAll ? <InfoTooltip content={_getTooltipText()} /> : null}

      {isReminderMessagePresent && (
        <ReminderAlerts
          userId={dashboardData?.user?._id}
          messages={dashboardData?.user?.message}
        />
      )}

      {/* Info video alert card */}
      <VideoAlertCard
        content="Difference between Generating & Uploading a new report"
        onOpenVideo={() => setInfoVideoModal(true)}
        showAlert={showAlertCard}
        onDismiss={_onDismiss}
      />

      {/* Info video button */}
      {!isLetterSentToAll && (
        <div className="wavesBtnWrapper posFixed">
          <div className="waves wave-1" />
          <div className="waves wave-2" />
          <div className="waves wave-3" />
          <Button
            color="link"
            className="infoVideoBtn"
            onClick={() => setInfoVideoModal(true)}
          >
            <img src={require("../../assets/img/play-button.png")} alt="" />
          </Button>
        </div>
      )}

      {/* Credit scores based on generation of latest report */}
      {currentRound !== 0 && (
        <section className="dashboardReport">
          <h6>Based on generation of latest report</h6>
          <CreditScores data={dashboardData} />
        </section>
      )}

      {currentRound !== 0 && <RepairActivity activity={repairActivity} />}
      <CreditItems creditItems={creditItems} />
      <History data={historyData} />
      <Reports reports={reports} />

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title={videoModalTitle}
          content={videoModalContent}
        />
      ) : null}
    </>
  );
};

export default DashboardPage;
