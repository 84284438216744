export const CIRCUIT_DATA = [
  {
    CircuitName: "1",
    States: [
      "Maine",
      "Massachusetts",
      "New Hampshire",
      "Puerto Rico",
      "Rhode Island",
    ],
  },

  {
    CircuitName: "2",
    States: ["Connecticut", "New York", "Vermont"],
  },

  {
    CircuitName: "3",
    States: ["Delaware", "New Jersey", "Pennsylvania", "Virgin Islands"],
  },

  {
    CircuitName: "4",
    States: [
      "Maryland",
      "North Carolina",
      "South Carolina",
      "Virginia, West Virginia",
    ],
  },

  {
    CircuitName: "5",
    States: ["District of the Canal Zone", "Louisiana", "Mississippi", "Texas"],
  },

  {
    CircuitName: "6",
    States: ["Kentucky", "Michigan", "Ohio", "Tennessee"],
  },

  {
    CircuitName: "7",
    States: ["Illinois", "Indiana", "Wisconsin"],
  },

  {
    CircuitName: "8",
    States: [
      "Arkansas",
      "Iowa",
      "Minnesota",
      "Missouri",
      "Nebraska",
      "North Dakota",
      "South Dakota",
    ],
  },

  {
    CircuitName: "9",
    States: [
      "Alaska",
      "Arizona",
      "California",
      "Idaho",
      "Montana",
      "Nevada",
      "Oregon",
      "Washington",
      "Guam",
      "Hawaii",
    ],
  },

  {
    CircuitName: "10",
    States: ["Colorado, Kansas", "New Mexico", "Oklahoma", "Utah", "Wyoming"],
  },

  {
    CircuitName: "11",
    States: ["Alabama", "Florida", "Georgia"],
  },

  {
    CircuitName: "Federal",
    States: ["All Federal Judicial Districts"],
  },
];
