import { Card, Col } from "reactstrap";
import defaultProfile from "assets/img/default-profile.svg";
import {
  LeadCardActions,
  LeadCardHeader,
  LeadInfoList,
  LeadProfilePopup,
} from ".";
import { useNavigate } from "react-router-dom";
import { getCaseTypes } from "helper-methods/leads-helper";
import { useSelector } from "react-redux";

const LeadCard = ({ onUnlockLead, lead, loading }) => {
  const { credit } = useSelector((state) => state?.userCredential?.user || {});
  const navigate = useNavigate();

  // constants
  const isLocked = lead?.isLocked;
  const leadName = lead?.name?.full;
  const leadProfilePic = lead?.profilePic;
  const leadCredits = `${lead?.credits} ${
    lead?.credits === 1 ? "Credit" : "Credits"
  }`;
  const hasEnoughCredits = credit >= lead?.credits;

  const leadInfoListProps = {
    id: lead?._id,
    circuit: lead?.currentMailingAddress?.circuit || "N/A",
    damageEstimate: lead?.damageEstimates,
    caseTypes: getCaseTypes(lead?.caseQuestion),
    totalDefendants: lead?.noOfDefendants || 0,
  };

  const leadProfilePopupProps = {
    id: lead?._id,
    name: lead?.name?.full,
    avatar: leadProfilePic || defaultProfile,
    credits: `${lead?.credits} ${lead?.credits === 1 ? "Credit" : "Credits"}`,
    circuit: lead?.currentMailingAddress?.circuit || "N/A",
    state: lead?.currentMailingAddress?.state || "N/A",
    caseTypes: getCaseTypes(lead?.caseQuestion),
    totalDefendants: lead?.noOfDefendants || 0,
  };

  return (
    <Col xl="6">
      <Card body>
        {/* header */}
        <LeadCardHeader
          leadName={leadName}
          leadCredits={leadCredits}
          leadAvatar={leadProfilePic || defaultProfile}
        />

        <LeadInfoList {...leadInfoListProps} />

        <LeadCardActions
          leadId={lead?._id}
          loading={loading}
          isLeadLocked={isLocked}
          onUnlockLead={onUnlockLead.bind(this, hasEnoughCredits)}
        />

        {!isLocked ? (
          <LeadProfilePopup
            {...leadProfilePopupProps}
            onViewDetails={(id) => navigate(`/lead/${id}`)}
          />
        ) : null}
      </Card>
    </Col>
  );
};

export default LeadCard;
