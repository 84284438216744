import { useEffect, useState } from "react";
import useFormValidation from "./useFormValidation";
import { validatePaymentDetails } from "lib/form-validation";
import {
  addDefaultCard,
  buySubscription,
  buySubscriptionAttorney,
  getUserDetails,
} from "http/http-calls";
import { errorHandler, showToast } from "helper-methods";
import { updateUserData } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useMembership = (plan, type, isAttorney = false) => {
  // states
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state?.userCredential || {});
  const {
    formErrors,
    formValues,
    setFormValues,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
  } = useFormValidation(
    {
      cardCountry: "usa",
      fullname: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      terms: false,
    },
    validatePaymentDetails,
    _processPayment
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function to handle payment while buying subscription
  async function _processPayment(cardToken) {
    setLoading(true);
    try {
      // // call api to add default card
      await addDefaultCard({ token: cardToken });

      const address = formValues["address"]?.trim();
      const index = address?.indexOf(" ");
      const streetLine1 = address?.slice(index, address?.length)?.trim();
      const streetLine2 = address?.split(" ")?.[0]?.trim();

      // call api to buy subscription
      const buySubscriptionPayload = {
        subscriptionTier: plan,
        billingPackage: isAttorney ? type : undefined,
        name: formValues["fullname"],
        streetLine1,
        streetLine2,
        city: formValues["city"],
        state: formValues["state"],
        zip: formValues["zip"],
        ssn: !isAttorney ? formValues["ssn"] : undefined,
      };

      const data = isAttorney
        ? await buySubscriptionAttorney(buySubscriptionPayload)
        : await buySubscription(buySubscriptionPayload);

      if (!data?.error) {
        showToast("Subscription was successful", "success", 2000);

        // get updated user details
        const userData = await getUserDetails();

        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }

        isAttorney ? navigate("/leads") : navigate("/user-info");
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  // set initial form values for billing address
  useEffect(() => {
    if (user)
      setFormValues((prev) => ({
        ...prev,
        fullname: user?.name?.full,
      }));
  }, [setFormValues, user]);

  return {
    loading,
    formValues,
    formErrors,
    setFormValues,
    setLoading,
    handleInputChange,
    handleInputBlur,
    onBuyMembership: handleSubmit,
  };
};

export default useMembership;
