export const regexConfig = {
  splitName: /^[a-zA-Z]+$/,
  phone: /^(\+\d{1,3}[- ]?)?\d{8,11}$/,
  address: /^\d+\s?.*$/,
  email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/,
  digitOnly: /^[0-9]*$/,
  ipAddress:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  name: /^[a-zA-Z'][a-zA-Z' ]{1,23}[a-zA-Z']?$/,
  withoutSpaceText: /^\S+$/,
  isoDate: /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/,
  panNumber: /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  username: /^[a-z0-9_-]{3,16}$/,
  aadharCard: /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/,
  panCard: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  nameWithoutSpecialCharactersAndNumber: /^[a-zA-Z ]{0,}$/,
  alphanumeric: /^[0-9a-zA-Z]+$/,
  alphanumericMultiWord: /^[0-9a-zA-Z-,'/ ]{0,}$/,
  ifscValidation: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  noSpecialChar: /^[A-Za-z0-9 ]{11,17}$/,
  title: /^(?=.{2,50}$)[a-zA-Z0-9]+$/,
  fourDigits: /^\d{4}$/,
  withinThreeDigits: /^[0-9]{1,3}$/,
  withinSevenDigits: /^[0-9]{1,7}$/,
  zipCode: /^\d{5}$/,
  twoToFiftyChars: /^[a-zA-Z\s]{2,50}$/,
  stripeCard:
    /^(?:\d{4}\s?\d{6}\s?\d{4}|\d{6}\s?\d{6}\s?\d{4}|\d{4}\s?\d{6}\s?\d{6})$/,
  cvv: /^\d{3,4}$/,
  ssn: /^(X{5}\d{4}|\d{9})$/,
  income: /^\d+$/,
};
