import GenerateLetterInfo from "components/credit-report/GenerateLetterInfo";
import { useSelector } from "react-redux";
import { hasSubscription } from "guards/subscription";
import { Navigate } from "react-router-dom";
import DocumentReference from "components/preview-letter/DocumentReference";
import PreviewSectionWrapper from "components/preview-letter/PreviewSectionWrapper";
import PreviewActions from "components/preview-letter/PreviewActions";

const PreviewLetter = () => {
  const { preview, currentRound } = useSelector(
    (state) => state?.reportData || {}
  );
  const { photoId, addressProof } = useSelector(
    (state) => state?.userCredential?.user || {}
  );

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  // redirect to dashboard if preview data does not exist
  if (!preview) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <>
      {/* info box */}
      <GenerateLetterInfo />

      {/* Preview Section */}
      <PreviewSectionWrapper />

      {/* Document Referance */}
      {currentRound === 1 ? (
        <DocumentReference photoId={photoId} addressProof={addressProof} />
      ) : null}

      {/* Actions */}
      <PreviewActions />
    </>
  );
};

export default PreviewLetter;
