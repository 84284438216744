import React, { useState } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import SubscriptionCard from "components/subscription/SubscriptionCard";
import { subscriptionFeatures } from "config/helper-config";

const SubscriptionAttorney = () => {
  const [isYearlySelected, setIsYearlySelected] = useState(false);

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap select-subscription flex-column">
          <div className="authWrapper-banner">
            <h2>Select Your Subscription</h2>

            {/* custom switch */}
            <FormGroup switch className="customSwitch">
              <Input
                type="switch"
                id="customSwitch"
                checked={isYearlySelected}
                onChange={(e) => setIsYearlySelected(e.target.checked)}
                onClick={() => {
                  setIsYearlySelected(!isYearlySelected);
                }}
              />

              <Label for="customSwitch">
                <span>Monthly</span>
                <span>Yearly</span>
                <span className="pos-abs"></span>
              </Label>
            </FormGroup>

            {isYearlySelected ? (
              <h6 className="mt-3 text-white fs-20">Save 15% with yearly</h6>
            ) : null}
          </div>

          {/* cards */}
          <section className="subscriptionPlanCards ">
            <SubscriptionCard
              userType="attorney"
              title="Basic"
              description="Lorem Ipsum is simply dummy text"
              planAmount={!isYearlySelected ? 100 : 999}
              planDuration={!isYearlySelected ? "Monthly" : "Yearly"}
              planType="Basic"
              planTypeText={!isYearlySelected ? "per month" : "per year"}
              featuresList={subscriptionFeatures?.attorney?.Basic}
            />

            <SubscriptionCard
              className="premium"
              userType="attorney"
              title="Pro"
              tag="Most Popular"
              description="Lorem Ipsum is simply dummy text"
              planAmount={!isYearlySelected ? 300 : 2999}
              planDuration={!isYearlySelected ? "Monthly" : "Yearly"}
              planType="Pro"
              planTypeText={!isYearlySelected ? "per month" : "per year"}
              featuresList={subscriptionFeatures?.attorney?.Pro}
            />

            <SubscriptionCard
              userType="attorney"
              title="Elite"
              description="Lorem Ipsum is simply dummy text"
              planAmount={!isYearlySelected ? 500 : 4999}
              planDuration={!isYearlySelected ? "Monthly" : "Yearly"}
              planType="Elite"
              planTypeText={!isYearlySelected ? "per month" : "per year"}
              featuresList={subscriptionFeatures?.attorney?.Elite}
            />
          </section>
        </div>

        {/* footer */}
        <PublicFooter />
      </div>
    </>
  );
};

export default SubscriptionAttorney;
