import { useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";

const ReportSummary = () => {
  // hooks
  const {
    profileDisputecount,
    accountHistoryDisputeCount,
    inquiriesDisputeCount,
  } = useSelector((state) => state?.reportData?.report || {});

  return (
    <section className="report-summary">
      <h6>Report Summary</h6>

      {/* cards */}
      <Row className="gy-4">
        <Col xl="4" lg="6">
          <Card body className="shadow h-100">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column me-2" style={{ flex: "1" }}>
                <strong
                  className={
                    !profileDisputecount ? "fs-20 noDisputes" : "fs-20"
                  }
                >
                  {profileDisputecount || 0}
                </strong>
                <span className="text-secondary fs-14 mt-1">
                  Disputes found in Personal Information
                </span>
              </div>

              <div
                className={
                  !profileDisputecount
                    ? "roundImgWrapper noDisputes"
                    : "roundImgWrapper"
                }
              >
                <img
                  src={
                    !profileDisputecount
                      ? require("../../assets/img/icon1-green.svg").default
                      : require("../../assets/img/icon1.svg").default
                  }
                  alt=""
                />
              </div>
            </div>
          </Card>
        </Col>

        <Col xl="4" lg="6">
          <Card body className="shadow h-100">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column me-2" style={{ flex: "1" }}>
                <strong
                  className={
                    !accountHistoryDisputeCount ? "fs-20 noDisputes" : "fs-20"
                  }
                >
                  {accountHistoryDisputeCount || 0}
                </strong>
                <span className="text-secondary fs-14 mt-1">
                  Disputes found in Account History
                </span>
              </div>
              <div
                className={
                  !accountHistoryDisputeCount
                    ? "roundImgWrapper noDisputes"
                    : "roundImgWrapper"
                }
              >
                <img
                  src={
                    !accountHistoryDisputeCount
                      ? require("../../assets/img/icon2-green.svg").default
                      : require("../../assets/img/icon2.svg").default
                  }
                  alt=""
                />
              </div>
            </div>
          </Card>
        </Col>

        <Col xl="4" lg="6">
          <Card body className="shadow h-100">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column me-2" style={{ flex: "1" }}>
                <strong
                  className={
                    !inquiriesDisputeCount ? "fs-20 noDisputes" : "fs-20"
                  }
                >
                  {inquiriesDisputeCount || 0}
                </strong>
                <span className="text-secondary fs-14 mt-1">
                  Dispute found in Inquiries
                </span>
              </div>
              <div
                className={
                  !inquiriesDisputeCount
                    ? "roundImgWrapper noDisputes"
                    : "roundImgWrapper"
                }
              >
                <img
                  src={
                    !inquiriesDisputeCount
                      ? require("../../assets/img/icon3-green.svg").default
                      : require("../../assets/img/icon3.svg").default
                  }
                  alt=""
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default ReportSummary;
