import { getChartData } from "helper-methods/dashboard-helper";
import { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Label,
} from "reactstrap";

const History = ({ data }) => {
  // states
  const [bureau, setBureau] = useState("");
  const [chartOptions, setChartOptions] = useState({
    series: [],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "month",
        categories: [],
      },
      tooltip: {
        x: {
          format: "MM",
        },
      },
    },
  });

  // update chart options
  const _updateChartOptions = useCallback(() => {
    const { series, categories } = getChartData(data);

    if (series?.length && categories?.length) {
      setChartOptions((prev) => {
        const result = {
          ...prev,
          series,
          options: {
            ...prev.options,
            xaxis: { ...prev.options.xaxis, categories },
          },
        };
        return result;
      });
    }
  }, [data]);

  // handle bureau change
  const _handleChangeBureau = (value) => {
    setBureau(value);

    const item = data?.find((item) => item?.bureauName === value);
    const series = [
      {
        name: item?.bureauName,
        data: item?.data?.map((each) => +each?.score),
      },
    ];

    setChartOptions((prev) => ({ ...prev, series }));
  };

  useEffect(() => {
    if (!bureau?.length) {
      _updateChartOptions();
    }
  }, [_updateChartOptions, bureau]);

  return (
    <section>
      <Card>
        <CardHeader>
          <CardTitle className="d-flex gap-3 align-items-center">
            History{" "}
          </CardTitle>

          {/* Bureau */}
          <div className="formGroup">
            <Label>Bureau</Label>
            <Input
              type="select"
              bsSize="sm"
              value={bureau}
              onChange={(e) => _handleChangeBureau(e.target.value)}
            >
              <option value="">All</option>
              <option value="TransUnion">TransUnion</option>
              <option value="Experian">Experian</option>
              <option value="Equifax">Equifax</option>
            </Input>
          </div>
        </CardHeader>

        <CardBody>
          <ReactApexChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="area"
            height={350}
          />
        </CardBody>
      </Card>
    </section>
  );
};

export default History;
