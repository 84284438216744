import { Col, Input, Label, Row } from "reactstrap";

const AttorneyBasicInfo = ({
  formValues,
  formErrors,
  handleInputChange,
  handleInputBlur,
}) => {
  return (
    <section>
      <Row className="p-2 rounded-3 bg-light m-0">
        <Col md="6">
          {/* name */}
          <div className="form-group">
            <Label>Name</Label>
            <Input
              placeholder="Enter"
              name="name"
              value={formValues["name"]}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />

            {formErrors["name"] ? (
              <p className="form-error">{formErrors["name"]}</p>
            ) : null}
          </div>
        </Col>

        <Col md="6">
          {/* Email */}
          <div className="form-group">
            <Label>Email</Label>
            <Input
              type="email"
              placeholder="Enter"
              name="email"
              value={formValues["email"]}
              readOnly
              disabled
            />
          </div>
        </Col>

        <Col md="6">
          {/* phone number */}
          <div className="form-group">
            <Label>Phone Number</Label>
            <Input
              placeholder="Enter"
              name="phone"
              value={formValues["phone"]}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />

            {formErrors["phone"] ? (
              <p className="form-error">{formErrors["phone"]}</p>
            ) : null}
          </div>
        </Col>

        <Col md="6">
          {/* Company Name */}
          <div className="form-group">
            <Label>Company Name</Label>
            <Input
              placeholder="Enter"
              name="companyName"
              value={formValues["companyName"]}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />

            {formErrors["companyName"] ? (
              <p className="form-error">{formErrors["companyName"]}</p>
            ) : null}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default AttorneyBasicInfo;
