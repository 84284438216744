import { isAfterRound2 } from "helper-methods";
import { useSelector } from "react-redux";
import { FormGroup, Input, Label, List } from "reactstrap";

const AddressDisputeField = ({
  values,
  errors,
  onChange,
  onBlur,
  address,
  onChangeSuggestion,
}) => {
  // hooks
  const { currentMailingAddress } = useSelector(
    (state) => state?.reportData?.report || {}
  );

  return (
    <>
      <div className="form-group mb-0">
        <Label for="checkbox2">
          Incorrect Address{" "}
          <span className="disputeValue">
            {isAfterRound2() ? "" : `(${address?.dataValue})`}
          </span>
        </Label>

        {!isAfterRound2() ? (
          <Input
            type="textarea"
            placeholder="Enter your correct address"
            className="mt-1"
            name="address"
            value={values["address"]}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : null}

        {/* Suggested  Incorrect address */}
        <div className="incorrectNameList">
          <Label>{isAfterRound2() ? "Dispute: " : "Suggestions: "}</Label>
          <List>
            <li>
              {isAfterRound2() ? (
                <span>{address?.dataValue}</span>
              ) : (
                <FormGroup check inline>
                  <Input
                    id="incorretAddress1"
                    type="radio"
                    name="incorrectAddress"
                    value={currentMailingAddress?.address}
                    onChange={(e) =>
                      onChangeSuggestion("address", e.target.value)
                    }
                    checked={
                      values["address"] === currentMailingAddress?.address
                    }
                  />
                  <Label for="incorretAddress1">
                    {currentMailingAddress?.address}
                  </Label>
                </FormGroup>
              )}
            </li>
          </List>
        </div>

        {errors["address"] ? (
          <p className="form-error">{errors["address"]}</p>
        ) : null}
      </div>
      <hr />
    </>
  );
};

export default AddressDisputeField;
