import moment from "moment";

const PaymentHistoryData = ({ data }) => {
  const { subscriptionTier, transactionId, amount, createdAt } = data;
  const formattedDate = moment(new Date(createdAt)).format("ll");

  return (
    <tr>
      <td>{subscriptionTier} Subscription</td>
      <td>{transactionId || "N/A"}</td>
      <td>Visa 4242</td>
      <td>${amount}</td>
      <td>{formattedDate}</td>
    </tr>
  );
};

export default PaymentHistoryData;
