import React from "react";
import { Col, Row } from "reactstrap";
import CreditScoreCard from "./CreditScoreCard";
import moment from "moment";

const CreditScores = ({ data }) => {
  const [latestTransUnionScore, latestExperianScore, latestEquifaxScore] = [
    data?.user?.latestTransUnionScore,
    data?.user?.latestExperianScore,
    data?.user?.latestEquifaxScore,
  ];
  const latestReportDate = moment(
    new Date(data?.user?.latestReportDate)
  ).format("ll");

  return (
    <Row className="gy-4">
      <Col xl="4" lg="6">
        <CreditScoreCard
          bureau="TransUnion"
          score={latestTransUnionScore}
          date={latestReportDate}
        />
      </Col>

      <Col xl="4" lg="6">
        <CreditScoreCard
          bureau="Experian"
          score={latestExperianScore}
          date={latestReportDate}
        />
      </Col>

      <Col xl="4" lg="6">
        <CreditScoreCard
          bureau="Equifax"
          score={latestEquifaxScore}
          date={latestReportDate}
        />
      </Col>
    </Row>
  );
};

export default CreditScores;
