import { LeadInfoListItem } from ".";

const LeadInfoList = ({
  id,
  circuit,
  damageEstimate,
  caseTypes,
  totalDefendants,
}) => {
  return (
    <div className="info-list">
      <ul className="col-2">
        <LeadInfoListItem
          id={`circuit_${id}`}
          title="Circuit"
          value={circuit}
          showTooltip={false}
          icon={require("../../../assets/img/bank.png")}
        />

        <LeadInfoListItem
          id={`damageEstimate_${id}`}
          title="Damage Estimate"
          value={damageEstimate}
          showTooltip={false}
          icon={require("../../../assets/img/dollar-symbol.png")}
        />

        <LeadInfoListItem
          id={`caseTypes_${id}`}
          title="Case Types"
          value={caseTypes}
          showTooltip={caseTypes !== "N/A"}
          icon={require("../../../assets/img/document.png")}
        />

        <LeadInfoListItem
          id={`defendants_${id}`}
          title="Total Defendants"
          value={totalDefendants}
          showTooltip={false}
          icon={require("../../../assets/img/users.png")}
        />
      </ul>
    </div>
  );
};

export default LeadInfoList;
