import React, { forwardRef } from "react";
import { CardTitle, Col } from "reactstrap";
import PaymentDetailsForm from "./PaymentDetailsForm";
import moment from "moment";

const PaymentDetails = forwardRef(
  (
    {
      planType,
      formValues,
      formErrors,
      setFormValues,
      onChange,
      onBlur,
      amount,
      onAddCard,
    },
    ref
  ) => {
    const subscriptionEndDate =
      planType === "monthly"
        ? moment().add(31, "d").format("ll")
        : moment().add(1, "year").format("ll");

    return (
      <Col md="12" xl="6">
        <CardTitle>Payment Details</CardTitle>

        <PaymentDetailsForm
          ref={ref}
          formValues={formValues}
          formErrors={formErrors}
          setFormValues={setFormValues}
          onChange={onChange}
          onBlur={onBlur}
          onAddCard={onAddCard}
        />

        {/* Monthly Fee */}
        <div className="mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <p className="text-dark">
              {planType === "yearly" ? "Yearly" : "Monthly"} Fee
            </p>
            <p className="text-dark">${amount}</p>
          </div>
          <p>
            Next payment ${amount} on {subscriptionEndDate}
          </p>
        </div>

        {/* Payment Due Today */}
        <div className="mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <p className="text-dark">Payment Due Today</p>
            <p className="text-dark">${amount}</p>
          </div>
        </div>
      </Col>
    );
  }
);

export default PaymentDetails;
