import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";

const DamageEstimateTextInput = ({
  field,
  formValues,
  formErrors,
  handleInputChange,
  handleInputBlur,
}) => {
  return (
    <FormGroup className="form-group">
      <Label for={field?.id}>{field?.label}</Label>
      {field?.isAmountField ? (
        <InputGroup>
          <InputGroupText>$</InputGroupText>
          <Input
            id={field?.id}
            type="text"
            name={field?.name}
            value={formValues[field?.name]}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            disabled={
              (field?.name.includes("creditCard") &&
                formValues["appliedForCreditCard"] !== "Yes") ||
              (field?.name.includes("carLoan") &&
                formValues["appliedForCarLoan"] !== "Yes") ||
              (field?.name.includes("homeLoan") &&
                formValues["appliedForHomeLoan"] !== "Yes") ||
              (field?.name.includes("medicalLoan") &&
                formValues["appliedForMedicalLoan"] !== "Yes")
            }
          />
        </InputGroup>
      ) : (
        <Input
          id={field?.id}
          type="text"
          name={field?.name}
          value={formValues[field?.name]}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={
            (field?.name.includes("creditCard") &&
              formValues["appliedForCreditCard"] !== "Yes") ||
            (field?.name.includes("carLoan") &&
              formValues["appliedForCarLoan"] !== "Yes") ||
            (field?.name.includes("homeLoan") &&
              formValues["appliedForHomeLoan"] !== "Yes") ||
            (field?.name.includes("medicalLoan") &&
              formValues["appliedForMedicalLoan"] !== "Yes")
          }
        />
      )}

      {formErrors[field?.name] ? (
        <p className="form-error">{formErrors[field?.name]}</p>
      ) : null}
    </FormGroup>
  );
};

export default DamageEstimateTextInput;
