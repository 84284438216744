import { useEffect, useRef } from "react";
import useDebounce from "hooks/useDebounce";
import useFilters from "hooks/useFilters";
import { UnlockedLeadsFiltersForm } from ".";
import { Button } from "reactstrap";

const UnlockedLeadsFilters = ({ onApplyFilter, onClearFilter }) => {
  const {
    filters,
    isFilterApplied,
    onChangeFilter,
    onRemoveFilters,
    applyFilters,
  } = useFilters(
    {
      circuit: "",
      states: "",
      caseType: [],
      search: "",
    },
    onApplyFilter,
    onClearFilter
  );
  const isMounted = useRef(false);
  const debouncedSearchValue = useDebounce(filters.search, 500);

  // effect to apply filter whenever search value changes
  useEffect(() => {
    if (isMounted.current && debouncedSearchValue?.trim()?.length) {
      applyFilters({ ...filters, search: debouncedSearchValue?.trim() });
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <div className="filterWrapper">
      <div className="filterIcon">
        <i className="fas fa-filter" />
      </div>

      <UnlockedLeadsFiltersForm
        filters={filters}
        onChangeFilter={onChangeFilter}
      />

      {isFilterApplied(filters) ? (
        <Button
          outline
          color="danger"
          className="btn-eraser"
          onClick={onRemoveFilters}
        >
          <i className="fas fa-eraser fa-lg" />
        </Button>
      ) : null}
    </div>
  );
};

export default UnlockedLeadsFilters;
