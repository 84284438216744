import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { useSelector } from "react-redux";
import {
  getAccountDisputeFields,
  getDataForTradeLineLetter,
} from "helper-methods/generate-letter-helper";
import BureauAccountHistoryDisputes from "components/preview-letter/BureauAccountHistoryDisputes";
import TradeLineAccountHistoryDisputes from "components/preview-letter/TradeLineAccountHistoryDisputes";

const AccountHistoryAccordion = ({ isForTradeLine }) => {
  const { bureau, noResponseLetterTradeLine } = useSelector(
    (state) => state?.reportData || {}
  );
  const disputes = getAccountDisputeFields();

  const tradeLineData = isForTradeLine ? getDataForTradeLineLetter() : null;

  // filter out those tradelines to whom no response letter hasn't been sent
  const filteredDisputes = isForTradeLine
    ? tradeLineData.filter((tradeline) => {
        let isNoResponseLetterSent = false;
        noResponseLetterTradeLine?.forEach((letter) => {
          if (letter?.uniqueBalance === tradeline?.balance)
            isNoResponseLetterSent = true;
        });

        return !isNoResponseLetterSent;
      })
    : disputes.filter((item) => {
        let isNoResponseLetterSent = false;

        noResponseLetterTradeLine?.forEach((letter) => {
          if (
            letter?.tradeLineName === item?.bureau &&
            letter?.bureauName === bureau &&
            letter?.uniqueBalance === item?.balance
          )
            isNoResponseLetterSent = true;
        });

        return !isNoResponseLetterSent;
      });

  const _getAccountDisputesCount = () => {
    let count = 0;

    isForTradeLine
      ? filteredDisputes?.forEach((item) => {
          count += item?.data?.length;
        })
      : filteredDisputes.forEach((dispute) => {
          count += dispute?.disputes?.length;
        });

    return count;
  };

  return (
    <AccordionItem>
      <AccordionHeader targetId="2" tag="div">
        Account History ({_getAccountDisputesCount()}{" "}
        {_getAccountDisputesCount() > 1 ? "Items" : "Item"})
      </AccordionHeader>
      <AccordionBody accordionId="2">
        {isForTradeLine ? (
          <TradeLineAccountHistoryDisputes data={filteredDisputes} />
        ) : (
          <BureauAccountHistoryDisputes
            disputes={filteredDisputes}
            bureau={bureau}
          />
        )}
      </AccordionBody>
    </AccordionItem>
  );
};

export default AccountHistoryAccordion;
