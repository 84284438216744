import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Navigate, useSearchParams } from "react-router-dom";
import { hasSubscription } from "guards/subscription";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchTrainingVideos } from "http/http-calls";
import { errorHandler } from "helper-methods";
import TrainingVideosContainer from "components/training-program/TrainingVideosContainer";

const TrainingProgram = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [params, setSearchParams] = useSearchParams({ tab: 1 });

  const { subscriptionType } = useSelector(
    (state) => state?.userCredential?.user || {}
  );

  useEffect(() => {
    const _fetchTrainingVideos = async () => {
      try {
        setLoading(true);
        const res = await fetchTrainingVideos();
        if (!res?.error) {
          setVideos(res);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };
    _fetchTrainingVideos();
  }, []);

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      <div className="training_tab">
        <Nav pills>
          <NavItem>
            <NavLink
              className={params.get("tab") === "1" ? "active" : ""}
              onClick={() => setSearchParams({ tab: 1 })}
            >
              Basic Training
            </NavLink>
          </NavItem>

          {subscriptionType === "Premium" ? (
            <NavItem>
              <NavLink
                className={params.get("tab") === "2" ? "active" : ""}
                onClick={() => setSearchParams({ tab: 2 })}
              >
                Advance Training
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>

        <TabContent activeTab={params.get("tab")}>
          <TabPane tabId="1">
            {params.get("tab") === "1" ? (
              <TrainingVideosContainer
                loading={loading}
                videos={videos?.basicTraining}
              />
            ) : null}
          </TabPane>

          {subscriptionType === "Premium" ? (
            <TabPane tabId="2">
              {params.get("tab") === "2" ? (
                <TrainingVideosContainer
                  loading={loading}
                  videos={videos?.advanceTraining}
                />
              ) : null}
            </TabPane>
          ) : null}
        </TabContent>
      </div>
    </>
  );
};

export default TrainingProgram;
