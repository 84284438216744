import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ url, className, loading }) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className={className}>
      <Document
        loading={loading || "Loading PDF..."}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} devicePixelRatio={4} />
      </Document>

      {numPages && (
        <div className="pdfControls">
          {pageNumber !== 1 && (
            <button onClick={() => setPageNumber((prev) => prev - 1)}>
              {"<"}
            </button>
          )}

          <p className="pdfViewerPage">
            Page {pageNumber} of {numPages}
          </p>

          {pageNumber !== numPages && (
            <button onClick={() => setPageNumber((prev) => prev + 1)}>
              {">"}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
