import moment from "moment";

export const groupActivityByDate = (activity) => {
  const result = activity?.reduce((acc, item) => {
    // check for today's date
    if (
      moment().format("ll") === moment(new Date(item?.createdAt)).format("ll")
    ) {
      if (!acc["Today"]) acc["Today"] = [];
      acc["Today"].push(item);
    }

    // check for yesterday's date
    if (
      moment().subtract(1, "day").format("ll") ===
      moment(new Date(item?.createdAt)).format("ll")
    ) {
      if (!acc["Yesterday"]) acc["Yesterday"] = [];
      acc["Yesterday"].push(item);
    }

    // check for last 7 day's date
    if (
      moment(new Date(item?.createdAt)).isBetween(
        moment().subtract(9, "days"),
        moment().subtract(2, "days")
      )
    ) {
      if (!acc["Last 7 days"]) acc["Last 7 days"] = [];
      acc["Last 7 days"].push(item);
    }
    return acc;
  }, {});

  return result;
};

export const getActivityIcon = (status) => {
  switch (status) {
    case "Certified Letter Sent":
      return require("../../src/assets/img/high-quality.svg").default;
    case "Response Received":
      return require("../../src/assets/img/new-email.svg").default;
    case "Letter Sent":
      return require("../../src/assets/img/email-sent.svg").default;
    case "Dispute Suggested":
      return require("../../src/assets/img/warning.png");
    case "Credit Reported Dated":
      return require("../../src/assets/img/debit-card.svg").default;
    default:
  }
};

export const getChartData = (historyData) => {
  const series = historyData?.map((item) => ({
    name: item?.bureauName,
    data: item?.data?.map((each) => +each?.score),
  }));

  const categories = historyData?.[0]?.data?.map((each) =>
    moment(new Date(each?.date)).format("MMM")
  );

  return {
    series,
    categories,
  };
};
