import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const UnlockLeadsModal = ({ isOpen, toggle, lead, onUnlock }) => {
  const { credit } = useSelector((state) => state?.userCredential?.user || {});

  const creditsRequired = lead?.credits - credit;

  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>Unlock Lead</ModalHeader>
      <ModalBody>
        <h6 className="text-center">
          You need{" "}
          {creditsRequired === 1
            ? `${creditsRequired} more credit`
            : `${creditsRequired} more credits`}{" "}
          to unlock the profile!
        </h6>

        {/* submit button */}
        <div className="inlineBtnWrapper">
          <Button color="primary" onClick={onUnlock}>
            Unlock Now
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UnlockLeadsModal;
