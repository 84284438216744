import React from "react";
import { Button, ListGroup, ListGroupItem, NavbarBrand } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "helper-methods";
import { useSelector } from "react-redux";
const DashboardSidebar = ({ isShow, setIsShow }) => {
  // hooks
  const { user } = useSelector((state) => state?.userCredential || {});
  const navigate = useNavigate();
  const location = useLocation();
  const isSubscriptionActive =
    !user?._subscription?.[0]?.isExpired && user?._subscription?.[0]?.isAuto;
  const isAttorney = user?.userType === "Lawyer";
  // const menus = ["dashboard", "disputes"];

  const _logout = () => {
    logout(navigate);
  };

  // menu
  const _isActiveTab = (route) => {
    return location?.pathname === route ? true : false;
  };

  const _toggleSidebar = () => {
    setIsShow(!isShow);
  };

  return (
    <>
      {/* add show class after click on bar icon  */}
      <div className={`sidebarWrapper ${isShow ? "show" : ""}`}>
        <div className="sidebarInner pb-4">
          <Button
            onClick={() => _toggleSidebar()}
            className="toggleIcon sideBarClose"
          >
            <span></span>
          </Button>
          <NavbarBrand
            onClick={() => navigate(isAttorney ? "/leads" : "/dashboard")}
            style={{ borderBottom: "1px solid #EBEBEB", marginBottom: "30px" }}
          >
            <img
              src={require("../../assets/img/company-logo.png")}
              alt="Logo"
              width="150px"
            />
          </NavbarBrand>

          <div
            className="d-flex flex-column justify-content-between px-3"
            style={{ flex: "1" }}
          >
            <div className="sidebarMenu" onClick={() => _toggleSidebar()}>
              <ListGroup>
                {/* user & attorney */}
                {user?.userType !== "Lawyer" ? (
                  <>
                    {isSubscriptionActive && (
                      <ListGroupItem
                        className={_isActiveTab("/dashboard") ? "active" : ""}
                        onClick={() => navigate("/dashboard")}
                      >
                        {_isActiveTab("/dashboard") ? (
                          <img
                            src={require("../../assets/img/SidebarMenu/dashboardActive.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/SidebarMenu/dashboard.png")}
                            alt=""
                          />
                        )}

                        <span>Dashboard</span>
                      </ListGroupItem>
                    )}

                    {isSubscriptionActive && (
                      <ListGroupItem
                        className={_isActiveTab("/disputes") ? "active" : ""}
                        onClick={() => navigate("/disputes")}
                      >
                        {_isActiveTab("/disputes") ? (
                          <img
                            src={require("../../assets/img/SidebarMenu/disputesActive.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/SidebarMenu/disputes.png")}
                            alt=""
                          />
                        )}
                        <span>Dispute History</span>
                      </ListGroupItem>
                    )}

                    {isSubscriptionActive && (
                      <ListGroupItem
                        className={
                          _isActiveTab("/sent-letters") ? "active" : ""
                        }
                        onClick={() => navigate("/sent-letters")}
                      >
                        {_isActiveTab("/sent-letters") ? (
                          <img
                            src={require("../../assets/img/SidebarMenu/sentLettersActive.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/SidebarMenu/sentLetters.png")}
                            alt=""
                          />
                        )}
                        <span>Sent Letters</span>
                      </ListGroupItem>
                    )}

                    <ListGroupItem
                      className={_isActiveTab("/payment") ? "active" : ""}
                      onClick={() => navigate("/payment")}
                    >
                      {_isActiveTab("/payment") ? (
                        <img
                          src={require("../../assets/img/SidebarMenu/paymentActive.png")}
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/img/SidebarMenu/payment.png")}
                          alt=""
                        />
                      )}
                      <span>Payment</span>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        _isActiveTab("/manage-subscription") ? "active" : ""
                      }
                      onClick={() => navigate("/manage-subscription")}
                    >
                      {_isActiveTab("/manage-subscription") ? (
                        <img
                          src={require("../../assets/img/SidebarMenu/subscriptionActive.png")}
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/img/SidebarMenu/subscription.png")}
                          alt=""
                        />
                      )}
                      <span>Subscription</span>
                    </ListGroupItem>

                    {user?.userType === "Affiliate" ? (
                      <ListGroupItem
                        className={
                          _isActiveTab("/affiliate-dashboard") ? "active" : ""
                        }
                        onClick={() => navigate("/affiliate-dashboard")}
                      >
                        {_isActiveTab("/affiliate-dashboard") ? (
                          <img
                            src={require("../../assets/img/SidebarMenu/subscriptionActive.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/SidebarMenu/subscription.png")}
                            alt=""
                          />
                        )}

                        <span>Affiliate Dashboard</span>
                      </ListGroupItem>
                    ) : null}
                  </>
                ) : (
                  <>
                    {isSubscriptionActive ? (
                      <ListGroupItem
                        className={_isActiveTab("/leads") ? "active" : ""}
                        onClick={() => navigate("/leads")}
                      >
                        {_isActiveTab("/leads") ? (
                          <img
                            src={require("../../assets/img/SidebarMenu/dashboardActive.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/SidebarMenu/dashboard.png")}
                            alt=""
                          />
                        )}

                        <span>Leads</span>
                      </ListGroupItem>
                    ) : null}

                    {isSubscriptionActive ? (
                      <ListGroupItem
                        className={
                          _isActiveTab("/unlocked-leads") ? "active" : ""
                        }
                        onClick={() => navigate("/unlocked-leads")}
                      >
                        {_isActiveTab("/unlocked-leads") ? (
                          <img
                            src={require("../../assets/img/SidebarMenu/cautionActive.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/SidebarMenu/caution.png")}
                            alt=""
                          />
                        )}

                        <span>Unlocked Leads</span>
                      </ListGroupItem>
                    ) : null}

                    <ListGroupItem
                      className={
                        _isActiveTab("/manage-subscription-attorney")
                          ? "active"
                          : ""
                      }
                      onClick={() => navigate("/manage-subscription-attorney")}
                    >
                      {_isActiveTab("/manage-subscription-attorney") ? (
                        <img
                          src={require("../../assets/img/SidebarMenu/subscriptionActive.png")}
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/img/SidebarMenu/subscription.png")}
                          alt=""
                        />
                      )}

                      <span>Subscription</span>
                    </ListGroupItem>
                  </>
                )}
              </ListGroup>
            </div>

            <div className="sidebarTraining">
              <div>
                <img
                  src={require("../../assets/img/online-video.svg").default}
                  alt=""
                />
                <p>Training Program</p>

                <Button onClick={() => navigate("/training-program")}>
                  {isAttorney ? "Access Now" : "Learn More"}
                </Button>
              </div>
            </div>

            <div className="sidebarMenu">
              <ListGroup>
                <ListGroupItem onClick={() => _logout()}>
                  <img
                    src={require("../../assets/img/logout.svg").default}
                    alt=""
                  />
                  <span>Logout</span>
                </ListGroupItem>
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSidebar;
