import { Col, Row } from "reactstrap";
import {
  LeadDisputeAnalysis,
  LeadDocReference,
  LeadInfo,
} from "components/lead-details";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUnlockedLeadDetails } from "http/http-calls";
import { errorHandler } from "helper-methods";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "redux/actions";
import { CreditItems, History, Reports } from "components/dashboard";

const LeadsDetails = () => {
  const [leadDetails, setLeadDetails] = useState();
  const [data, setData] = useState([]);

  const { id: leadId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({ type: "history" });
  const dispatch = useDispatch();

  const creditItems = {
    transUnion: leadDetails?.transCreditItem,
    experian: leadDetails?.expCreditItem,
    equifax: leadDetails?.equiCreditItem,
  };

  // fetch lead details
  useEffect(() => {
    const _fetchLeadDetails = async (payload) => {
      try {
        dispatch(showLoader());
        const res = await getUnlockedLeadDetails(leadId, payload);
        if (!res?.error) {
          setLeadDetails(res?.lead);
          setData(res?.data);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        dispatch(hideLoader());
      }
    };

    let payload = {
      type: searchParams.get("type"),
    };

    if (searchParams.get("type") === "letter")
      payload.letterType = searchParams.get("letterType") || "bureau";

    _fetchLeadDetails(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, leadId]);

  return (
    <>
      <LeadInfo lead={leadDetails} />

      <Reports reports={leadDetails?.creditScoreHistory} />
      <History data={leadDetails?.graphData?.[0]} />

      <Row>
        <Col md="6">
          <LeadDocReference
            photoId={leadDetails?.photoId}
            addressProof={leadDetails?.addressProof}
          />
        </Col>

        <Col md="6">
          <CreditItems creditItems={creditItems} />
        </Col>
      </Row>

      <LeadDisputeAnalysis
        type={searchParams.get("type")}
        lead={leadDetails}
        data={data}
        setSearchParams={setSearchParams}
      />
    </>
  );
};

export default LeadsDetails;
