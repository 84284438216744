import { camelCaseToWords } from "helper-methods";
import { getBureauLogo } from "helper-methods/generate-letter-helper";

import { Fragment } from "react";
import { Label, List } from "reactstrap";

const BureauAccountHistoryDisputes = ({ disputes, bureau }) => {
  console.log("DISPUTES >>>", disputes);
  return (
    <>
      {disputes?.map((item, index) => (
        <Fragment key={index}>
          <h6 className="mb-2 text-secondary">Trade Line : {item?.bureau}</h6>

          {/* Incorrect Credit Limit */}
          {item?.disputes?.map((dispute, index) => (
            <div key={index} className="mb-4 form-group">
              <span className="disputeName">
                Incorrect {camelCaseToWords(dispute?.disputeName)}
              </span>

              <div className="incorrectNameList">
                <Label>Dispute: </Label>
                <List>
                  <li key={index} className="me-3">
                    <img
                      className="bureauLogo"
                      src={getBureauLogo(bureau)}
                      alt={bureau}
                    />
                    <span className="disputeValue">
                      {dispute?.originalValue}
                    </span>
                  </li>
                </List>
              </div>
            </div>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default BureauAccountHistoryDisputes;
