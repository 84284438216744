import { STATE_CITY_DATA } from "config/states-and-cities";

const StateOptions = () => {
  const states = STATE_CITY_DATA.map((state) => ({
    id: state?.id,
    name: state?.name,
  }));

  return (
    <>
      <option value="">Select</option>
      {states?.map((state) => (
        <option key={state?.id} value={state?.name}>
          {state?.name}
        </option>
      ))}
    </>
  );
};

export default StateOptions;
