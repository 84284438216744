import { useSelector } from "react-redux";
import PreviewSection from "./PreviewSection";
import Summary from "./Summary";
import LettersPreviewWrapper from "components/preview-letter/LettersPreviewWrapper";

const PreviewSectionWrapper = () => {
  const { preview } = useSelector((state) => state?.reportData || {});

  const {
    bureau,
    inquiriesStrArr,
    personalInfoData,
    accountData,
    upperSummary,
    example,
    lowerSumamry: lowerSummary,
  } = preview || {};

  const inquiriesData = inquiriesStrArr?.filter(
    (item) => item?.bureau === bureau
  );

  return (
    <>
      {/* Upper Summary */}
      {upperSummary && example && bureau && (
        <Summary
          heading={`Bureau : ${bureau}`}
          content={`${upperSummary} ${example}`}
        />
      )}

      {/* Preview Of Letters */}
      <LettersPreviewWrapper />

      {/* Personal Information */}
      {personalInfoData?.length ? (
        <PreviewSection
          heading="Personal Information"
          data={personalInfoData}
          isPersonalInfo={true}
          toggleId="personalInfo"
        />
      ) : null}

      {/* Account History */}
      {accountData?.length
        ? accountData?.map((item, index) => (
            <PreviewSection
              key={index}
              toggleId={`accountHistory${index}`}
              heading="Account History"
              data={item?.data}
              subheading={item?.tradeLine}
            />
          ))
        : null}

      {/* Inquiries */}
      {inquiriesData?.length ? (
        <PreviewSection
          toggleId="inquiries"
          heading="Inquiries"
          data={inquiriesData}
        />
      ) : null}

      {/* Conclusion */}
      <Summary heading="Conclusion" content={lowerSummary} />
    </>
  );
};

export default PreviewSectionWrapper;
