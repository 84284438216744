import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import { addUserCredential } from "redux/actions/userCredential";
import useFormValidation from "hooks/useFormValidation";
import { validateLogin } from "lib/form-validation";
import { errorHandler, showToast } from "helper-methods";
import { addDeviceForNotification, login } from "http/http-calls";
import { hasSubscription } from "guards/subscription";

const LoginPage = () => {
  // states
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
  } = useFormValidation(
    {
      email: "",
      password: "",
    },
    validateLogin,
    _login
  );

  const deviceIdData = JSON.parse(localStorage.getItem("users"));

  const { deviceId } = deviceIdData?.[deviceIdData?.length - 1] || {};

  // handle login
  async function _login() {
    try {
      setLoading(true);

      const res = await login({
        handle: formValues["email"]?.trim(),
        password: formValues["password"]?.trim(),
      });

      if (!res?.error) {
        dispatch(addUserCredential({ token: res?.token, user: res?.user }));
        showToast("Logged in successfully", "success", 2000);

        const subscription = res?.user?._subscription?.[0];
        const subscriptionIsActive =
          !subscription?.isExpired && subscription?.isAuto;
        const isAttorney = res?.user?.userType === "Lawyer";

        hasSubscription()
          ? subscriptionIsActive
            ? isAttorney
              ? navigate("/leads")
              : navigate("/dashboard")
            : isAttorney
            ? navigate("/manage-subscription-attorney")
            : navigate("/manage-subscription")
          : isAttorney
          ? navigate("/subscription-attorney")
          : navigate("/subscription");

        // add device id for notification
        if (deviceId) {
          await addDeviceForNotification({ deviceId });
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          <div className="leftWrapper">
            <div className="leftInnerWrapper">
              <h2>Welcome Back!</h2>
              <p className="text-secondary">
                Please enter your email and password
              </p>

              <Form>
                {/* username */}
                <div className="form-group">
                  <Label>Email</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      name="email"
                      value={formValues["email"]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    <InputGroupText>
                      <i className="far fa-envelope" />
                    </InputGroupText>
                  </InputGroup>

                  {formErrors["email"] ? (
                    <p className="form-error">{formErrors["email"]}</p>
                  ) : null}
                </div>

                {/* password */}
                <div className="form-group">
                  <Label>Password</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      type={`${showPassword ? "text" : "password"}`}
                      name="password"
                      value={formValues["password"]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    <InputGroupText
                      className="cursorPointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </InputGroupText>
                  </InputGroup>

                  {formErrors["password"] ? (
                    <p className="form-error">{formErrors["password"]}</p>
                  ) : null}
                </div>

                <div className="text-end">
                  <Link to="/forgot-password" className="forgotPassword">
                    Forgot Password?
                  </Link>
                </div>

                {/* submit button */}
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-submit"
                  disabled={loading}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Spinner />
                      Login
                    </div>
                  ) : (
                    "Login"
                  )}
                </Button>

                {/*infoText*/}
                <div className="text-center fs-14 mt-3 fw-medium">
                  Don't have an account?
                  <Link to="/sign-up" className="ms-1">
                    Sign Up
                  </Link>
                </div>
              </Form>

              <div className="or">
                <span>Or Continue With</span>
              </div>

              <div className="socialLogin">
                <Button className="googleBtn">
                  <img
                    src={require("../../assets/img/google.png")}
                    alt="google"
                  />
                </Button>
                <Button className="appleBtn">
                  <img
                    src={require("../../assets/img/apple.svg").default}
                    alt="apple"
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="rightWrapper">
            {/* logo */}
            <img
              src={require("../../assets/img/logo-white.png")}
              alt="Brand Logo"
              className="companyLogo"
            />
          </div>
        </div>

        {/* footer */}
        <PublicFooter />
      </div>
    </>
  );
};

export default LoginPage;
