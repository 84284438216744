const LeadPopupHeader = ({ leadName, leadCredits, leadAvatar }) => {
  return (
    <>
      <div className="leadCardHeaderImg">
        <img src={leadAvatar} alt="" />
      </div>

      <div>
        <strong>{leadName}</strong>
        <span className="d-block">{leadCredits}</span>
      </div>
    </>
  );
};

export default LeadPopupHeader;
