export const CASE_TYPE_OPTIONS = [
  {
    value: "Inaccurate Zero Balance",
    label: "Inaccurate Zero Balance",
    type: "caseType1",
  },
  {
    value: "1099-C With Balance",
    label: "1099-C With Balance",
    type: "caseType2",
  },
  { value: "Multi Reporting", label: "Multi Reporting", type: "caseType3" },
  { value: "Unknown Tradeline", label: "Unknown Tradeline", type: "caseType4" },
  {
    value: "Shortsale/Foreclosure",
    label: "Shortsale/Foreclosure",
    type: "caseType5",
  },
  { value: "Bankruptcy", label: "Bankruptcy", type: "caseType6" },
  { value: "Old Account", label: "Old Account", type: "caseType7" },
  { value: "Mixed File", label: "Mixed File", type: "caseType8" },
  { value: "Authorized User", label: "Authorized User", type: "caseType9" },
];
