import { useState } from "react";
import { Alert } from "reactstrap";

const AlertCard = ({ theme, onDismiss, content }) => {
  const [isOpen, setIsOpen] = useState(true);

  const _handleDismiss = () => {
    setIsOpen(false);
    onDismiss();
  };

  return (
    <Alert color={theme} isOpen={isOpen} toggle={_handleDismiss}>
      <h6 className="mb-1">Note</h6>
      <p className="m-0" style={{ width: "95%" }}>
        {content}
      </p>
    </Alert>
  );
};

export default AlertCard;
