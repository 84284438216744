import { Card } from "reactstrap";
import { hasSubscription } from "guards/subscription";
import { Navigate } from "react-router-dom";
import {
  UnlockedLeadsFilters,
  UnlockedLeadsTable,
} from "components/unlocked-leads";
import { useEffect, useState } from "react";
import { fetchUnlockedLeads } from "http/http-calls";
import { errorHandler } from "helper-methods";
import { Fallback, PaginatedItems } from "components/common";

const ITEMS_PER_PAGE = 10;

const UnlockedLeads = () => {
  const [loading, setLoading] = useState(false);
  const [unlockedLeads, setUnlockedLeads] = useState([]);
  const [unlockedLeadsCount, setUnlockedLeadsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState();

  // fetch all unlocked leads
  const _fetchUnlockedLeads = async (payload = { pageNumber: 1 }) => {
    try {
      setFilters(payload);
      setLoading(true);
      const res = await fetchUnlockedLeads(payload);
      if (!res?.error) {
        setUnlockedLeads(res?.clients);
        setUnlockedLeadsCount(res?.clientsCount);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    _fetchUnlockedLeads({ ...filters, pageNumber });
  };

  useEffect(() => {
    _fetchUnlockedLeads();
  }, []);

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription-attorney"} />;
  }

  return (
    <>
      {/* filter */}
      <UnlockedLeadsFilters
        onApplyFilter={(filters) => _fetchUnlockedLeads(filters)}
        onClearFilter={() => _fetchUnlockedLeads()}
      />

      {/* table */}
      <Card body>
        {loading || !unlockedLeads?.length ? (
          <Fallback loading={loading} />
        ) : (
          <UnlockedLeadsTable leads={unlockedLeads} />
        )}

        {/* pagination */}
        {!loading &&
        unlockedLeads?.length &&
        unlockedLeadsCount > ITEMS_PER_PAGE ? (
          <PaginatedItems
            currentPage={currentPage}
            itemsPerPage={ITEMS_PER_PAGE}
            items={Array(unlockedLeadsCount)}
            onItemsChange={() => {}}
            onPageChange={_handlePageChange}
          />
        ) : null}
      </Card>
    </>
  );
};

export default UnlockedLeads;
