import moment from "moment";
import { regexConfig } from "config/regex-config";

export const validateSignup = (fieldName, value) => {
  switch (fieldName) {
    case "firstname":
    case "lastname":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 25) {
        return "*Must be 2-25 characters";
      }
      if (
        !regexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.email.test(value?.trim())) {
        return "*Please enter a valid email address";
      }
      return "";
    case "password":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.password.test(value?.trim())) {
        return "*Should have atleast 8 characters with 1 digit and 1 special character";
      }
      return "";
    case "phone":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.phone.test(value?.trim())) {
        return "*Please enter a valid phone number";
      }
      return "";
    case "income":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.income.test(value?.trim())) {
        return "*Decimal values are not allowed";
      }
      return "";
    case "year":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "ssn":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.ssn.test(value?.trim())) {
        return "*Should be exactly 9 digits";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.address.test(value?.trim())) {
        return "*First word must be a number";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 100) {
        return "*Must be 2-100 characters";
      }
      return "";
    case "state":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const validateLogin = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.email.test(value?.trim())) {
        return "*Invalid email";
      }
      return "";
    case "password":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    default:
      return "";
  }
};

export const validateForgotPassword = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.email.test(value?.trim())) {
        return "*Invalid email";
      }
      return "";
    default:
      return "";
  }
};

export const validateResetPassword = (() => {
  let password;

  return (fieldName, value) => {
    if (fieldName === "password" && value?.trim()?.length) {
      password = value?.trim();
    }

    switch (fieldName) {
      case "password":
        if (!value?.trim()?.length) {
          return "*Required";
        }
        if (!regexConfig.password.test(value?.trim())) {
          return "*Should have at least 8 characters with 1 digit and 1 special character";
        }
        return "";
      case "confirmPassword":
        if (!value?.trim()?.length) {
          return "*Required";
        }
        if (value?.trim() !== password) {
          return "*Passwords do not match";
        }
        return "";
      default:
        return "";
    }
  };
})();

export const validatePaymentDetails = (fieldName, value) => {
  switch (fieldName) {
    case "cardCountry":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "fullname":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 20) {
        return "*Must be 2-20 characters";
      }
      if (
        !regexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 100) {
        return "*Must be 2-100 characters";
      }
      return "";
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "state":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const validateProfile = (fieldName, value) => {
  switch (fieldName) {
    case "name":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 25) {
        return "*Must be 2-25 characters";
      }
      if (
        !regexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.email.test(value?.trim())) {
        return "*Please enter a valid email address";
      }
      return "";
    case "phone":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.phone.test(value?.trim())) {
        return "*Please enter a valid phone number";
      }
      return "";
    case "year":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "ssn":
      if (!regexConfig.ssn.test(value?.trim())) {
        return "*Should be exactly 9 digits";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 100) {
        return "*Must be 2-100 characters";
      }
      return "";
    case "companyName":
    case "state":
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const validateCardDetails = (fieldName, value) => {
  switch (fieldName) {
    case "cardNumber":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.stripeCard.test(value?.trim())) {
        return "*Invalid card number";
      }
      return "";
    case "expiry":
      if (!value) {
        return "*Required";
      }
      if (moment(value)?.isBefore(moment())) {
        return "*This card has already expired";
      }
      return "";
    case "cvv":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.cvv.test(value?.trim())) {
        return "*Must be 3 or 4 digits";
      }
      return "";
    default:
      return "";
  }
};

export const validateAccount = (fieldName, value) => {
  switch (fieldName) {
    case "firstname":
    case "lastname":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 25) {
        return "*Must be 2-25 characters";
      }
      if (
        !regexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.email.test(value?.trim())) {
        return "*Please enter a valid email address";
      }
      return "";
    case "phone":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.phone.test(value?.trim())) {
        return "*Please enter a valid phone number";
      }
      return "";
    case "year":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "ssn":
      if (!regexConfig.ssn.test(value?.trim())) {
        return "*Should be exactly 9 digits";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 100) {
        return "*Must be 2-100 characters";
      }
      return "";
    case "state":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!regexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const validateUserInput = (fieldName, value) => {
  switch (fieldName) {
    case "userInput":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    default:
      return "";
  }
};

export const validateGenerateLetter = (fieldName, value) => {
  switch (fieldName) {
    case "name":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 25) {
        return "*Must be 2-25 characters";
      }
      if (
        !regexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 100) {
        return "*Must be 2-100 characters";
      }
      return "";
    case "birthYear":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    default:
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (
        ((fieldName?.includes("accountType") ||
          fieldName?.includes("paymentStatus")) &&
          value?.trim()?.length < 2) ||
        value?.trim()?.length > 25
      ) {
        return "*Must be 2-25 characters";
      }
      if (
        fieldName?.includes("accountStatus") &&
        (value?.trim()?.length < 2 || value?.trim()?.length > 12)
      ) {
        return "*Must be 2-12 characters";
      }
      if (fieldName?.includes("accountNumber") && value?.trim()?.length > 20) {
        return "*Max 20 characters";
      }
      return "";
  }
};

export const validateBuyCredits = (fieldName, value) => {
  switch (fieldName) {
    case "credits":
      if (!value?.trim()?.length) return "*Required";
      if (+value < 1) return "*Must be greater than 0";
      return "";
    default:
      return "";
  }
};

export const validateDamageEstimate = (() => {
  let appliedForCreditCard,
    appliedForCarLoan,
    appliedForHomeLoan,
    appliedForMedicalLoan,
    creditCardApplicationsCount,
    carLoanApplicationsCount,
    homeLoanApplicationsCount,
    medicalLoanApplicationsCount;

  return (fieldName, value) => {
    if (fieldName === "appliedForCreditCard" && value?.trim()?.length) {
      appliedForCreditCard = value?.trim();
    }
    if (fieldName === "appliedForCarLoan" && value?.trim()?.length) {
      appliedForCarLoan = value?.trim();
    }
    if (fieldName === "appliedForHomeLoan" && value?.trim()?.length) {
      appliedForHomeLoan = value?.trim();
    }
    if (fieldName === "appliedForMedicalLoan" && value?.trim()?.length) {
      appliedForMedicalLoan = value?.trim();
    }

    switch (fieldName) {
      case "appliedForCreditCard":
      case "appliedForCarLoan":
      case "appliedForHomeLoan":
      case "appliedForMedicalLoan":
        if (!value?.trim()?.length) {
          return "*Required";
        }
        return "";
      case "creditCardApplicationsCount":
        if (appliedForCreditCard === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForCreditCard === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        } else {
          creditCardApplicationsCount = value?.trim();
          return "";
        }
      case "creditCardAmountAppliedFor":
        if (appliedForCreditCard === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForCreditCard === "Yes" &&
          !regexConfig.withinSevenDigits.test(value?.trim())
        ) {
          return "*Must be between 1-7 digits";
        }
        return "";
      case "creditCardApplicationsDeniedCount":
        if (appliedForCreditCard === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForCreditCard === "Yes" &&
          +value?.trim() > +creditCardApplicationsCount
        ) {
          return "*Denied count can't be greater than applied count";
        }
        if (
          appliedForCreditCard === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        }
        return "";
      case "carLoanApplicationsCount":
        if (appliedForCarLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForCarLoan === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        } else {
          carLoanApplicationsCount = value?.trim();
          return "";
        }
      case "carLoanAmountAppliedFor":
        if (appliedForCarLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForCarLoan === "Yes" &&
          !regexConfig.withinSevenDigits.test(value?.trim())
        ) {
          return "*Must be between 1-7 digits";
        }
        return "";
      case "carLoanApplicationsDeniedCount":
        if (appliedForCarLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForCarLoan === "Yes" &&
          +value?.trim() > +carLoanApplicationsCount
        ) {
          return "*Denied count can't be greater than applied count";
        }
        if (
          appliedForCarLoan === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        }
        return "";
      case "homeLoanApplicationsCount":
        if (appliedForHomeLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForHomeLoan === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        } else {
          homeLoanApplicationsCount = value?.trim();
          return "";
        }
      case "homeLoanAmountAppliedFor":
        if (appliedForHomeLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForHomeLoan === "Yes" &&
          !regexConfig.withinSevenDigits.test(value?.trim())
        ) {
          return "*Must be between 1-7 digits";
        }
        return "";
      case "homeLoanApplicationsDeniedCount":
        if (appliedForHomeLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForHomeLoan === "Yes" &&
          +value?.trim() > +homeLoanApplicationsCount
        ) {
          return "*Denied count can't be greater than applied count";
        }
        if (
          appliedForHomeLoan === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        }
        return "";
      case "medicalLoanType":
        if (appliedForMedicalLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForMedicalLoan === "Yes" &&
          !regexConfig.twoToFiftyChars.test(value?.trim())
        ) {
          return "*Must be between 2-50 chars without digits or special characters";
        }
        return "";
      case "medicalLoanApplicationsCount":
        if (appliedForMedicalLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForMedicalLoan === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        } else {
          medicalLoanApplicationsCount = value?.trim();
          return "";
        }
      case "medicalLoanAmountAppliedFor":
        if (appliedForMedicalLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForMedicalLoan === "Yes" &&
          !regexConfig.withinSevenDigits.test(value?.trim())
        ) {
          return "*Must be between 1-7 digits";
        }
        return "";
      case "medicalLoanApplicationsDeniedCount":
        if (appliedForMedicalLoan === "Yes" && !value?.trim()?.length) {
          return "*Required";
        }
        if (
          appliedForMedicalLoan === "Yes" &&
          +value?.trim() > +medicalLoanApplicationsCount
        ) {
          return "*Denied count can't be greater than applied count";
        }
        if (
          appliedForMedicalLoan === "Yes" &&
          !regexConfig.withinThreeDigits.test(value?.trim())
        ) {
          return "*Must be between 1-3 digits";
        }
        return "";
      default:
        return "";
    }
  };
})();
