import moment from "moment";
import { useDispatch } from "react-redux";
import { setSentLetters } from "redux/actions";

const Dispute = ({ dispute, onViewReport, userType }) => {
  // hooks
  const dispatch = useDispatch();

  const isLetterSentToAll =
    dispute?.isTransUnionSent &&
    dispute?.isExperianSent &&
    dispute?.isEquifaxSent &&
    dispute?.isTradeLineSent;

  const _handleViewReport = () => {
    dispatch(
      setSentLetters({
        isTransUnionSent: dispute?.isTransUnionSent,
        isExperianSent: dispute?.isExperianSent,
        isEquifaxSent: dispute?.isEquifaxSent,
        isTradeLineSent: dispute?.isTradeLineSent,
      })
    );
    onViewReport(dispute?._id);
  };

  return (
    <tr>
      <td>
        Credit Report Dates {moment(new Date(dispute?.createdAt)).format("ll")}
      </td>
      <td>{dispute?.round?.slice(-1)}</td>
      <td>{moment(new Date(dispute?.updatedAt)).format("LLL")}</td>
      <td>
        <i
          className={
            userType !== "Attorney" && isLetterSentToAll
              ? "far fa-eye hoverBtn inactive"
              : "far fa-eye hoverBtn"
          }
          onClick={
            userType !== "Attorney" && isLetterSentToAll
              ? () => {}
              : _handleViewReport
          }
        />
      </td>
    </tr>
  );
};

export default Dispute;
