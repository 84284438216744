import { useEffect, useRef, useState } from "react";
import {
  deleteLetter,
  getAllLetters,
  getUnlockedLeadDetails,
  uploadLetterResponse,
} from "http/http-calls";
import { errorHandler, showToast, uploadFileOnServer } from "helper-methods";
import useFileUpload from "./useFileUpload";

const useSentLetters = (letterType, leadId, userType) => {
  const [sentLettersData, setSentLettersData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [lettersCount, setLetterCount] = useState(0);
  const [tradeLines, setTradeLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState();

  const prevTabRef = useRef();

  const { handleFileUpload, payload } = useFileUpload();

  // fetch all sent letters
  const _fetchSentLetters = async (payload) => {
    try {
      setFilters(payload);
      setLoading(true);

      let attorneyPayload = {
        type: "letter",
        letterType,
        ...payload,
        ...payload?.dateRange,
      };

      delete attorneyPayload?.dateRange;

      const res =
        userType === "Attorney"
          ? await getUnlockedLeadDetails(leadId, attorneyPayload)
          : await getAllLetters({ payload, letterType });

      if (!res?.error) {
        setSentLettersData(res?.letters || res?.data);
        setTradeLines(res?.uniqueTrade || res?.uniqueTradeLines);
        setLetterCount(res?.lettersCount || res?.dataCount);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // handle upload response
  const _onUploadResponse = async (e, letterId) => {
    handleFileUpload({ event: e });
    try {
      const res = await uploadFileOnServer(payload);

      if (!res?.error) {
        const uploadResponse = await uploadLetterResponse({
          letterId: letterId,
          letterLink: res?.[0]?.url,
          fileType: res?.[0]?.contentType,
        });

        if (!uploadResponse?.error) _fetchSentLetters();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // delete a letter
  const _deleteLetter = async (id) => {
    try {
      setLoading(true);

      const res = await deleteLetter(id);

      if (!res?.error) {
        showToast("Letter deleted successfully", "success", 2000);
        _fetchSentLetters();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // download letter
  const _downloadLetter = (location) => {
    window.open(location);
  };

  const _onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    _fetchSentLetters({ ...filters, pageNumber });
  };

  useEffect(() => {
    let newFilters = { ...filters };
    // if tab is changed then clear all filters and set current page to 1
    if (prevTabRef.current !== letterType) {
      newFilters = {};
      setCurrentPage(1);
    }
    prevTabRef.current = letterType;
    _fetchSentLetters({ ...newFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterType]);

  return {
    loading,
    letters: sentLettersData,
    tradeLines,
    lettersCount,
    currentPage,
    onPageChange: _onPageChange,
    fetchLetters: _fetchSentLetters,
    deleteLetter: _deleteLetter,
    onUploadResponse: _onUploadResponse,
    downloadLetter: _downloadLetter,
  };
};

export default useSentLetters;
