import { Button, FormGroup, Input, Label } from "reactstrap";

const PaymentCard = ({
  card,
  onSelectCard,
  onDeleteCard,
  selectedCard,
  isDeletingCard,
}) => {
  return (
    <li key={card?.id}>
      <FormGroup check>
        <Input
          id={`radio${card?.id}`}
          type="radio"
          name="selectCard"
          checked={card?.id === selectedCard?.id}
          onChange={() => onSelectCard(card)}
        />
        <Label for={`radio${card?.id}`}>
          Card ending with {card?.last4} (
          {`${card?.exp_month}/${String(card?.exp_year).slice(-2)}`})
        </Label>
      </FormGroup>

      {!card?.isDefault ? (
        <Button
          color="link"
          className="h-auto p-0"
          onClick={() => onDeleteCard(card?.id)}
          disabled={isDeletingCard}
        >
          <img src={require("../../assets/img/trash.svg").default} alt="" />
        </Button>
      ) : null}
    </li>
  );
};

export default PaymentCard;
