import { CIRCUIT_DATA } from "config/circuit-data";

const CircuitOptions = () => {
  return (
    <>
      <option value="">All</option>
      {CIRCUIT_DATA.map((circuit, index) => (
        <option key={index} value={circuit?.CircuitName}>
          {circuit?.CircuitName}
        </option>
      ))}
    </>
  );
};

export default CircuitOptions;
