import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { countryCodes } from "config/country-codes";

const MyProfileAffiliateModal = ({ isOpen, onDismiss }) => {
  const _closeModal = () => {
    onDismiss();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>My Profile</ModalHeader>

      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Affiliate Name</Label>
              <Input type="text" placeholder="Enter" />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Phone Number</Label>
              <div className="d-flex">
                <Input type="select" className="me-1">
                  {countryCodes.map((countryCode, countryIndex) => (
                    <option key={countryIndex} value={countryCode.code}>
                      {countryCode.name} ({countryCode.dial_code})
                    </option>
                  ))}
                </Input>

                <Input type="text" placeholder="Enter" />
              </div>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Email Address</Label>
              <Input className="mt-1 fw-semibold" readOnly disabled />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Referred By</Label>
              <Input className="mt-1 fw-semibold" readOnly disabled />
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label>Address</Label>
              <Input type="textarea" rows="3" placeholder="Address" />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button color="primary">Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default MyProfileAffiliateModal;
