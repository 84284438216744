import React, { useEffect, useState } from "react";
import { errorHandler } from "helper-methods";
import { fetchAllLeads } from "http/http-calls";
import { hasSubscription } from "guards/subscription";
import { Navigate } from "react-router-dom";
import { LeadCards, LeadFilters } from "components/leads";
import { Fallback, PaginatedItems } from "components/common";

const ITEMS_PER_PAGE = 10;

const Leads = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [leadsCount, setLeadsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState();

  // fetch leads
  const _fetchLeads = async (payload = { pageNumber: 1 }) => {
    try {
      setFilters(payload);
      setCurrentPage(payload.pageNumber || 1);
      setLoading(true);
      const res = await fetchAllLeads(payload);
      if (!res?.error) {
        setLeads(res?.updatedSubscribers);
        setLeadsCount(res?.subscribersCount);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    _fetchLeads({ ...filters, pageNumber });
  };

  useEffect(() => {
    _fetchLeads();
  }, []);

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription-attorney"} />;
  }

  return (
    <>
      <LeadFilters
        onApplyFilter={(filters) => _fetchLeads(filters)}
        onClearFilter={() => _fetchLeads()}
      />

      {loading || !leads?.length ? (
        <Fallback loading={loading} />
      ) : (
        <LeadCards
          leads={leads}
          onUnlockLead={_fetchLeads.bind(this, {
            pageNumber: filters?.pageNumber,
          })}
        />
      )}

      {/* pagination */}
      {!loading && leads?.length && leadsCount > ITEMS_PER_PAGE ? (
        <PaginatedItems
          currentPage={currentPage}
          items={Array(leadsCount)}
          onItemsChange={() => {}}
          onPageChange={_handlePageChange}
          itemsPerPage={ITEMS_PER_PAGE}
        />
      ) : null}
    </>
  );
};

export default Leads;
