import { useState } from "react";
import {
  getUserDetails,
  pauseSubscription,
  pauseSubscriptionAttorney,
  renewSubscription,
  renewSubscriptionAttorney,
  resumeSubscription,
  upgradeSubscription,
  upgradeSubscriptionAttorney,
} from "http/http-calls";
import { errorHandler, showToast } from "helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "redux/actions";

const useSubscription = (isAttorney = false) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { _id: userId, _subscription } = useSelector(
    (state) => state?.userCredential?.user || {}
  );

  const subscription = _subscription?.[0];
  const upgradeTier =
    subscription?.tierLawyer === "Basic"
      ? "Pro"
      : subscription?.tierLawyer === "Pro"
      ? "Elite"
      : null;

  // handle pause subscription
  const _pauseSubscription = async (isAttorney = false) => {
    try {
      setLoading(true);

      const res = isAttorney
        ? await pauseSubscriptionAttorney()
        : await pauseSubscription();

      if (!res?.error) {
        showToast("Subscription paused successfully", "success", 2000);

        // get latest user details
        const userData = await getUserDetails();

        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // handle renew subscription
  const _renewSubscription = async () => {
    try {
      setLoading(true);
      const res = isAttorney
        ? await renewSubscriptionAttorney()
        : await renewSubscription();

      if (!res?.error) {
        showToast("Subscription renewed successfully", "success", 2000);

        // get latest user details
        const userData = await getUserDetails();

        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // handle renew subscription
  const _resumeSubscription = async () => {
    try {
      setLoading(true);
      const res = await resumeSubscription({ id: userId });

      if (!res?.error) {
        showToast("Subscription resumed successfully", "success", 2000);

        // get latest user details
        const userData = await getUserDetails();

        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  // upgrade subscription
  const _upgradeSubscription = async (cb) => {
    try {
      setLoading(true);
      const res = isAttorney
        ? await upgradeSubscriptionAttorney({
            tier: upgradeTier,
            billingPackage: subscription?.billingPackage,
          })
        : await upgradeSubscription();
      if (!res?.error) {
        showToast("Subscription upgraded successfully", "success", 2000);
        cb();

        // get updated user details
        const userData = await getUserDetails();
        if (!userData?.error) dispatch(updateUserData(userData?.user));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    pauseSubscription: _pauseSubscription,
    renewSubscription: _renewSubscription,
    resumeSubscription: _resumeSubscription,
    upgradeSubscription: _upgradeSubscription,
  };
};

export default useSubscription;
