import moment from "moment";
import { useSelector } from "react-redux";
import { List } from "reactstrap";

const GenerateLetterInfo = () => {
  const { report } = useSelector((state) => state?.reportData || {});

  return (
    <div className="infoBox">
      <List>
        <li>
          <strong>Name</strong>
          <p>
            {report?.name?.first} {report?.name?.last}
          </p>
        </li>
        <li>
          <strong>Date</strong>
          <p>{moment(report?.createdAt).format("ll")}</p>
        </li>
        <li>
          <strong>Address</strong>
          <p>{report?.currentMailingAddress?.address}</p>
        </li>
        <li>
          <strong>Year of Birth</strong>
          <p>{report?.birthYear}</p>
        </li>
        <li>
          <strong>Last 4 SSN</strong>
          <p>
            XXXXX
            {report?.ssn?.slice(-4)}
          </p>
        </li>
      </List>
    </div>
  );
};

export default GenerateLetterInfo;
