import { List } from "reactstrap";

const AttorneyCreditDetails = ({ user }) => {
  const totalCredits = user?.totalCredit;
  const balanceCredits = user?.credit;
  const unlockedLeads = user?.partnershipCount ?? 0;

  return (
    <section>
      <div className="infoBox">
        <List>
          <li>
            <strong>Total Credits</strong>
            <p>{totalCredits}</p>
          </li>
          <li>
            <strong>Credit Balance</strong>
            <p>{balanceCredits}</p>
          </li>
          <li>
            <strong>Clients Unlocked</strong>
            <p>{unlockedLeads}</p>
          </li>
        </List>
      </div>
    </section>
  );
};

export default AttorneyCreditDetails;
