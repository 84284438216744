import { CIRCUIT_DATA } from "config/circuit-data";

const CircuitStateOptions = ({ circuitName }) => {
  const circuit = CIRCUIT_DATA.find(
    (circuit) => circuit?.CircuitName === circuitName
  );
  const STATES = circuit?.States;

  return (
    <>
      <option value="">All</option>
      {STATES?.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </>
  );
};

export default CircuitStateOptions;
