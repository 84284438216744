import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { bureaus } from "config/helper-config";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  resetBureauName,
  resetPreviewData,
  setBureauName,
} from "redux/actions";

const DisputeSendLetterModal = ({ isOpen, toggle, bureau }) => {
  // hooks
  const { sentLetters, currentRound } = useSelector(
    (state) => state?.reportData || {}
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filteredBureaus = bureaus?.filter(
    (item) => !sentLetters[`is${item}Sent`]
  );

  if (!sentLetters.isTradeLineSent) filteredBureaus.push("Tradeline");

  // handle create letter redirect
  const _handleCreateLetterRedirect = (bureau) => {
    navigate("/generate-letter", { replace: true });

    dispatch(resetPreviewData());
    dispatch(setBureauName(bureau));
  };

  // handle dashboard redirect
  const _handleDashboardRedirect = () => {
    navigate("/dashboard", { replace: true });

    dispatch(resetPreviewData());
    dispatch(resetBureauName());
  };

  return (
    <Modal isOpen={isOpen} scrollable centered>
      <ModalHeader>
        <img
          src={require("../../assets/img/successCheck.svg").default}
          alt=""
          width="20px"
          className="me-2"
        />
        Great Job!
      </ModalHeader>

      <ModalBody>
        <p className="text-center">
          {bureau === "Tradeline"
            ? `Your Round ${currentRound} dispute letter for Tradelines has been sent.`
            : `Your ${bureau} Round ${currentRound} dispute letter has been sent.`}
        </p>

        <hr />

        <h6 className="text-center">What would you like to do next?</h6>

        <div className="d-flex flex-column align-items-center gap-2">
          {filteredBureaus?.map((bureau, index) => (
            <Button
              key={index}
              color="primary"
              style={{ minWidth: "70%" }}
              onClick={() => _handleCreateLetterRedirect(bureau)}
            >
              Create {bureau} Letter
            </Button>
          ))}

          <Button
            color="primary"
            style={{ minWidth: "70%" }}
            onClick={_handleDashboardRedirect}
          >
            Go To Dashboard
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DisputeSendLetterModal;
