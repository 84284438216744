import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  setPreviewData,
  setReportData,
  showLoader,
} from "redux/actions";
import {
  getAllReports,
  getReport,
  getReportForAttorney,
  getUnlockedLeadDetails,
} from "http/http-calls";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "helper-methods";

const useDisputeHistory = (data, userType = "User", leadId) => {
  const [disputesData, setDisputesData] = useState();
  const [loading, setLoading] = useState(false);

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _handleViewReport = async (reportID) => {
    try {
      dispatch(showLoader());
      const res =
        userType === "Attorney"
          ? await getReportForAttorney(reportID)
          : await getReport(reportID);

      if (!res?.error) {
        dispatch(setReportData(res?.data));
        if (res?.data?._preview) {
          dispatch(setPreviewData(res?.data?._preview));
          navigate("/preview-letter");
        } else {
          navigate("/credit-report");
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // fetch dispute history data
  const _fetchDisputeHistory = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const res =
          userType === "Attorney"
            ? await getUnlockedLeadDetails(leadId, {
                type: "history",
                ...payload?.dateRange,
              })
            : await getAllReports(payload);

        if (!res?.error) {
          setDisputesData(res?.data);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [leadId, userType]
  );

  useEffect(() => {
    if (!data) _fetchDisputeHistory();
    else setDisputesData(data);
  }, [_fetchDisputeHistory, data]);

  return {
    loading,
    disputes: disputesData,
    fetchDisputes: _fetchDisputeHistory,
    handleViewReport: _handleViewReport,
  };
};

export default useDisputeHistory;
