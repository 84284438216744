import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Button, Card } from "reactstrap";
import ShareDashboardReportModal from "components/modals/ShareDashboardReportModal";
import { useState } from "react";

const CreditScoreCard = ({ bureau, score, date }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const _getCreditScoreClassName = (score) => {
    const value = Math.round((score / 850) * 100);

    if (value >= 70) return "success";
    else if (value >= 45) return "warning";
    else return "danger";
  };

  return (
    <>
      <Card body className="shadow">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column" style={{ gap: "10px" }}>
            <span className="text-secondary fs-16">{bureau}</span>
            <strong className="fs-18">300-850</strong>
          </div>

          <CircularProgressbar
            value={Math.round((score / 850) * 100) || "0"}
            text={score}
            className={_getCreditScoreClassName(score)}
            styles={buildStyles({
              strokeLinecap: "round",
            })}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-between mt-4 pt-2"
          style={{ borderTop: "1px solid #f1f1f1" }}
        >
          <small className="text-secondary fs-14">As of {date}</small>
          <Button color="link" onClick={() => setIsOpenModal(true)}>
            <i className="fa fa-share-alt"></i>
          </Button>
        </div>
      </Card>
      <ShareDashboardReportModal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default CreditScoreCard;
