import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  List,
  UncontrolledCollapse,
} from "reactstrap";

const PreviewSection = ({
  heading,
  toggleId,
  subheading,
  data,
  isPersonalInfo,
}) => {
  return (
    <section>
      <Card className="cardToggler">
        <CardHeader id={toggleId}>
          <CardTitle>
            {heading} ({data?.length} Items)
          </CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler={`#${toggleId}`}>
          <CardBody>
            <div className="paraBox">
              {subheading ? <h6>Tradeline : {subheading}</h6> : null}
              <List>
                {data?.map((item, index) => (
                  <li key={index}>
                    {isPersonalInfo && <span>{item?.title}</span>}
                    <p>{item?.string || item?.str || item?.msg || item}</p>
                  </li>
                ))}
              </List>
            </div>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </section>
  );
};

export default PreviewSection;
