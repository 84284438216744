import { Button, Card, Table } from "reactstrap";
import Dispute from "./Dispute";
import { useState } from "react";

const INITIAL_ITEMS_COUNT = 3;

const DisputesTable = ({ disputes, onViewReport, userType }) => {
  const [itemsToShow, setItemsToShow] = useState(INITIAL_ITEMS_COUNT);

  const _handleSeeMore = () =>
    setItemsToShow((prev) =>
      prev === disputes?.length
        ? INITIAL_ITEMS_COUNT
        : prev + INITIAL_ITEMS_COUNT > disputes?.length
        ? disputes?.length
        : prev + INITIAL_ITEMS_COUNT
    );

  return (
    <section>
      <Card body>
        <Table responsive>
          <thead>
            <tr>
              <th>Dispute Reports</th>
              <th>Round</th>
              <th>Modified on</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {disputes?.slice(0, itemsToShow)?.map((dispute) => (
              <Dispute
                key={dispute?._id}
                userType={userType}
                dispute={dispute}
                onViewReport={onViewReport}
              />
            ))}
          </tbody>
        </Table>

        {/* See More */}
        {disputes?.length > INITIAL_ITEMS_COUNT ? (
          <Button color="link" className="h-auto mb-2" onClick={_handleSeeMore}>
            See {itemsToShow === disputes?.length ? "Less" : "More"}{" "}
            <i
              className={
                itemsToShow === disputes?.length
                  ? "fa fa-chevron-up"
                  : "fa fa-chevron-down"
              }
            />
          </Button>
        ) : null}
      </Card>
    </section>
  );
};

export default DisputesTable;
