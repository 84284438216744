import moment from "moment";
import { useState } from "react";

const useFilters = (initialFilters, onApplyFilter, onClearFilter) => {
  const [filters, setFilters] = useState(initialFilters);

  const _isFilterApplied = (filters) => {
    // Check if any property has a non-empty value
    for (const key in filters) {
      if (typeof filters[key] === "object") {
        // If the property is an object (like dateRange), recursively check its properties
        if (_isFilterApplied(filters[key])) {
          return true;
        }
      } else if (
        filters[key] !== null &&
        filters[key] !== undefined &&
        filters[key] !== "" && // Check if it's not null, undefined, or an empty string
        (!Array.isArray(filters[key]) || filters[key].length > 0) // Check if it's not an empty array
      ) {
        return true;
      }
    }
    return false; // No filter applied
  };

  const _onDatesChange = (startDate = null, endDate = null) => {
    const newFilters = { ...filters };
    newFilters["dateRange"] = {
      startDate,
      endDate,
    };
    setFilters(newFilters);
    if (startDate && endDate) _applyFilters(newFilters);
  };

  const _handleChangeFilter = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    // clear filters is no filters are present
    if (!_isFilterApplied(newFilters)) {
      onClearFilter();
      return;
    }

    if (key !== "search") _applyFilters(newFilters);
  };

  const _applyFilters = (filters) => {
    let payload = JSON.parse(JSON.stringify(filters));

    // checking for caseType and dateRange in filters
    const hasDateRange = Object.keys(payload).includes("dateRange");
    const hasCaseType = Object.keys(payload).includes("caseType");

    // delete keys from payload that are empty
    Object.keys(payload).forEach((key) => {
      if (payload[key] === "") delete payload[key];
    });

    // update date range payload
    if (hasDateRange) {
      payload["dateRange"] =
        payload.dateRange.startDate && payload.dateRange.endDate
          ? {
              startDate: moment(payload["dateRange"].startDate).format(
                "DD/MM/YYYY"
              ),
              endDate: moment(payload["dateRange"].endDate).format(
                "DD/MM/YYYY"
              ),
            }
          : undefined;
    }

    // update payload for caseType
    if (hasCaseType) {
      payload["caseType"] = payload["caseType"]?.length
        ? payload["caseType"].map((item) => item?.type)
        : undefined;
    }

    onApplyFilter(payload);
  };

  const _handleClearFilters = () => {
    setFilters(initialFilters);
    onClearFilter();
  };

  return {
    filters,
    isFilterApplied: _isFilterApplied,
    setFilters,
    onDatesChange: _onDatesChange,
    onChangeFilter: _handleChangeFilter,
    onRemoveFilters: _handleClearFilters,
    applyFilters: _applyFilters,
  };
};

export default useFilters;
