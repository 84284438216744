import { useDispatch, useSelector } from "react-redux";
import { errorHandler, showToast, uploadFileOnServer } from "helper-methods";
import { updateProfile } from "http/http-calls";
import useFileUpload from "./useFileUpload";
import { updateUserData } from "redux/actions";
import { useState } from "react";

const useProfile = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [uploadPayload, setUploadPayload] = useState([]);

  const { user } = useSelector((state) => state?.userCredential || {});
  const { handleFileUpload, payload } = useFileUpload();
  const dispatch = useDispatch();

  const photoIdData = uploadPayload?.find((item) => item?.field === "photoId");
  const addressProofData = uploadPayload?.find(
    (item) => item?.field === "addressProof"
  );

  // handle profile pic upload
  const _onUploadProfilePic = async (e) => {
    handleFileUpload({ event: e, field: "profilePic" });
    try {
      const res = await uploadFileOnServer(payload);

      if (!res?.error) {
        const data = await updateProfile({
          id: user?._id,
          payload: {
            profilePic: res?.[0]?.url,
          },
        });

        if (!data?.error) {
          dispatch(updateUserData(data?.user));
          showToast("Profile picture updated successfully", "success", 2000);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // handle photo id upload
  const _onUploadPhotoId = async (e) => {
    handleFileUpload({ event: e, field: "photoId" });
    setUploadPayload((prev) => [...prev, payload?.[0]]);
  };

  // handle address proof upload
  const _onUploadAddressProof = async (e) => {
    handleFileUpload({ event: e, field: "addressProof" });
    setUploadPayload((prev) => [...prev, payload?.[0]]);
  };

  // check if any field has changed
  const _fieldHasChanged = (formValues, userType) => {
    let hasChanged = false;
    if (userType === "User") {
      if (
        formValues["name"]?.trim() !== user?.name?.full ||
        formValues["phone"]?.trim() !== user?.phone ||
        formValues["year"] !== user?.birthYear ||
        formValues["ssn"]?.trim() !== user?.ssn ||
        formValues["address"]?.trim() !==
          user?.currentMailingAddress?.streetLine2 +
            " " +
            user?.currentMailingAddress?.streetLine1 ||
        formValues["city"] !== user?.currentMailingAddress?.city ||
        formValues["state"] !== user?.currentMailingAddress?.state ||
        formValues["zip"]?.trim() !== user?.currentMailingAddress?.zip ||
        photoIdData ||
        addressProofData
      )
        hasChanged = true;
    } else {
      if (
        formValues["name"]?.trim() !== user?.name?.full ||
        formValues["phone"]?.trim() !== user?.phone ||
        formValues["companyName"]?.trim() !== user?.companyName
      ) {
        hasChanged = true;
      }
    }
    return hasChanged;
  };

  // handle update profile
  async function _onUpdateProfile(formValues, userType = "User") {
    try {
      if (!_fieldHasChanged(formValues, userType)) {
        return;
      }

      setLoading(true);

      let uploadRes;
      if (uploadPayload?.length) {
        uploadRes = await uploadFileOnServer(uploadPayload);
      }

      const photoId = uploadRes?.find(
        (item) => item?.forKeyName === "photoId"
      )?.url;
      const addressProof = uploadRes?.find(
        (item) => item?.forKeyName === "addressProof"
      )?.url;

      const streetLine2 = formValues["address"]?.trim()?.split(" ")?.[0];
      const streetLine1 = formValues["address"]
        ?.trim()
        ?.split(" ")
        ?.slice(1)
        ?.join(" ");

      const payload =
        userType === "Attorney"
          ? {
              name: formValues["name"]?.trim(),
              phone: formValues["phone"]?.trim(),
              companyName: formValues["companyName"]?.trim(),
            }
          : {
              name: formValues["name"]?.trim(),
              streetLine2,
              streetLine1,
              phone: formValues["phone"]?.trim(),
              birthYear: formValues["year"]?.trim(),
              city: formValues["city"],
              state: formValues["state"],
              zip: formValues["zip"]?.trim(),
              ssn: formValues["ssn"]?.trim(),
              photoId,
              addressProof,
            };

      const res = await updateProfile({ id: user?._id, payload });

      if (!res?.error) {
        dispatch(updateUserData(res?.user));
        showToast("profile updated successfully", "success", 2000);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
      setUploadPayload([]);
    }
  }
  return {
    loading,
    photoIdData,
    addressProofData,
    onUploadProfilePic: _onUploadProfilePic,
    onUploadPhotoId: _onUploadPhotoId,
    onUploadAddressProof: _onUploadAddressProof,
    onUpdateProfile: _onUpdateProfile,
  };
};

export default useProfile;
