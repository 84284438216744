import AccountHistoryAccordion from "components/accordion/AccountHistoryAccordion";
import InquiriesAccordion from "components/accordion/InquiriesAccordion";
import PersonalInfoAccordion from "components/accordion/PersonalInfoAccordion";
import React from "react";
import { UncontrolledAccordion } from "reactstrap";

const GenerateLetterAccordion = ({
  isForTradeLine,
  isNoResponseSentToBureau,
  report,
  values,
  onChange,
  onBlur,
  errors,
  setFormValues,
  setFormErrors,
}) => {
  return (
    <section>
      <UncontrolledAccordion
        defaultOpen={!isForTradeLine && !isNoResponseSentToBureau ? "1" : "2"}
      >
        {!isForTradeLine &&
          !isNoResponseSentToBureau &&
          report?.profileDisputecount > 0 && (
            <PersonalInfoAccordion
              values={values}
              onChange={onChange}
              onBlur={onBlur}
              errors={errors}
              setFormValues={setFormValues}
              setFormErrors={setFormErrors}
            />
          )}

        {report?.accountHistoryDisputeCount > 0 && (
          <AccountHistoryAccordion isForTradeLine={isForTradeLine} />
        )}

        {!isForTradeLine &&
          !isNoResponseSentToBureau &&
          report?.inquiriesDisputeCount > 0 && <InquiriesAccordion />}
      </UncontrolledAccordion>
    </section>
  );
};

export default GenerateLetterAccordion;
