import { PopoverBody, UncontrolledPopover } from "reactstrap";

const LeadInfoListItem = ({ id, showTooltip, title, value, icon }) => {
  return (
    <li>
      <div className="textBox">
        <span>{title}</span>
        {showTooltip ? (
          <>
            <strong id={id} role="button">
              {value}
            </strong>

            <UncontrolledPopover
              placement="bottom"
              target={id}
              trigger="legacy"
            >
              <PopoverBody>{value}</PopoverBody>
            </UncontrolledPopover>
          </>
        ) : (
          <strong>{value}</strong>
        )}
      </div>
      <div className="roundImgWrapper">
        <img src={icon} alt="" />
      </div>
    </li>
  );
};

export default LeadInfoListItem;
