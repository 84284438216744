import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { useEffect, useState } from "react";

const LoaderModal = ({ isOpen, toggle, title, texts, allowClose = true }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <Modal isOpen={isOpen} {...(allowClose && { toggle })} scrollable centered>
      <ModalHeader {...(allowClose && { toggle })}>{title}</ModalHeader>

      <ModalBody>
        <Spinner
          color="primary"
          size={"sm"}
          style={{
            height: "1.25rem",
            width: "1.25rem",
          }}
          className=" d-flex mx-auto mb-3"
        />
        <p className="text-center">{texts[currentIndex]}</p>
      </ModalBody>
    </Modal>
  );
};

export default LoaderModal;
