import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import PhotoId from "./PhotoId";
import AddressProof from "./AddressProof";

const UserDocs = ({
  addressProofData,
  photoIdData,
  onUploadAddressProof,
  onUploadPhotoId,
}) => {
  return (
    <Card className="mt-2">
      <CardHeader>
        <CardTitle>My Docs</CardTitle>
      </CardHeader>

      <CardBody>
        <PhotoId photoIdData={photoIdData} onUploadPhotoId={onUploadPhotoId} />

        <AddressProof
          addressProofData={addressProofData}
          onUploadAddressProof={onUploadAddressProof}
        />
      </CardBody>
    </Card>
  );
};

export default UserDocs;
