import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import {
  MONTHLY_ADD_ON_CREDIT_PRICE,
  YEARLY_ADD_ON_CREDIT_PRICE,
} from "config/helper-config";
import useFormValidation from "hooks/useFormValidation";
import { validateBuyCredits } from "lib/form-validation";
import { errorHandler, showToast } from "helper-methods";
import { buyCredits, getUserDetails } from "http/http-calls";
import { updateUserData } from "redux/actions";

const AdditionalCreditsModal = ({ isOpen, toggle }) => {
  // states
  const [loading, setLoading] = useState(false);

  // hooks
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
  } = useFormValidation(
    {
      credits: "",
    },
    validateBuyCredits,
    _buyCredits
  );
  const { _subscription } = useSelector(
    (state) => state?.userCredential?.user || {}
  );
  const dispatch = useDispatch();

  const subscription = _subscription?.[0];
  const planType = subscription?.billingPackage?.toLowerCase();
  const pricePerCredit =
    planType === "monthly"
      ? MONTHLY_ADD_ON_CREDIT_PRICE
      : YEARLY_ADD_ON_CREDIT_PRICE;
  const purchaseAmount = formValues["credits"] * pricePerCredit;

  const _closeModal = () => {
    toggle();
  };

  async function _buyCredits() {
    try {
      setLoading(true);
      const res = await buyCredits({
        noOfCredits: +formValues["credits"],
        amount: purchaseAmount,
      });

      if (!res?.error) {
        showToast("Credits purchased successfully", "success", 2000);
        _closeModal();

        // get updated user details
        const userData = await getUserDetails();
        if (!userData?.error) dispatch(updateUserData(userData?.user));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>Additional Credit</ModalHeader>
      <ModalBody>
        {false ? (
          <h6 className="text-center text-capitalize">
            You need more credits to unlock this profile.
          </h6>
        ) : (
          <>
            {/* Add on Credits */}
            <div className="form-group">
              <Label>Add on Credits</Label>
              <Input
                placeholder="Enter"
                min={1}
                type="number"
                className="inputNumber"
                name="credits"
                value={formValues["credits"]}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["credits"]?.length ? (
                <p className="form-error">{formErrors["credits"]}</p>
              ) : null}
            </div>

            {/* Purchase Amount */}
            <div className="form-group">
              <Label>Purchase Amount</Label>
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  placeholder="Enter"
                  type="text"
                  value={purchaseAmount || ""}
                  readOnly
                />
              </InputGroup>
            </div>
          </>
        )}

        {/* submit button */}
        <div className="inlineBtnWrapper">
          {false ? (
            <Button color="primary">Buy Credits Now</Button>
          ) : (
            <Button disabled={loading} color="primary" onClick={handleSubmit}>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <Spinner />
                  Buy Now
                </div>
              ) : (
                "Buy Now"
              )}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AdditionalCreditsModal;
