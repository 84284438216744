import { Table } from "reactstrap";
import { UnlockedLead } from ".";

const UnlockedLeadsTable = ({ leads }) => {
  return (
    <Table responsive className="unlockedLeadsTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Credits</th>
          <th>Case Types</th>
          <th>Circuit</th>
          <th>State</th>
          <th>Damage Estimates</th>
          <th>Total Defendants</th>
        </tr>
      </thead>

      <tbody>
        {leads?.map((lead) => (
          <UnlockedLead key={lead?._id} lead={lead} />
        ))}
      </tbody>
    </Table>
  );
};

export default UnlockedLeadsTable;
