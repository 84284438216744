import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { Fragment } from "react";
import { getInquiryDisputeFields } from "helper-methods/generate-letter-helper";

const InquiriesAccordion = () => {
  const inquiryDisputes = getInquiryDisputeFields();

  return (
    <AccordionItem>
      <AccordionHeader targetId="4" tag="div">
        Inquiries ({inquiryDisputes?.length}{" "}
        {inquiryDisputes?.length > 1 ? "Items" : "Item"})
      </AccordionHeader>

      <AccordionBody accordionId="4">
        {inquiryDisputes?.length
          ? inquiryDisputes.map((item, index) => (
              <Fragment key={index}>
                <div className="form-group mb-4">
                  <span>{item?.str}</span>
                </div>
              </Fragment>
            ))
          : null}
      </AccordionBody>
    </AccordionItem>
  );
};

export default InquiriesAccordion;
