import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

const VerifyMobileNumberModal = ({ isOpen, toggle, phone }) => {
  const [verifyMobile, setVerifyMobile] = useState(true);
  const [otp, setOtp] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",
  });

  const _closeModal = () => {
    toggle();
    setOtp({
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
      digit6: "",
    });
  };

  const _handleKeyDown = (e, index) => {
    // if backspace is pressed and it's not the first field and the field is empty then move to previous field
    if (e.keyCode === 8 && index !== 1 && e.target.value === "") {
      const prevInput = document.getElementById(`otpInput-${index - 1}`);
      prevInput?.focus();
    }
  };

  const _handleMoveToNextField = (index) => {
    // ignore if its last index
    if (index === 6) return;

    const nextInput = document.getElementById(`otpInput-${index + 1}`);
    nextInput?.focus();
  };

  const _handleChange = (e, index) => {
    const value = e.target.value?.trim();

    // update the otp state
    const newOtp = { ...otp };
    newOtp[`digit${index}`] = value;
    setOtp(newOtp);

    // move to next otp field if current field is updated
    if (value) _handleMoveToNextField(index);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>
        Verify Your Phone Number
      </ModalHeader>
      <ModalBody>
        {verifyMobile ? (
          <>
            {/* Generate OTP */}
            <div>
              <h6>
                You need to verify your Phone Number in order to generate your
                credit Report.
              </h6>

              {/* Mobile number */}
              <div className="form-group">
                <Label>Mobile Number</Label>
                <InputGroup>
                  <InputGroupText className="cursorPointer">+1</InputGroupText>
                  <Input
                    placeholder="Enter"
                    type="text"
                    name="phone"
                    value={phone}
                    readOnly
                  />
                </InputGroup>
              </div>

              <p className="fs-14 text-secondary">
                <i className="fa fa-lock me-1"></i> Your data is secured and
                encrypted
              </p>

              {/* submit button */}
              <div className="inlineBtnWrapper">
                <Button color="primary" onClick={() => setVerifyMobile(false)}>
                  Generate OTP
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Verify OTP */}
            <div>
              <h6>Please enter the 6-digit OTP sent to your Phone Number</h6>

              {/* Enter OTP */}
              <div className="form-group">
                <Label>Enter OTP</Label>
                <div className="d-flex gap-2">
                  {Array(6)
                    .fill("")
                    .map((_, index) => (
                      <Input
                        key={index}
                        id={`otpInput-${index + 1}`}
                        type="text"
                        className="text-center"
                        maxLength={1}
                        value={otp[`digit${index + 1}`]}
                        onChange={(e) => _handleChange(e, index + 1)}
                        onKeyDown={(e) => _handleKeyDown(e, index + 1)}
                        style={{ width: "40px" }}
                        {...(index === 0 && { autoFocus: true })}
                      />
                    ))}
                </div>
              </div>

              <p className="fs-14 text-secondary">
                <i className="fa fa-lock me-1"></i> Your data is secured and
                encrypted
              </p>

              {/* submit button */}
              <div className="inlineBtnWrapper">
                <Button color="primary">Submit</Button>
              </div>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default VerifyMobileNumberModal;
