import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import defaultProfile from "assets/img/default-profile.svg";
import useFormValidation from "hooks/useFormValidation";
import { useSelector } from "react-redux";
import { validateProfile } from "lib/form-validation";
import { useEffect } from "react";
import useProfile from "hooks/useProfile";
import { AttorneyBasicInfo, AttorneyCreditDetails } from "components/attorney";

const MyProfileModal = ({ isOpen, toggle }) => {
  // hooks
  const { user } = useSelector((state) => state?.userCredential || {});
  const { loading, onUpdateProfile, onUploadProfilePic } = useProfile();
  const {
    formErrors,
    formValues,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    setFormValues,
  } = useFormValidation(
    {
      name: "",
      email: "",
      phone: "",
      companyName: "",
    },
    validateProfile,
    () => onUpdateProfile(formValues, "Attorney")
  );

  // set initial form values
  useEffect(() => {
    if (user) {
      setFormValues({
        name: user?.name?.full || "",
        email: user?.email || "",
        phone: user?.phone || "",
        companyName: user?.companyName || "",
      });
    }
  }, [setFormValues, user]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable centered>
      <ModalHeader toggle={toggle}>My Profile</ModalHeader>

      <ModalBody>
        {/* profile pic */}
        <section className="d-flex justify-content-center profileCard">
          <div className="position-relative">
            <div className="cardImg bg-light">
              <img src={user?.profilePic || defaultProfile} alt="" />
            </div>

            {/* pencil */}
            <Label className="editProfileCard">
              <Input
                type="file"
                className="d-none"
                accept="image/*"
                onChange={onUploadProfilePic}
              />
              <i className="fa fa-pencil-alt" />
            </Label>
          </div>
        </section>

        {/* basic info */}
        <AttorneyBasicInfo
          formValues={formValues}
          formErrors={formErrors}
          handleInputBlur={handleInputBlur}
          handleInputChange={handleInputChange}
        />
        <AttorneyCreditDetails user={user} />
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <Button
          color="primary"
          outline
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Spinner />
              Save
            </div>
          ) : (
            "Save"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MyProfileModal;
