import { createSlice } from "@reduxjs/toolkit";
import {
  resetBureauName,
  resetPreviewData,
  resetReportData,
  setBureauName,
  setCurrentRound,
  setNextRoundDate,
  setNoResponseBureauData,
  setNoResponseTradeLineData,
  setPreviewData,
  setReportData,
  setSentLetters,
} from "redux/actions";

const initialState = {
  report: null,
  nextRoundDate: null,
  currentRound: 1,
  noResponseLetterBureau: [],
  noResponseLetterTradeLine: [],
  sentLetters: {
    isTransUnionGenerated: false,
    isExperianGenerated: false,
    isEquifaxGenerated: false,
    isTradeLineGenerated: false,
    isTransUnionSent: false,
    isExperianSent: false,
    isEquifaxSent: false,
    isTradeLineSent: false,
  },
  bureau: null,
  preview: null,
};

const reportDataSlice = createSlice({
  name: "reportData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setReportData, (state, action) => {
        state.report = action.payload;
      })

      .addCase(setNextRoundDate, (state, action) => {
        state.nextRoundDate = action.payload;
      })

      .addCase(setCurrentRound, (state, action) => {
        state.currentRound = action.payload;
      })

      .addCase(setNoResponseBureauData, (state, action) => {
        state.noResponseLetterBureau = action.payload;
      })

      .addCase(setNoResponseTradeLineData, (state, action) => {
        state.noResponseLetterTradeLine = action.payload;
      })

      .addCase(setSentLetters, (state, action) => {
        state.sentLetters = { ...state.sentLetters, ...action.payload };
      })

      .addCase(setBureauName, (state, action) => {
        state.bureau = action.payload;
      })

      .addCase(setPreviewData, (state, action) => {
        state.preview = action.payload;
      })

      .addCase(resetBureauName, (state) => {
        state.bureau = null;
      })

      .addCase(resetPreviewData, (state) => {
        state.preview = null;
      })

      .addCase(resetReportData, () => {
        return initialState;
      });
  },
});

export const reportDataReducer = reportDataSlice.reducer;
