import { Button, Col, Row } from "reactstrap";
import AffiliateReferralLink from "components/affiliateDetails/AffiliateReferralLink";
import AffiliateReferredClients from "components/affiliateDetails/AffiliateReferredClients";
import AffiliateReferredPartners from "components/affiliateDetails/AffiliateReferredPartners";
import { useState } from "react";
import AffiliateUserInfo from "components/affiliateDetails/AffiliateUserInfo";

const AffiliateDashboard = () => {
  const [affiliate, setAffiliate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Row>
        <Col md={12} xl={6}>
          <AffiliateUserInfo />

          <AffiliateReferredClients />
        </Col>

        <Col md={12} xl={6}>
          <AffiliateReferralLink affiliate={affiliate} isLoading={isLoading} />

          <AffiliateReferredPartners />
        </Col>
      </Row>
    </>
  );
};

export default AffiliateDashboard;
