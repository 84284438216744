import {
  USER_TYPES,
  lawyerSubscriptionPlans,
  userSubscriptionPlans,
} from "config/helper-config";
import { store } from "redux/store";

export const getUpgradePlanAmount = (subscription, userType) => {
  const isMonthlySubscription = subscription?.billingCycle === 30;

  // for lawyer monthly subscription
  if (userType === USER_TYPES?.lawyer && isMonthlySubscription) {
    if (subscription?.tierLawyer === "Basic") {
      return lawyerSubscriptionPlans?.Pro?.monthly;
    } else if (subscription?.tierLawyer === "Pro") {
      return lawyerSubscriptionPlans?.Elite?.monthly;
    }
  }

  // for lawyer yearly subscription
  if (userType === USER_TYPES?.lawyer && !isMonthlySubscription) {
    if (subscription?.tierLawyer === "Basic") {
      return lawyerSubscriptionPlans?.Pro?.yearly;
    } else if (subscription?.tierLawyer === "Pro") {
      return lawyerSubscriptionPlans?.Elite?.yearly;
    }
  }

  // for user monthly subscription
  if (userType === USER_TYPES?.user && isMonthlySubscription) {
    if (subscription?.tier === "Basic") {
      return userSubscriptionPlans?.Premium?.monthly;
    }
  }

  // for user yearly subscription
  if (userType === USER_TYPES?.user && !isMonthlySubscription) {
    if (subscription?.tier === "Basic") {
      return userSubscriptionPlans?.Premium?.yearly;
    }
  }
};

export const getCurrentSubscriptionAmount = () => {
  const state = store.getState();
  const isAttorney = state?.userCredential?.user?.userType === "Lawyer";
  const subscription = state?.userCredential?.user?._subscription?.[0];

  const currentSubscriptionAmount = isAttorney
    ? lawyerSubscriptionPlans[subscription?.tierLawyer][
        subscription?.billingPackage?.toLowerCase()
      ]
    : userSubscriptionPlans[subscription?.tier][
        subscription?.billingCycle === 30 ? "monthly" : "yearly"
      ];

  return currentSubscriptionAmount;
};
