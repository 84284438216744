import { useSelector } from "react-redux";
import { Input, Label } from "reactstrap";

const PhotoId = ({ photoIdData, onUploadPhotoId }) => {
  const { user } = useSelector((state) => state?.userCredential || {});

  return (
    <div className="form-group">
      <Label>Photo</Label>
      {user?.photoId ? (
        <div className="docReferenceBox mb-3 mt-2">
          <div className="mx-auto">
            <img
              src={require("../../assets/img/icon1-primary.svg").default}
              alt=""
            />
            <a
              href={user?.photoId}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-primary"
            >
              Identity Proof
            </a>
          </div>
        </div>
      ) : null}

      <div className="customFileUpload">
        <Input
          type="file"
          id="photoIdUpload"
          accept="image/*"
          onChange={onUploadPhotoId}
        />

        {!photoIdData ? (
          <Label for="photoIdUpload" className="p-3">
            <img
              src={require("../../assets/img/uploadDoc.svg").default}
              alt=""
            />
            <div className="customUploadText">
              <h6>Upload Document</h6>
              <span>File size must be less than 5mb</span>
            </div>
          </Label>
        ) : (
          <Label for="photoIdUpload" className="uploaded p-3">
            <img
              src={require("../../assets/img/sign-up-doc.svg").default}
              alt=""
            />
            <div className="customUploadText">
              <h6>{photoIdData?.uploadData?.name}</h6>
              <span>File size must be less than 5mb</span>
            </div>
          </Label>
        )}
      </div>
    </div>
  );
};

export default PhotoId;
