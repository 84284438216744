import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const LeadDocReference = ({ photoId, addressProof }) => {
  return (
    <section>
      <Card>
        <CardHeader>
          <CardTitle>Document Referance</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="docReferenceBox">
            <div>
              <img
                src={require("../../assets/img/icon1-primary.svg").default}
                alt=""
              />
              <a
                href={photoId}
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-primary"
              >
                Identity Proof
              </a>
            </div>

            <div>
              <img
                src={require("../../assets/img/icon1-primary.svg").default}
                alt=""
              />
              <a
                href={addressProof}
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-primary"
              >
                Address Proof
              </a>
            </div>
          </div>
        </CardBody>
      </Card>
    </section>
  );
};

export default LeadDocReference;
