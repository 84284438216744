import { useState } from "react";
import { Button, Spinner } from "reactstrap";
import usePaymentMethod from "hooks/usePaymentMethod";
import AllCards from "./AllCards";
import { showConfirmModal } from "helper-methods";
import AddNewCardModal from "components/modals/AddNewCardModal";

const PaymentMethods = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    loading,
    cards,
    isDeletingCard,
    deleteCard,
    getCards,
    makeDefaultCard,
  } = usePaymentMethod();

  // handle modal state
  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  // handle delete card
  const _handleDeleteCard = (id) => {
    showConfirmModal({
      title: "Delete Card",
      text: "Are you sure you want to delete this card?",
      icon: "warning",
      onConfirm: () => deleteCard(id),
    });
  };

  return (
    <>
      <section className="payment-methods">
        <div className="innerHeader">
          <h2>Your Cards</h2>
          <div>
            <Button color="primary" onClick={() => _toggleModal(true)}>
              Add Card
            </Button>
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <div className="main_content_wrapper">
            <AllCards
              cards={cards}
              makeDefault={makeDefaultCard}
              onDelete={_handleDeleteCard}
              isDeletingCard={isDeletingCard}
            />
          </div>
        )}
      </section>

      {isOpenModal ? (
        <AddNewCardModal
          isOpen={isOpenModal}
          toggle={() => _toggleModal()}
          onAddCard={getCards}
        />
      ) : null}
    </>
  );
};

export default PaymentMethods;
