import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Navigate, useSearchParams } from "react-router-dom";
import { PaymentHistory, PaymentMethods } from "components/payment";
import { hasSubscription } from "guards/subscription";

const Payment = () => {
  const [params, setSearchParams] = useSearchParams({ tab: 1 });

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      <div className="disputes_tab">
        <Nav pills>
          <NavItem>
            <NavLink
              className={params.get("tab") === "1" ? "active" : ""}
              onClick={() => setSearchParams({ tab: 1 })}
            >
              Payment History
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={params.get("tab") === "2" ? "active" : ""}
              onClick={() => setSearchParams({ tab: 2 })}
            >
              Payment Method
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={params.get("tab")}>
          <TabPane tabId="1">
            {params.get("tab") === "1" ? <PaymentHistory /> : null}
          </TabPane>

          <TabPane tabId="2">
            {params.get("tab") === "2" ? <PaymentMethods /> : null}
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Payment;
