import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import TradeLineCard from "./TradeLineCard";
import CreditReportUserInputModal from "components/modals/CreditReportUserInputModal";
import { getAccountHistoryDisputes } from "helper-methods/generate-letter-helper";

const AccountHistory = () => {
  // states
  const [isOpenCreditModal, setIsOpenCreditModal] = useState(false);
  const [tradeLine, setTradeLine] = useState("");
  const [issue, setIssue] = useState("");
  const [issueData, setIssueData] = useState([]);

  // hooks
  const { accountHistory } = useSelector(
    (state) => state?.reportData?.report || {}
  );
  const accountDisputes = useMemo(() => {
    return getAccountHistoryDisputes(accountHistory);
  }, [accountHistory]);

  const _toggleCreditModal = (isOpenCreditModal = false) => {
    setIsOpenCreditModal(isOpenCreditModal);
  };

  // handle open modal
  const _handleOpenModal = (tradeLineName, issueName, data) => {
    _toggleCreditModal(true);
    setTradeLine(tradeLineName);
    setIssue(issueName);
    setIssueData(data);
  };

  return (
    <>
      <section>
        <h6>Account History</h6>

        {accountDisputes?.length
          ? accountDisputes?.map((tradeline, index) => (
              <TradeLineCard
                key={index}
                tradeLine={tradeline}
                onOpenModal={_handleOpenModal}
              />
            ))
          : null}
      </section>

      {isOpenCreditModal && (
        <CreditReportUserInputModal
          isOpen={isOpenCreditModal}
          toggle={() => _toggleCreditModal()}
          userInput="Account"
          issueName={issue}
          tradeLine={tradeLine}
          issueData={issueData}
          disputes={accountDisputes}
        />
      )}
    </>
  );
};

export default AccountHistory;
