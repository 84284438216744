import MyProfileAffiliateModal from "components/modals/Affiliate/MyProfileAffiliateModal";
import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, List } from "reactstrap";
// import SkeletonLoader from "SkeletonLoader";

const AffiliateUserInfo = ({ affiliate, isLoading }) => {
  const [myProfileModal, setMyProfileModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleMyProfileModal = (isOpen = false, data = null) => {
    setMyProfileModal({ isOpen, data });
  };
  return (
    <>
      <Card className="commonCard">
        <CardHeader>
          Basic Info
          <div className="action">
            <Button
              color="link"
              className="edit me-2"
              onClick={() => _toggleMyProfileModal(true)}
            >
              <i className="fas fa-pencil-alt"></i>
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <List className="userInfoList">
            <li>
              <div className="userIcon">
                <img src={require("../../assets/img/user.png")} alt="" />
              </div>
              <span>Jerry Tom</span>
            </li>

            <li>
              <div className="userIcon">
                <img src={require("../../assets/img/mail.png")} alt="" />
              </div>
              <span>pratik+tom@logic-square.com</span>
            </li>

            <li>
              <div className="userIcon">
                <img src={require("../../assets/img/telephone.png")} alt="" />
              </div>
              <span>+1-92872772</span>
            </li>

            <li>
              <div className="userIcon">
                <img
                  src={require("../../assets/img/maps-and-flags.png")}
                  alt=""
                />
              </div>
              <span>Delhi</span>
            </li>
          </List>
        </CardBody>
      </Card>

      <MyProfileAffiliateModal
        isOpen={myProfileModal?.isOpen}
        // affiliate={myProfileModal?.data}
        onDismiss={() => _toggleMyProfileModal()}
        onSuccess={() => {}}
      />
    </>
  );
};

export default AffiliateUserInfo;
