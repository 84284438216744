import { Col, Form, Input, Label, Row } from "reactstrap";
import { CityOptions, StateOptions } from "components/form-options";

const BillingAddressForm = ({ formValues, formErrors, onChange, onBlur }) => {
  return (
    <Form>
      {/* Full Name */}
      <div className="form-group">
        <Label>Full Name</Label>
        <Input
          placeholder="Enter"
          name="fullname"
          value={formValues["fullname"]}
          onChange={onChange}
          onBlur={onBlur}
        />

        {formErrors["fullname"] ? (
          <p className="form-error">{formErrors["fullname"]}</p>
        ) : null}
      </div>

      {/* Billing Address */}
      <div className="form-group">
        <Label>Billing Address</Label>
        <Input
          placeholder="Enter"
          name="address"
          value={formValues["address"]}
          onChange={onChange}
          onBlur={onBlur}
        />

        {formErrors["address"] ? (
          <p className="form-error">{formErrors["address"]}</p>
        ) : null}
      </div>

      <Row>
        <Col md="4">
          {/* State */}
          <div className="form-group">
            <Label>State</Label>
            <Input
              type="select"
              name="state"
              value={formValues["state"]}
              onChange={onChange}
              onBlur={onBlur}
            >
              <StateOptions />
            </Input>

            {formErrors["state"] ? (
              <p className="form-error">{formErrors["state"]}</p>
            ) : null}
          </div>
        </Col>

        <Col md="4">
          {/* City */}
          <div className="form-group">
            <Label>City</Label>
            <Input
              type="select"
              name="city"
              onChange={onChange}
              onBlur={onBlur}
              value={formValues["city"]}
            >
              <CityOptions state={formValues["state"]} />
            </Input>

            {formErrors["city"] ? (
              <p className="form-error">{formErrors["city"]}</p>
            ) : null}
          </div>
        </Col>

        <Col md="4">
          {/* Zip */}
          <div className="form-group">
            <Label>Zip</Label>
            <Input
              placeholder="Enter"
              name="zip"
              value={formValues["zip"]}
              onChange={onChange}
              onBlur={onBlur}
            />

            {formErrors["zip"] ? (
              <p className="form-error">{formErrors["zip"]}</p>
            ) : null}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default BillingAddressForm;
