import { isAfterRound2 } from "helper-methods";
import { useSelector } from "react-redux";
import { FormGroup, Input, Label, List } from "reactstrap";

const NameDisputeField = ({
  values,
  errors,
  onChange,
  onBlur,
  name,
  onChangeSuggestion,
}) => {
  // hooks
  const { name: userName } = useSelector(
    (state) => state?.reportData?.report || {}
  );

  return (
    <>
      <div className="form-group mb-0">
        <Label for="checkbox1">
          Incorrect Name{" "}
          <span className="disputeValue">
            {isAfterRound2() ? "" : `(${name?.dataValue})`}
          </span>
        </Label>

        {!isAfterRound2() ? (
          <Input
            type="text"
            placeholder="Enter your correct name"
            className="mt-1"
            name="name"
            value={values["name"]}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : null}

        {/* Suggested  Incorrect name */}
        <div className="incorrectNameList">
          <Label>{isAfterRound2() ? "Dispute: " : "Suggestions: "}</Label>
          <List>
            <li>
              {isAfterRound2() ? (
                <span>{name?.dataValue}</span>
              ) : (
                <FormGroup check inline>
                  <Input
                    id="incorrectName1"
                    type="radio"
                    name="incorrectName"
                    value={`${userName?.first} ${userName?.last}`}
                    onChange={(e) => onChangeSuggestion("name", e.target.value)}
                    checked={
                      values["name"] === `${userName?.first} ${userName?.last}`
                    }
                  />
                  <Label for="incorrectName1">
                    {userName?.first} {userName?.last}
                  </Label>
                </FormGroup>
              )}
            </li>
          </List>
        </div>

        {errors["name"] ? <p className="form-error">{errors["name"]}</p> : null}
      </div>
      <hr />
    </>
  );
};

export default NameDisputeField;
