import { store } from "redux/store";
import {
  getAccountDisputeFields,
  getDataForTradeLineLetter,
  getInquiryDisputeFields,
  getPersonalInfoDisputes,
} from "./generate-letter-helper";
import { isAfterRound2 } from "helper-methods";

export const checkForPersonalInfoDisputes = (personalInfo) => {
  const {
    transUnion: personalInfoTransUnion,
    equifax: personalInfoEquifax,
    experian: personalInfoExperian,
  } = getPersonalInfoDisputes(personalInfo);

  const nameHasDispute =
    personalInfoTransUnion?.nameHasDispute ||
    personalInfoExperian?.nameHasDispute ||
    personalInfoEquifax?.nameHasDispute;

  const yobHasDispute =
    personalInfoTransUnion?.yobHasDispute ||
    personalInfoExperian?.yobHasDispute ||
    personalInfoEquifax?.yobHasDispute;

  const addressHasDispute =
    personalInfoTransUnion?.addressHasDispute ||
    personalInfoExperian?.addressHasDispute ||
    personalInfoEquifax?.addressHasDispute;

  return {
    nameHasDispute,
    yobHasDispute,
    addressHasDispute,
  };
};

const getPersonalInfoPayload = (formValues) => {
  const result = [];
  const state = store.getState();
  const personalInfo = state?.reportData?.report?.personalInfo;
  const bureau = state?.reportData?.bureau;
  const { nameHasDispute, addressHasDispute, yobHasDispute } =
    checkForPersonalInfoDisputes(personalInfo);

  const [name, address, year] = personalInfo.find(
    (item) => item?.bureau === bureau
  )?.data;

  if (nameHasDispute) {
    result.push({
      field: "name",
      value: name?.dataValue,
      userValue: isAfterRound2() ? name?.dataValue : formValues["name"],
    });
  }

  if (addressHasDispute) {
    result.push({
      field: "address",
      value: address?.dataValue,
      userValue: isAfterRound2() ? address?.dataValue : formValues["address"],
    });
  }

  if (yobHasDispute) {
    result.push({
      field: "birthYear",
      value: year?.dataValue,
      userValue: isAfterRound2() ? year?.dataValue : formValues["birthYear"],
    });
  }

  return result;
};

const getAccountHistoryPayload = () => {
  const result = [];

  const disputes = getAccountDisputeFields();

  disputes.forEach((item) => {
    let value = {
      tradeLine: item?.bureau,
      balance: item?.balance,
      err: [],
    };

    item.disputes.forEach((dispute) => {
      value.err.push({
        field: dispute?.disputeName,
        value: dispute?.originalValue,
        userValue: dispute?.originalValue,
      });
    });

    result.push(value);
  });

  return result;
};

const getInquiriesPayload = () => {
  const result = [];
  const inquiryDisputes = getInquiryDisputeFields();

  inquiryDisputes.forEach((item) => {
    result.push(item);
  });

  return result;
};

export const createGenerateLetterPayload = (formValues) => {
  const state = store.getState();
  const bureau = state?.reportData?.bureau;

  const payload = {
    bureau,
  };

  // For personal info
  const personalErrArr = getPersonalInfoPayload(formValues);
  payload.personalErrArr = personalErrArr;

  // For account history
  const tradelineErrArr = getAccountHistoryPayload();
  payload.tradelineErrArr = tradelineErrArr;

  // For inquiries
  const inquiriesErrArr = getInquiriesPayload();
  payload.inquiriesErrArr = inquiriesErrArr;

  return payload;
};

export const createPayloadForTradeline = () => {
  const state = store.getState();
  const { noResponseLetterTradeLine } = state?.reportData || {};
  const data = getDataForTradeLineLetter();

  const payload = {
    isOnlyBureau: false,
    tradelineErrArr: [],
  };

  data.forEach((item) => {
    const value = {};
    const fields = item?.data;
    const isNoResponseSent = noResponseLetterTradeLine?.find(
      (each) => each?.tradeLineName === item?.bureau
    );

    if (fields.length && !isNoResponseSent) {
      value["tradeLine"] = item?.bureau;
      value["balance"] = item?.balance;
      value["data"] = fields;
      payload.tradelineErrArr.push(value);
    }
  });

  return payload;
};

export const getTradelineNamesFromPreview = () => {
  const state = store.getState();
  const { preview } = state?.reportData || {};
  const tradelines = preview?.accountData?.map((item) => item?.tradeLine);

  const tradelineNames = new Intl.ListFormat("en", {
    style: "long",
    type: "conjunction",
  }).format(tradelines);

  return tradelineNames;
};
