import React, { useState } from "react";
import { Input, Label, FormGroup, Button } from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import { subscriptionFeatures } from "config/helper-config";
import SubscriptionCard from "components/subscription/SubscriptionCard";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";

const SubscriptionPage = () => {
  // states
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [isYearlySelected, setIsYearlySelected] = useState(false);

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap select-subscription flex-column pb-5">
          <div className="authWrapper-banner" style={{ minHeight: "400px" }}>
            <h2 className="mt-3">Select Your Subscription</h2>

            {/* custom switch */}
            <FormGroup switch className="customSwitch">
              <Input
                type="switch"
                id="customSwitch"
                checked={isYearlySelected}
                onChange={(e) => setIsYearlySelected(e.target.checked)}
                onClick={() => setIsYearlySelected((prev) => !prev)}
              />

              <Label for="customSwitch">
                <span>Monthly</span>
                <span>Yearly</span>
                <span className="pos-abs"></span>
              </Label>
            </FormGroup>

            {isYearlySelected ? (
              <h6 className="mt-3 text-white fs-20">Save 15% with yearly</h6>
            ) : null}
          </div>

          {/* cards */}
          <section className="subscriptionPlanCards ">
            <SubscriptionCard
              title="Basic"
              description="Get started with a Basic Subscription Plan"
              planAmount={!isYearlySelected ? 39 : 399}
              planDuration={!isYearlySelected ? "monthly" : "yearly"}
              planType="Basic"
              planTypeText={!isYearlySelected ? "per month" : "per year"}
              featuresList={subscriptionFeatures?.user?.Basic}
            />

            <SubscriptionCard
              title="Pro"
              tag="Most popular"
              description="Unlock advanced features and take your experience to the next level with a Pro Plan."
              planAmount={!isYearlySelected ? 79 : 799}
              planDuration={!isYearlySelected ? "monthly" : "yearly"}
              planType="Premium"
              planTypeText={!isYearlySelected ? "per month" : "per year"}
              featuresList={subscriptionFeatures?.user?.Premium}
            />
          </section>
        </div>

        {/* info video */}
        <div className="wavesBtnWrapper posFixed">
          <div className="waves wave-1" />
          <div className="waves wave-2" />
          <div className="waves wave-3" />
          <Button
            color="link"
            className="infoVideoBtn"
            onClick={() => setInfoVideoModal(true)}
          >
            <img src={require("../../assets/img/play-button.png")} alt="" />
          </Button>
        </div>

        {infoVideoModal ? (
          <InfoVideoAlertModal
            isOpen={infoVideoModal}
            toggle={() => setInfoVideoModal(false)}
            title="Choose your plan"
            content="Why choose Pro plan over Basic plan?"
          />
        ) : null}

        {/* footer */}
        <PublicFooter />
      </div>
    </>
  );
};

export default SubscriptionPage;
