import { FormGroup, Input, Label } from "reactstrap";

const DamageEstimateRadioInput = ({
  field,
  fieldIndex,
  formValues,
  formErrors,
  handleInputChange,
}) => {
  return (
    <FormGroup className="form-group">
      <Label>{field?.label}</Label>
      <div className="d-flex align-items-center mt-3 mb-2">
        {field?.buttons?.map((button, index) => (
          <FormGroup check inline key={index}>
            <Input
              id={`q${fieldIndex}radio${index}`}
              value={button}
              type="radio"
              name={field?.name}
              checked={formValues[field?.name] === button}
              onChange={handleInputChange}
            />
            <Label for={`q${fieldIndex}radio${index}`}>{button}</Label>
          </FormGroup>
        ))}
      </div>
      {formErrors[field?.name] ? (
        <p className="form-error">{formErrors[field?.name]}</p>
      ) : null}
    </FormGroup>
  );
};

export default DamageEstimateRadioInput;
