import { CardTitle, Col } from "reactstrap";
import { subscriptionFeatures } from "config/helper-config";
import { getCurrentSubscriptionAmount } from "helper-methods/subscription-helper";
import { FeatureList } from "components/common";

const SubscriptionDetails = ({ subscription, isAttorney = false }) => {
  const subscriptionAmount = getCurrentSubscriptionAmount();
  const featureList = isAttorney
    ? subscriptionFeatures?.attorney?.[subscription?.tierLawyer]
    : subscriptionFeatures?.user?.[subscription?.tier];
  const subscriptionTier = isAttorney
    ? subscription?.tierLawyer
    : subscription?.tier;
  const billingCycle = subscription?.billingCycle === 30 ? "month" : "year";

  return (
    <Col md="12" xl="6" className="px-1">
      <CardTitle>{subscriptionTier}</CardTitle>
      <span>Get started with a {subscriptionTier} Subscription Plan</span>

      <div className="planPrice">
        <span className="price">${subscriptionAmount}</span>
        <span>per {billingCycle}</span>
      </div>

      <div className="features-list inline-list">
        <FeatureList list={featureList} />
      </div>
    </Col>
  );
};

export default SubscriptionDetails;
