import { CustomDateRangePicker } from "components/common";
import { Input, Label } from "reactstrap";

const PaymentHistoryFiltersForm = ({
  filters,
  onDatesChange,
  onChangeFilter,
}) => {
  return (
    <div className="filterForm">
      <div className="formGroup">
        <Label>Transaction Date</Label>
        <CustomDateRangePicker
          startDate={filters.dateRange.startDate}
          endDate={filters.dateRange.endDate}
          startDateId={"startDate_kpi_dashboard"}
          endDateId={`endDate_kpi_dashboard`}
          onDatesChange={(startDate, endDate) =>
            onDatesChange(startDate, endDate)
          }
        />
      </div>

      <div className="formGroup">
        <Label>Transaction Type</Label>
        <Input
          type="select"
          value={filters["transactionType"]}
          onChange={(e) => onChangeFilter("transactionType", e.target.value)}
        >
          <option value="">All</option>
          <option value="Basic">Basic Subscription</option>
          <option value="Premimum">Premium Subscription</option>
        </Input>
      </div>
    </div>
  );
};

export default PaymentHistoryFiltersForm;
