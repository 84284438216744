import Lottie from "lottie-react";
import { Spinner } from "reactstrap";
import noData from "assets/lottie/noData.json";

const Fallback = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div className="noDataImgWrap">
          <Lottie animationData={noData} loop={true} className="noDataImg" />
          <span>No data found</span>
        </div>
      )}
    </>
  );
};

export default Fallback;
