import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

const ShareDashboardReportModal = ({ isOpen, toggle, onAddCard }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>
        <img
          src={require("../../assets/img/successCheck.svg").default}
          alt=""
          width="20px"
          className="me-2"
        />
        Congratulations!
      </ModalHeader>

      <ModalBody>
        <p className="text-center">
          Your credit score has increased for the month of May.
        </p>

        <h6 className="mt-3 text-center">
          You can share your scores on the following platforms
        </h6>

        <div className="socialIconList">
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="option"
                value="facebook"
                onClick={handleOptionChange}
                checked={selectedOption === "facebook"}
              />
              <div
                className={`socialIconBox ${
                  selectedOption === "facebook" && "active"
                }`}
              >
                <img src={require("../../assets/img/facebook.png")} alt="" />
              </div>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="option"
                value="youtube"
                onClick={handleOptionChange}
                checked={selectedOption === "youtube"}
              />
              <div
                className={`socialIconBox ${
                  selectedOption === "youtube" && "active"
                }`}
              >
                <img src={require("../../assets/img/youtube.png")} alt="" />
              </div>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="option"
                value="instagram"
                onClick={handleOptionChange}
                checked={selectedOption === "instagram"}
              />
              <div
                className={`socialIconBox ${
                  selectedOption === "instagram" && "active"
                }`}
              >
                <img src={require("../../assets/img/instagram.png")} alt="" />
              </div>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="option"
                value="twitter"
                onClick={handleOptionChange}
                checked={selectedOption === "twitter"}
              />
              <div
                className={`socialIconBox ${
                  selectedOption === "twitter" && "active"
                }`}
              >
                <img src={require("../../assets/img/twitter.png")} alt="" />
              </div>
            </Label>
          </FormGroup>
        </div>

        {/* submit button */}
        <div className="inlineBtnWrapper">
          <Button color="primary">Share</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareDashboardReportModal;
