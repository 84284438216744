import { List } from "reactstrap";
import SingleCard from "./SingleCard";

const AllCards = ({ cards, makeDefault, onDelete, isDeletingCard }) => {
  return (
    <List>
      {cards?.length
        ? cards?.map((card) => (
            <SingleCard
              key={card?.id}
              card={card}
              makeDefault={makeDefault}
              onDelete={onDelete}
              isDeletingCard={isDeletingCard}
            />
          ))
        : null}
    </List>
  );
};

export default AllCards;
