import { useSelector } from "react-redux";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { validateGenerateLetter } from "lib/form-validation";
import { checkForPersonalInfoDisputes } from "helper-methods/preview-letter-helper";
import {
  AddressDisputeField,
  NameDisputeField,
  YearDisputeField,
} from "components/generate-letter/personal-info";

const PersonalInfoAccordion = ({
  values,
  onChange,
  onBlur,
  errors,
  setFormValues,
  setFormErrors,
}) => {
  // hooks
  const { personalInfo } = useSelector(
    (state) => state?.reportData?.report || {}
  );
  const { bureau } = useSelector((state) => state?.reportData || {});

  const { nameHasDispute, addressHasDispute, yobHasDispute } =
    checkForPersonalInfoDisputes(personalInfo);

  const [name, address, year] = personalInfo.find(
    (item) => item?.bureau === bureau
  )?.data;

  const _getPersonalDisputeCount = () => {
    let count = 0;
    const disputes = [nameHasDispute, yobHasDispute, addressHasDispute];
    for (let dispute of disputes) {
      if (dispute) count++;
    }

    return count;
  };

  const _checkIsValueSameAsDispute = (inputName, value) => {
    const newErrors = { ...errors };

    switch (inputName) {
      case "name":
        if (value?.trim() === name?.dataValue)
          newErrors["name"] = "*Cannot be same as dispute value";
        else delete newErrors["name"];
        setFormErrors(newErrors);
        return;

      case "address":
        if (value?.trim() === address?.dataValue)
          newErrors["address"] = "*Cannot be same as dispute value";
        else delete newErrors["address"];
        setFormErrors(newErrors);
        return;

      case "birthYear":
        if (value?.trim() === year?.dataValue)
          newErrors["birthYear"] = "*Cannot be same as dispute value";
        else delete newErrors["birthYear"];
        setFormErrors(newErrors);
        return;

      default:
        return;
    }
  };

  const _handleChangeInput = (e) => {
    onChange(e);
    const { name: inputName, value } = e.target;

    // check if value is same as dispute value
    _checkIsValueSameAsDispute(inputName, value);
  };

  const _handleBlurInput = (e) => {
    onBlur(e);

    const { name: inputName, value } = e.target;

    // check if value is same as dispute value
    _checkIsValueSameAsDispute(inputName, value);
  };

  const _handleChangeSuggestion = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));

    // validate the field and remove error if no error message found
    const errorMessage = validateGenerateLetter(field, value);
    if (!errorMessage?.length) {
      const newFormErrors = { ...errors };
      delete newFormErrors[field];
      setFormErrors(newFormErrors);
    }
  };

  return (
    <AccordionItem>
      <AccordionHeader targetId="1" tag="div">
        Personal Information ({_getPersonalDisputeCount()}{" "}
        {_getPersonalDisputeCount() > 1 ? "Items" : "Item"})
      </AccordionHeader>

      <AccordionBody accordionId="1">
        {nameHasDispute && (
          <NameDisputeField
            name={name}
            errors={errors}
            onChange={_handleChangeInput}
            onBlur={_handleBlurInput}
            values={values}
            onChangeSuggestion={_handleChangeSuggestion}
          />
        )}

        {/* Incorrect address */}
        {addressHasDispute && (
          <AddressDisputeField
            address={address}
            errors={errors}
            onChange={_handleChangeInput}
            onBlur={_handleBlurInput}
            values={values}
            onChangeSuggestion={_handleChangeSuggestion}
          />
        )}

        {/* Incorrect year of birth */}
        {yobHasDispute && (
          <YearDisputeField
            year={year}
            errors={errors}
            onChange={_handleChangeInput}
            onBlur={_handleBlurInput}
            values={values}
            onChangeSuggestion={_handleChangeSuggestion}
          />
        )}
      </AccordionBody>
    </AccordionItem>
  );
};

export default PersonalInfoAccordion;
