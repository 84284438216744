import { damageEstimateFormConfig } from "config/forms/damage-estimate";
import { errorHandler } from "helper-methods";
import useFormValidation from "hooks/useFormValidation";
import { sendQuestionnaireData } from "http/http-calls";
import { validateDamageEstimate } from "lib/form-validation";
import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "reactstrap";
import { DamageEstimateRadioInput, DamageEstimateTextInput } from ".";

const DamageEstimateForm = ({ onSubmit, caseTypePayload }) => {
  const [loading, setLoading] = useState(false);
  const {
    formValues,
    formErrors,
    setFormErrors,
    setFormValues,
    handleInputChange,
    handleInputBlur,
    handleSubmit,
  } = useFormValidation(
    {
      appliedForCreditCard: "",
      creditCardApplicationsCount: "",
      creditCardAmountAppliedFor: "",
      creditCardApplicationsDeniedCount: "",
      appliedForCarLoan: "",
      carLoanApplicationsCount: "",
      carLoanAmountAppliedFor: "",
      carLoanApplicationsDeniedCount: "",
      appliedForHomeLoan: "",
      homeLoanApplicationsCount: "",
      homeLoanAmountAppliedFor: "",
      homeLoanApplicationsDeniedCount: "",
      appliedForMedicalLoan: "",
      medicalLoanType: "",
      medicalLoanApplicationsCount: "",
      medicalLoanAmountAppliedFor: "",
      medicalLoanApplicationsDeniedCount: "",
    },
    validateDamageEstimate,
    _submitHandler
  );

  async function _submitHandler() {
    try {
      setLoading(true);
      let payload = {
        caseQuestion: caseTypePayload,
      };

      Object.keys(formValues).forEach((key) => {
        if (formValues[key]) payload[key] = formValues[key];
      });

      const res = await sendQuestionnaireData(payload);
      if (!res?.error) onSubmit();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  // effect to remove errors and clear value if a field gets disabled
  useEffect(() => {
    if (
      formValues.appliedForCreditCard &&
      formValues.appliedForCreditCard !== "Yes"
    ) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.creditCardApplicationsCount;
        delete newErrors.creditCardAmountAppliedFor;
        delete newErrors.creditCardApplicationsDeniedCount;

        return newErrors;
      });
      setFormValues((prevValues) => {
        const newValues = { ...prevValues };
        newValues.creditCardApplicationsCount = "";
        newValues.creditCardAmountAppliedFor = "";
        newValues.creditCardApplicationsDeniedCount = "";

        return newValues;
      });
    }

    if (
      formValues.appliedForCarLoan &&
      formValues.appliedForCarLoan !== "Yes"
    ) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.carLoanApplicationsCount;
        delete newErrors.carLoanAmountAppliedFor;
        delete newErrors.carLoanApplicationsDeniedCount;

        return newErrors;
      });
      setFormValues((prevValues) => {
        const newValues = { ...prevValues };
        newValues.carLoanApplicationsCount = "";
        newValues.carLoanAmountAppliedFor = "";
        newValues.carLoanApplicationsDeniedCount = "";

        return newValues;
      });
    }

    if (
      formValues.appliedForHomeLoan &&
      formValues.appliedForHomeLoan !== "Yes"
    ) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.homeLoanApplicationsCount;
        delete newErrors.homeLoanAmountAppliedFor;
        delete newErrors.homeLoanApplicationsDeniedCount;

        return newErrors;
      });
      setFormValues((prevValues) => {
        const newValues = { ...prevValues };
        newValues.homeLoanApplicationsCount = "";
        newValues.homeLoanAmountAppliedFor = "";
        newValues.homeLoanApplicationsDeniedCount = "";

        return newValues;
      });
    }

    if (
      formValues.appliedForMedicalLoan &&
      formValues.appliedForMedicalLoan !== "Yes"
    ) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.medicalLoanType;
        delete newErrors.medicalLoanApplicationsCount;
        delete newErrors.medicalLoanAmountAppliedFor;
        delete newErrors.medicalLoanApplicationsDeniedCount;

        return newErrors;
      });
      setFormValues((prevValues) => {
        const newValues = { ...prevValues };
        newValues.medicalLoanType = "";
        newValues.medicalLoanApplicationsCount = "";
        newValues.medicalLoanAmountAppliedFor = "";
        newValues.medicalLoanApplicationsDeniedCount = "";

        return newValues;
      });
    }
  }, [
    formValues.appliedForCreditCard,
    formValues.appliedForCarLoan,
    formValues.appliedForHomeLoan,
    formValues.appliedForMedicalLoan,
    setFormErrors,
    setFormValues,
  ]);

  return (
    <Form className="d-flex flex-column gap-3">
      {damageEstimateFormConfig?.fields?.map((field, fieldIndex) =>
        field?.type === "radio" ? (
          <DamageEstimateRadioInput
            key={fieldIndex}
            field={field}
            fieldIndex={fieldIndex}
            formValues={formValues}
            formErrors={formErrors}
            handleInputChange={handleInputChange}
          />
        ) : (
          <DamageEstimateTextInput
            key={fieldIndex}
            field={field}
            formValues={formValues}
            formErrors={formErrors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
          />
        )
      )}

      <Button
        className="w-50 mx-auto"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Spinner />
            Submit
          </div>
        ) : (
          "Submit"
        )}
      </Button>
    </Form>
  );
};

export default DamageEstimateForm;
