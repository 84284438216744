import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Label,
  InputGroup,
  Input,
  FormGroup,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import useFormValidation from "hooks/useFormValidation";
import { useDispatch, useSelector } from "react-redux";
import {
  CityOptions,
  StateOptions,
  YearOptions,
} from "components/form-options";
import { useEffect } from "react";
import { validateAccount } from "lib/form-validation";
import { decodeToken, errorHandler } from "helper-methods";
import {
  getLatestReportData,
  getUserDetails,
  verifyAccountDetails,
} from "http/http-calls";
import LoaderModal from "components/modals/LoaderModal";
import {
  setCurrentRound,
  setNoResponseBureauData,
  setNoResponseTradeLineData,
  setReportData,
  setSentLetters,
  updateUserData,
  updateUserToken,
} from "redux/actions";
import { analyzingModalTexts } from "config/helper-config";
import { hasSubscription } from "guards/subscription";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";

const AccountVerification = () => {
  // states
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [showFullSSN, setShowFullSSN] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    setFormValues,
  } = useFormValidation(
    {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      year: "",
      ssn: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    },
    validateAccount,
    _onSubmit
  );
  const [checkboxes, setCheckboxes] = useState({
    firstnameCheck: true,
    lastnameCheck: true,
    emailCheck: true,
    phoneCheck: true,
    yearCheck: true,
    ssnCheck: true,
    addressCheck: true,
    cityCheck: true,
    stateCheck: true,
    zipCheck: true,
    confirmCheck: false,
  });

  // hooks
  const { user } = useSelector((state) => state?.userCredential || {});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  // constants
  const allCheckboxesChecked = Object.keys(checkboxes)?.every(
    (key) => checkboxes[key]
  );

  // handle change in checkbox state
  const _handleChangeCheckbox = (e) => {
    const { name, checked } = e?.target;

    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // handle submision logic
  async function _onSubmit() {
    _toggleModal(true);
    try {
      setLoading(true);
      setIsOpenModal(true);

      const payload = {
        pdfLink: localStorage.getItem("pdfLink"),
        firstName: formValues["firstname"],
        lastName: formValues["lastname"],
        email: formValues["email"],
        phoneNo: formValues["phone"],
        yearOfBirth: formValues["year"],
        ssn: formValues["ssn"],
        streetLine2: formValues["address"]?.split(" ")?.[0],
        streetLine1: formValues["address"]?.split(" ")?.slice(1)?.join(" "),
        city: formValues["city"],
        state: formValues["state"],
        zip: formValues["zip"],
      };

      const res = await verifyAccountDetails(payload);

      if (!res?.error) {
        // update no response letter status for bureau and tradeline
        dispatch(setNoResponseBureauData(res?.noresponseLetterBureau));
        dispatch(setNoResponseTradeLineData(res?.noresponseLetterTradeLine));

        const data = decodeToken(res?.token);

        // get latest report data
        const reportData = await getLatestReportData(data?._reportId);

        // get latest user details
        const userData = await getUserDetails();

        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }

        dispatch(updateUserToken(res?.token));
        dispatch(setCurrentRound(+data?.round?.slice(-1)));

        if (!reportData?.error) {
          dispatch(setReportData(reportData?.report));
          dispatch(
            setSentLetters({
              isTransUnionSent: reportData?.report?.isTransUnionSent,
              isExperianSent: reportData?.report?.isExperianSent,
              isEquifaxSent: reportData?.report?.isEquifaxSent,
              isTradeLineSent: reportData?.report?.isTradeLineSent,
            })
          );
          localStorage.removeItem("pdfLink");
          localStorage.setItem("hasUploadedReport", true);
          navigate("/credit-report");
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
      setIsOpenModal(false);
    }
  }

  // set initial form values
  useEffect(() => {
    if (user) {
      setFormValues({
        firstname: user?.name?.first || "",
        lastname: user?.name?.last || "",
        email: user?.email || "",
        phone: user?.phone || "",
        year: user?.birthYear || "",
        ssn: user?.ssn || "",
        address:
          user?.currentMailingAddress?.streetLine2 +
            " " +
            user?.currentMailingAddress?.streetLine1 || "",
        city: user?.currentMailingAddress?.city || "",
        state: user?.currentMailingAddress?.state || "",
        zip: user?.currentMailingAddress?.zip || "",
      });
    }
  }, [setFormValues, user]);

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      <section>
        {/* info video alert */}
        <div className="wavesBtnWrapper posFixed">
          <div class="waves wave-1" />
          <div class="waves wave-2" />
          <div class="waves wave-3" />
          <Button
            color="link"
            className="infoVideoBtn"
            onClick={() => setInfoVideoModal(true)}
          >
            <img src={require("../../assets/img/play-button.png")} alt="" />
          </Button>
        </div>

        <h6 className="text-secondary">
          Hey there! You need to verify your personal information before we
          proceed.
        </h6>
        <Row>
          <Col md="6" lg="4">
            {/* first name */}
            <div className="form-group mb-2">
              <FormGroup check>
                <Input
                  id="checkbox2"
                  type="checkbox"
                  disabled={loading}
                  name="firstnameCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["firstnameCheck"]}
                />
                <Label for="checkbox2">First Name</Label>
              </FormGroup>

              <Input
                placeholder="Enter"
                className="mt-1"
                name="firstname"
                value={formValues["firstname"]}
                disabled={loading}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["firstname"] ? (
                <p className="form-error">{formErrors["firstname"]}</p>
              ) : null}
            </div>
          </Col>

          <Col md="6" lg="4">
            {/* last name */}
            <div className="form-group">
              <FormGroup check>
                <Input
                  id="checkbox3"
                  type="checkbox"
                  disabled={loading}
                  name="lastnameCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["lastnameCheck"]}
                />
                <Label for="checkbox3">Last Name</Label>
              </FormGroup>

              <Input
                placeholder="Enter"
                className="mt-1"
                name="lastname"
                value={formValues["lastname"]}
                disabled={loading}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["lastname"] ? (
                <p className="form-error">{formErrors["lastname"]}</p>
              ) : null}
            </div>
          </Col>

          <Col md="6" lg="4">
            {/* Email */}
            <div className="form-group">
              <FormGroup check>
                <Input
                  id="checkbox4"
                  type="checkbox"
                  disabled={loading}
                  name="emailCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["emailCheck"]}
                />
                <Label for="checkbox4">Email</Label>
              </FormGroup>

              <Input
                placeholder="Enter"
                className="mt-1"
                name="email"
                value={formValues["email"]}
                disabled={loading}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["email"] ? (
                <p className="form-error">{formErrors["email"]}</p>
              ) : null}
            </div>
          </Col>

          <Col md="6" lg="4">
            {/* phone number */}
            <div className="form-group">
              <FormGroup check>
                <Input
                  id="checkbox5"
                  type="checkbox"
                  disabled={loading}
                  name="phoneCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["phoneCheck"]}
                />
                <Label for="checkbox5">Phone Number</Label>
              </FormGroup>

              <Input
                placeholder="Enter"
                className="mt-1"
                name="phone"
                value={formValues["phone"]}
                disabled={loading}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["phone"] ? (
                <p className="form-error">{formErrors["phone"]}</p>
              ) : null}
            </div>
          </Col>

          <Col md="6" lg="4">
            {/* Year of Birth */}
            <div className="form-group">
              <FormGroup check>
                <Input
                  id="checkbox6"
                  type="checkbox"
                  disabled={loading}
                  name="yearCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["yearCheck"]}
                />
                <Label for="checkbox6">Year of Birth</Label>
              </FormGroup>

              <Input
                type="select"
                name="year"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                value={formValues["year"]}
                disabled={loading}
              >
                <YearOptions />
              </Input>

              {formErrors["year"] ? (
                <p className="form-error">{formErrors["year"]}</p>
              ) : null}
            </div>
          </Col>

          <Col md="6" lg="4">
            {/* Last 4 SSN */}
            <div className="form-group">
              <FormGroup check>
                <Input
                  id="checkbox7"
                  type="checkbox"
                  disabled={loading}
                  name="ssnCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["ssnCheck"]}
                />
                <Label for="checkbox7" className="labelTooltip">
                  SSN Number
                  <i
                    className="fas fa-shield-alt"
                    id="UncontrolledTooltipExample"
                  ></i>
                  <UncontrolledTooltip
                    placement="left"
                    autohide={false}
                    target="UncontrolledTooltipExample"
                  >
                    Your SSN is used to connect your credit report to your
                    profile within the dashboard. This data is both secure and
                    encrypted.
                  </UncontrolledTooltip>
                </Label>
              </FormGroup>

              <InputGroup>
                <Input
                  placeholder="Enter"
                  type="text"
                  name="ssn"
                  disabled={loading}
                  value={
                    showFullSSN
                      ? formValues["ssn"]
                      : "XXXXX" + formValues["ssn"]?.slice(-4)
                  }
                  onFocus={(e) => (e.target.value = formValues["ssn"])}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (!showFullSSN) {
                      setShowFullSSN(true);
                    }
                  }}
                  onBlur={(e) => {
                    handleInputBlur(e);
                    if (!formErrors["ssn"]) {
                      setShowFullSSN(false);
                    }
                  }}
                />
              </InputGroup>

              {formErrors["ssn"] ? (
                <p className="form-error">{formErrors["ssn"]}</p>
              ) : null}
            </div>
          </Col>
        </Row>

        {/* Current Address */}
        <div className="form-group">
          <FormGroup check>
            <Input
              id="checkbox8"
              type="checkbox"
              disabled={loading}
              name="addressCheck"
              onChange={_handleChangeCheckbox}
              checked={checkboxes["addressCheck"]}
            />
            <Label for="checkbox8">
              Current Address (Your current residence address)
            </Label>
          </FormGroup>

          <Input
            type="textarea"
            placeholder="Enter"
            className="mt-1"
            name="address"
            value={formValues["address"]}
            disabled={loading}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />

          {formErrors["address"] ? (
            <p className="form-error">{formErrors["address"]}</p>
          ) : null}
        </div>

        <Row className="gy-3 gy-xl-0">
          <Col lg="6" xl="4">
            {/* State */}
            <div className="form-group mb-0">
              <FormGroup check>
                <Input
                  id="checkbox10"
                  type="checkbox"
                  disabled={loading}
                  name="stateCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["stateCheck"]}
                />
                <Label for="checkbox10">State</Label>
              </FormGroup>

              <Input
                type="select"
                name="state"
                value={formValues["state"]}
                disabled={loading}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              >
                <StateOptions />
              </Input>

              {formErrors["state"] ? (
                <p className="form-error">{formErrors["state"]}</p>
              ) : null}
            </div>
          </Col>

          <Col lg="6" xl="4">
            {/* City */}
            <div className="form-group mb-0">
              <FormGroup check>
                <Input
                  id="checkbox9"
                  type="checkbox"
                  disabled={loading}
                  name="cityCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["cityCheck"]}
                />
                <Label for="checkbox9">City</Label>
              </FormGroup>

              <Input
                type="select"
                name="city"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                value={formValues["city"]}
                disabled={loading}
              >
                <CityOptions state={formValues["state"]} />
              </Input>

              {formErrors["city"] ? (
                <p className="form-error">{formErrors["city"]}</p>
              ) : null}
            </div>
          </Col>

          <Col lg="6" xl="4">
            {/* Zip */}
            <div className="form-group mb-0">
              <FormGroup check>
                <Input
                  id="checkbox11"
                  type="checkbox"
                  disabled={loading}
                  name="zipCheck"
                  onChange={_handleChangeCheckbox}
                  checked={checkboxes["zipCheck"]}
                />
                <Label for="checkbox11">Zip</Label>
              </FormGroup>

              <Input
                placeholder="Enter"
                className="mt-1"
                name="zip"
                value={formValues["zip"]}
                disabled={loading}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              {formErrors["zip"] ? (
                <p className="form-error">{formErrors["zip"]}</p>
              ) : null}
            </div>
          </Col>
        </Row>

        <FormGroup check className="mt-4">
          <Input
            id="checkbox12"
            type="checkbox"
            disabled={loading}
            name="confirmCheck"
            onChange={_handleChangeCheckbox}
            checked={checkboxes["confirmCheck"]}
          />
          <Label for="checkbox12">
            I solemnly confirm all the information provided is true to the best
            of my knowledge
          </Label>
        </FormGroup>

        {/* submit button */}
        <div className="inlineBtnWrapper mt-5">
          <Button
            color="primary"
            className="btn-submit"
            onClick={handleSubmit}
            disabled={loading || !allCheckboxesChecked}
          >
            {loading ? (
              <div className="d-flex justify-content-center align-items-center gap-2">
                <Spinner />
                Processing...
              </div>
            ) : (
              "Proceed"
            )}
          </Button>
        </div>
      </section>

      {isOpenModal ? (
        <LoaderModal
          title="Analyzing Credit Report"
          texts={analyzingModalTexts}
          isOpen={isOpenModal}
          toggle={() => _toggleModal()}
        />
      ) : null}

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title="Account Verification?"
          content="You must cross-verify your account details before starting the dispute analysis process"
        />
      ) : null}
    </>
  );
};

export default AccountVerification;
