export const damageEstimateFormConfig = {
  fields: [
    {
      type: "radio",
      label: "Q1. Have you ever applied for a Credit Card?",
      name: "appliedForCreditCard",
      isAmountField: false,
      buttons: ["Yes", "No", "Not Applicable"],
    },
    {
      id: "creditCardApplicationsCount",
      type: "text",
      label: "Q2. How many times?",
      isAmountField: false,
      name: "creditCardApplicationsCount",
    },
    {
      id: "creditCardAmountAppliedFor",
      type: "text",
      label: "Q3. What was the amount you applied for?",
      isAmountField: true,
      name: "creditCardAmountAppliedFor",
    },
    {
      id: "creditCardApplicationsDeniedCount",
      type: "text",
      label: "Q4. How many times have you been denied?",
      isAmountField: false,
      name: "creditCardApplicationsDeniedCount",
    },
    {
      type: "radio",
      label: "Q5. Have you ever applied for a Car Loan?",
      isAmountField: false,
      name: "appliedForCarLoan",
      buttons: ["Yes", "No", "Not Applicable"],
    },
    {
      id: "carLoanApplicationsCount",
      type: "text",
      label: "Q6. How many times?",
      isAmountField: false,
      name: "carLoanApplicationsCount",
    },
    {
      id: "carLoanAmountAppliedFor",
      type: "text",
      label: "Q7. What was the amount you applied for?",
      isAmountField: true,
      name: "carLoanAmountAppliedFor",
    },
    {
      id: "carLoanApplicationsDeniedCount",
      type: "text",
      label: "Q8. How many times have you been denied?",
      isAmountField: false,
      name: "carLoanApplicationsDeniedCount",
    },
    {
      type: "radio",
      label: "Q9. Have you ever applied for a Home Loan?",
      name: "appliedForHomeLoan",
      isAmountField: false,
      buttons: ["Yes", "No", "Not Applicable"],
    },
    {
      id: "homeLoanApplicationsCount",
      type: "text",
      label: "Q10. How many times?",
      isAmountField: false,
      name: "homeLoanApplicationsCount",
    },
    {
      id: "homeLoanAmountAppliedFor",
      type: "text",
      label: "Q11. What was the amount you applied for?",
      isAmountField: true,
      name: "homeLoanAmountAppliedFor",
    },
    {
      id: "homeLoanApplicationsDeniedCount",
      type: "text",
      label: "Q12. How many times have you been denied?",
      isAmountField: false,
      name: "homeLoanApplicationsDeniedCount",
    },
    {
      type: "radio",
      label: "Q13. Have you ever applied for a Medical Loan?",
      name: "appliedForMedicalLoan",
      isAmountField: false,
      buttons: ["Yes", "No", "Not Applicable"],
    },
    {
      id: "medicalLoanType",
      type: "text",
      label: "Q14. What is the type of Medical Loan you applied for?",
      isAmountField: false,
      name: "medicalLoanType",
    },
    {
      id: "medicalLoanApplicationsCount",
      type: "text",
      label: "Q15. How many times?",
      isAmountField: false,
      name: "medicalLoanApplicationsCount",
    },
    {
      id: "medicalLoanAmountAppliedFor",
      type: "text",
      label: "Q16. What was the amount you applied for?",
      isAmountField: true,
      name: "medicalLoanAmountAppliedFor",
    },
    {
      id: "medicalLoanApplicationsDeniedCount",
      type: "text",
      label: "Q17. How many times have you been denied?",
      isAmountField: false,
      name: "medicalLoanApplicationsDeniedCount",
    },
  ],
};
