import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { hasSubscription } from "guards/subscription";
import useDisputeHistory from "hooks/useDisputeHistory";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { DisputeHistoryFilter, DisputesTable } from "components/disputes";
import { Fallback, InfoTooltip } from "components/common";

const Disputes = ({ leadId, data, userType }) => {
  const [infoVideoModal, setInfoVideoModal] = useState(false);

  // hooks
  const { currentRound } = useSelector((state) => state?.reportData || {});
  const { disputes, loading, fetchDisputes, handleViewReport } =
    useDisputeHistory(data, userType, leadId);

  // show video and tooltip only if sending letter is pending for any report
  const showVideoAndTooltip = disputes?.some(
    (dispute) =>
      !(
        dispute?.isTransUnionSent &&
        dispute?.isExperianSent &&
        dispute?.isEquifaxSent &&
        dispute?.isTradeLineSent
      )
  );

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      {/* info tooltip */}
      {showVideoAndTooltip && (
        <InfoTooltip
          content={`Please generate & send the pending letters from Round ${currentRound} to upload
          your credit report after 40 days`}
        />
      )}

      {/* info video */}
      {showVideoAndTooltip && (
        <div className="wavesBtnWrapper posFixed">
          <div className="waves wave-1" />
          <div className="waves wave-2" />
          <div className="waves wave-3" />
          <Button
            color="link"
            className="infoVideoBtn"
            onClick={() => setInfoVideoModal(true)}
          >
            <img src={require("../../assets/img/play-button.png")} alt="" />
          </Button>
        </div>
      )}

      {/* filter */}
      <DisputeHistoryFilter
        onApplyFilter={(filters) => fetchDisputes(filters)}
        onClearFilter={fetchDisputes}
      />

      {/* Disputes Table */}
      {loading || !disputes?.length ? (
        <Fallback loading={loading} />
      ) : (
        <DisputesTable
          userType={userType}
          disputes={disputes}
          onViewReport={handleViewReport}
        />
      )}

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title="Dispute History"
          content="Generate & send the pending letters to complete the process and upload your credit report after 40 days"
        />
      ) : null}
    </>
  );
};

export default Disputes;
