import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledCollapse,
} from "reactstrap";

const DocumentReference = ({ photoId, addressProof }) => {
  return (
    <section>
      <Card className="cardToggler">
        <CardHeader id="documentToggler">
          <CardTitle>Document Referance</CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#documentToggler">
          <CardBody>
            <div className="docReferenceBox">
              <div>
                <img
                  src={require("../../assets/img/icon1-primary.svg").default}
                  alt=""
                />
                <a
                  href={photoId}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  Identity Proof
                </a>
              </div>

              <div>
                <img
                  src={require("../../assets/img/icon1-primary.svg").default}
                  alt=""
                />
                <a
                  href={addressProof}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  Address Proof
                </a>
              </div>
            </div>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </section>
  );
};

export default DocumentReference;
