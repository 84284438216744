import { List } from "reactstrap";

const FeatureList = ({ list }) => {
  return (
    <List>
      {list?.map((item, index) => (
        <li key={index}>
          <img src={item?.icon} alt="" />
          <span>{item?.title}</span>
        </li>
      ))}
    </List>
  );
};

export default FeatureList;
