import React from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";

const AffiliateReferredClients = () => {
  return (
    <>
      <Card className="commonCard">
        <CardHeader>
          Referred Clients
          <span className="fw-normal fs-14">Total Commission - $2</span>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date </th>
                <th>Commission </th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Yuvraj Singh</td>
                <td>Nov 07</td>
                <td>$1</td>
                <td>Active</td>
              </tr>
            </tbody>
          </Table>
          {/* <PaginatedItems
            items={4}
            totalItems={10}
            paginatePayload={() => {}}
            onPageChange={() => {}}
          /> */}
        </CardBody>
      </Card>
    </>
  );
};

export default AffiliateReferredClients;
