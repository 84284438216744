import OneSignal from "react-onesignal";
import { ONE_SIGNAL_APP_ID } from "config";

export { default as OneSignalHelper } from "./onesignal-helper";

export default async function runOneSignal() {
  await OneSignal.init({
    appId: ONE_SIGNAL_APP_ID,
    safari_web_id: "web.onesignal.auto.1b5ff574-1f63-4acf-ab26-dadb313db610",
    notifyButton: {
      enable: true,
    },
    allowLocalhostAsSecureOrigin: true,
  });
}
