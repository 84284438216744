import { CircuitOptions, CircuitStateOptions } from "components/form-options";
import { CASE_TYPE_OPTIONS } from "config/questionnaire/case-type-options";
import Select from "react-select";
import { Input, InputGroup, InputGroupText, Label } from "reactstrap";

const LeadFiltersForm = ({ filters, onChangeFilter }) => {
  return (
    <div className="filterForm">
      <div className="formGroup">
        <Label>Circuit</Label>
        <Input
          type="select"
          value={filters.circuit}
          onChange={(e) => onChangeFilter("circuit", e.target.value)}
        >
          <CircuitOptions />
        </Input>
      </div>

      <div className="formGroup">
        <Label>State</Label>
        <Input
          type="select"
          value={filters.states}
          onChange={(e) => onChangeFilter("states", e.target.value)}
        >
          <CircuitStateOptions circuitName={filters.circuit} />
        </Input>
      </div>

      <div className="formGroup">
        <Label>Credit</Label>
        <Input
          type="select"
          value={filters.credit}
          onChange={(e) => onChangeFilter("credit", e.target.value)}
        >
          <option value="">All</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </Input>
      </div>

      <div className="formGroup">
        <Label>Case Type</Label>
        <Select
          isMulti
          options={CASE_TYPE_OPTIONS}
          value={filters.caseType}
          className="react-select-container"
          classNamePrefix="react-select"
          onChange={(value) => onChangeFilter("caseType", value)}
        />
      </div>

      <div className="formGroup">
        <Label>Status</Label>
        <Input
          type="select"
          value={filters.status}
          onChange={(e) => onChangeFilter("status", e.target.value)}
        >
          <option value="">All</option>
          <option value="unlocked">Unlocked</option>
          <option value="locked">Locked</option>
        </Input>
      </div>

      {/* search */}
      <div className="formGroup">
        <Label>Search</Label>
        <InputGroup>
          <Input
            placeholder="Search..."
            value={filters.search}
            onChange={(e) => onChangeFilter("search", e.target.value)}
          />
          <InputGroupText>
            <i className="fas fa-search" />
          </InputGroupText>
        </InputGroup>
      </div>
    </div>
  );
};

export default LeadFiltersForm;
