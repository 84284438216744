import { AlertCard } from "components/common";
import { errorHandler } from "helper-methods";
import { dismissReminderAlert, getUserDetails } from "http/http-calls";
import { useDispatch } from "react-redux";
import { updateUserData } from "redux/actions";

const ReminderAlerts = ({ userId, messages }) => {
  const dispatch = useDispatch();

  const _onDismissAlert = async (msgId) => {
    try {
      const res = await dismissReminderAlert({
        id: userId,
        payload: { msgId },
      });
      if (!res?.error) {
        // get latest user details
        const userData = await getUserDetails();
        if (!userData?.error) {
          dispatch(updateUserData(userData?.user));
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {messages.map((message) => (
        <AlertCard
          key={message?._id}
          theme="warning"
          onDismiss={_onDismissAlert.bind(this, message?._id)}
          content={message?.msg}
        />
      ))}
    </>
  );
};

export default ReminderAlerts;
