import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Input,
  Form,
  Label,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  FormGroup,
  Spinner,
} from "reactstrap";
import PublicFooter from "containers/Public/PublicFooter";
import useFormValidation from "hooks/useFormValidation";
import { validateSignup } from "lib/form-validation";
import { errorHandler, showToast } from "helper-methods";
import { checkDuplicate, registerUser } from "http/http-calls";
import { useDispatch } from "react-redux";
import { addUserCredential } from "redux/actions";
import useDebounce from "hooks/useDebounce";
import { useEffect } from "react";
import { regexConfig } from "config/regex-config";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";

const Register = () => {
  // states
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    formValues,
    formErrors,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    setFormErrors,
  } = useFormValidation(
    {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      terms: false,
    },
    validateSignup,
    _onSignup
  );
  const debouncedEmailValue = useDebounce(formValues["email"], 300);

  // handle blur logic separately for email due to api call
  const _handleBlur = (e) => {
    const { value } = e?.target;
    const trimmedValue = value?.trim();

    if (!trimmedValue?.length) {
      setFormErrors((prev) => ({ ...prev, email: "*Required" }));
    } else if (!regexConfig?.email?.test(trimmedValue)) {
      setFormErrors((prev) => ({ ...prev, email: "*Invalid email address" }));
    }
  };

  // check duplicate email
  const _checkDuplicate = async (type) => {
    try {
      const payload = type === "email" ? { email: debouncedEmailValue } : null;

      const res = await checkDuplicate(payload);

      if (!res?.error) {
        const newErrors = { ...formErrors };
        delete newErrors[type];
        setFormErrors(newErrors);
      }
    } catch (error) {
      setFormErrors({ ...formErrors, [type]: `*${error?.reason}` });
    }
  };

  // handle signup
  async function _onSignup() {
    try {
      setLoading(true);

      const payload = {
        name: {
          first: formValues["firstname"]?.trim(),
          last: formValues["lastname"]?.trim(),
        },
        email: formValues["email"]?.trim(),
        password: formValues["password"]?.trim(),
      };

      const res = await registerUser(payload);

      if (!res?.error) {
        dispatch(
          addUserCredential({
            token: res?.token,
            user: res?.user,
          })
        );
        showToast("Signed up successfully", "success", 2000);
        navigate("/subscription");
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  // effect to check for duplicate email
  useEffect(() => {
    if (debouncedEmailValue?.length && !formErrors["email"]) {
      _checkDuplicate("email");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmailValue]);

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          <div className="leftWrapper justify-content-start">
            <div className="leftInnerWrapper">
              <h2>Welcome!</h2>
              <p className="text-secondary">
                Please enter the following details.
              </p>
              <div className="sign-up-info">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      {/* name */}
                      <div className="form-group">
                        <Label>First Name</Label>
                        <Input
                          placeholder="Enter"
                          name="firstname"
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          value={formValues["firstname"]}
                        />
                        {formErrors["firstname"] ? (
                          <p className="form-error">
                            {formErrors["firstname"]}
                          </p>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="6">
                      {/* name */}
                      <div className="form-group">
                        <Label>Last Name</Label>
                        <Input
                          placeholder="Enter"
                          name="lastname"
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          value={formValues["lastname"]}
                        />
                        {formErrors["lastname"] ? (
                          <p className="form-error">{formErrors["lastname"]}</p>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  {/* email */}
                  <div className="form-group">
                    <Label>Email Address</Label>
                    <Input
                      placeholder="Enter"
                      name="email"
                      onChange={handleInputChange}
                      onBlur={_handleBlur}
                      value={formValues["email"]}
                    />
                    {formErrors["email"] ? (
                      <p className="form-error">{formErrors["email"]}</p>
                    ) : null}
                  </div>

                  {/* password */}
                  <div className="form-group">
                    <Label className="labelTooltip">
                      Password
                      <i
                        className="fa fa-lock"
                        id="UncontrolledTooltipExample2"
                      />
                      <UncontrolledTooltip
                        placement="right"
                        autohide={false}
                        target="UncontrolledTooltipExample2"
                      >
                        Your data is safeguarded using the highest level of
                        industry-grade encryption for protection.
                      </UncontrolledTooltip>
                    </Label>
                    <InputGroup>
                      <Input
                        placeholder="Enter"
                        type={`${showPassword ? "text" : "password"}`}
                        name="password"
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        value={formValues["password"]}
                      />
                      <InputGroupText
                        className="cursorPointer"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <i
                          className={`far ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                        />
                      </InputGroupText>
                    </InputGroup>

                    {formErrors["password"] ? (
                      <p className="form-error">{formErrors["password"]}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <FormGroup check className="align-items-start mt-2">
                      <Input
                        id="checkbox1"
                        type="checkbox"
                        className="mt-1"
                        name="terms"
                        checked={formValues["terms"]}
                        onChange={handleInputChange}
                      />
                      <Label
                        for="checkbox1"
                        style={{ lineHeight: "1.8" }}
                        className="d-flex flex-wrap"
                      >
                        By clicking, you agree to our
                        <a href={window.location.href} className="mx-1">
                          Terms and Conditions
                        </a>
                        and
                        <a href={window.location.href} className="mx-1">
                          Privacy Policy
                        </a>
                        Statement.
                      </Label>
                    </FormGroup>
                  </div>

                  {/* submit button */}
                  <Button
                    color="primary"
                    className="btn-submit"
                    disabled={loading || !formValues["terms"]}
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <Spinner />
                        Next
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Button>

                  {/*infoText*/}
                  <div className="text-center fs-14 mt-3 fw-medium">
                    Already have an account?
                    <Link to="/login" className="ms-1">
                      Sign In
                    </Link>
                  </div>

                  {/* data secure lock icon */}
                  <div className="dataSecureLockIcon mt-3">
                    <i className="fas fa-shield-alt mt-0" />
                    <p>
                      Your data is safeguarded using the highest level of
                      industry-grade encryption for protection.
                    </p>
                  </div>
                </Form>
              </div>
            </div>

            {/* info video */}
            <div className="wavesBtnWrapper posFixed position-md-absolute">
              <div className="waves wave-1" />
              <div className="waves wave-2" />
              <div className="waves wave-3" />
              <Button
                color="link"
                className="infoVideoBtn"
                onClick={() => setInfoVideoModal(true)}
              >
                <img src={require("../../assets/img/play-button.png")} alt="" />
              </Button>
            </div>
          </div>

          <div className="rightWrapper">
            {/* logo */}
            <img
              src={require("../../assets/img/logo-white.png")}
              alt="Brand Logo"
              className="companyLogo"
            />
          </div>
        </div>

        {infoVideoModal ? (
          <InfoVideoAlertModal
            isOpen={infoVideoModal}
            toggle={() => setInfoVideoModal(false)}
            title="Welcome to Score Clear"
            content="Please enter the following details to create an account."
          />
        ) : null}

        {/* footer */}
        <PublicFooter />
      </div>
    </>
  );
};

export default Register;
