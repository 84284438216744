import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Navigate, useSearchParams } from "react-router-dom";
import { hasSubscription } from "guards/subscription";
import InfoVideoAlertModal from "components/modals/InfoVideoAlertModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SentLettersContainer } from "components/sent-letters";
import { InfoTooltip } from "components/common";

const SentLetters = ({ leadId, userType }) => {
  // states
  const [infoVideoModal, setInfoVideoModal] = useState(false);
  const [params, setSearchParams] = useSearchParams({ letterType: "bureau" });

  const { currentRound } = useSelector((state) => state?.reportData || {});

  // constants
  const isFirstRound = currentRound === 1;
  const videoModalContent = isFirstRound
    ? "Upload the response sent by Bureaus & Tradelines"
    : "You can upload the response sent by Bureaus & Tradelines to keep a log";

  // redirect to subscription page if subscription is not active
  if (!hasSubscription()) {
    return <Navigate to={"/subscription"} />;
  }

  return (
    <>
      {/* info tooltip */}
      {isFirstRound && (
        <InfoTooltip content="Please upload the response you receive from the Bureaus & Tradelines." />
      )}

      {/* info video */}
      {userType !== "Attorney" && (
        <div className="wavesBtnWrapper posFixed">
          <div className="waves wave-1" />
          <div className="waves wave-2" />
          <div className="waves wave-3" />
          <Button
            color="link"
            className="infoVideoBtn"
            onClick={() => setInfoVideoModal(true)}
          >
            <img src={require("../../assets/img/play-button.png")} alt="" />
          </Button>
        </div>
      )}

      <div className="disputes_tab">
        <Nav pills>
          <NavItem>
            <NavLink
              className={params.get("letterType") === "bureau" ? "active" : ""}
              onClick={() =>
                userType === "Attorney"
                  ? setSearchParams({ type: "letter", letterType: "bureau" })
                  : setSearchParams({ letterType: "bureau" })
              }
            >
              Bureaus
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={
                params.get("letterType") === "tradeline" ? "active" : ""
              }
              onClick={() =>
                userType === "Attorney"
                  ? setSearchParams({ type: "letter", letterType: "tradeline" })
                  : setSearchParams({ letterType: "tradeline" })
              }
            >
              Tradelines
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent>
          <TabPane>
            <SentLettersContainer
              type={params.get("letterType")}
              leadId={leadId}
              userType={userType}
            />
          </TabPane>
        </TabContent>
      </div>

      {infoVideoModal ? (
        <InfoVideoAlertModal
          isOpen={infoVideoModal}
          toggle={() => setInfoVideoModal(false)}
          title="Sent Letters"
          content={videoModalContent}
        />
      ) : null}
    </>
  );
};

export default SentLetters;
