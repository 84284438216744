import { copyTextToClipboard, showToast } from "helper-methods";
import { getCaseTypes } from "helper-methods/leads-helper";
import { UncontrolledTooltip } from "reactstrap";

const UnlockedLead = ({ lead }) => {
  const leadName = lead?.name?.full;
  const id = lead?._id;
  const email = lead?.email;
  const phone = lead?.phone;
  const credits = lead?.credits;
  const caseTypes = getCaseTypes(lead?.caseQuestion);
  const circuit = lead?.currentMailingAddress?.circuit;
  const state = lead?.currentMailingAddress?.state;
  const damageEstimates = lead?.damageEstimates;
  const {
    accountHistoryDisputeCount,
    inquiriesDisputeCount,
    profileDisputecount,
  } = lead?._fourthReport || {};
  const totalDefendants =
    accountHistoryDisputeCount + inquiriesDisputeCount + profileDisputecount;

  const _copyText = (text, message) => {
    copyTextToClipboard(text);
    showToast(message, "success", 2000, "copy");
  };

  return (
    <tr>
      <td>
        <div>
          <span>{leadName}</span>
          <br />
          <img
            src={require("../../assets/img/phone.svg").default}
            alt=""
            id={`phone_${id}`}
            role="button"
            width="15px"
            className="me-2"
            onClick={() => _copyText(phone, "Phone number copied to clipboard")}
          />
          <UncontrolledTooltip
            placement="bottom"
            autohide={false}
            target={`phone_${id}`}
            className="placement-bottom"
          >
            {phone}
          </UncontrolledTooltip>
          <img
            src={require("../../assets/img/mail.svg").default}
            alt=""
            id={`mail_${id}`}
            width="15px"
            role="button"
            onClick={() => _copyText(email, "Email copied to clipboard")}
          />
          <UncontrolledTooltip
            placement="bottom"
            autohide={false}
            target={`mail_${id}`}
            className="placement-bottom"
          >
            {email}
          </UncontrolledTooltip>
        </div>
      </td>
      <td>{credits}</td>
      <td>{caseTypes}</td>
      <td>{circuit}</td>
      <td>{state}</td>
      <td>${damageEstimates}</td>
      <td>{totalDefendants || "N/A"}</td>
    </tr>
  );
};

export default UnlockedLead;
