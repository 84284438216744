import { createSlice } from "@reduxjs/toolkit";
import {
  setNotifications,
  setNotificationsCount,
  setNotificationsUnreadCount,
} from "redux/actions";

const initialState = {
  notifications: [],
  count: 0,
  unread: 0,
};

const notificationDataSlice = createSlice({
  name: "notificationData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNotifications, (state, action) => {
      return { ...state, notifications: action.payload };
    });

    builder.addCase(setNotificationsCount, (state, action) => {
      state.count = action.payload;
    });

    builder.addCase(setNotificationsUnreadCount, (state, action) => {
      state.unread = action.payload;
    });
  },
});

export const notificationDataReducer = notificationDataSlice.reducer;
