import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "components/routes/ProtectedRoute";
import DashboardPage from "pages/protected/DashboardPage";
import DashboardFooter from "./DashboardFooter";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import CreditReport from "pages/protected/CreditReport";
import MyProfile from "pages/protected/MyProfile";
import ManageSubscription from "pages/protected/ManageSubscription";
import Disputes from "pages/protected/Disputes";
import Payment from "pages/protected/Payment";
import AccountVerification from "pages/protected/AccountVerification";
import GenerateLetter from "pages/protected/GenerateLetter";
import PreviewLetter from "pages/protected/PreviewLetter";
import Leads from "pages/protected/Attorney/Leads";
import UnlockedLeads from "pages/protected/Attorney/UnlockedLeads";
import ManageSubscriptionAttorney from "pages/protected/Attorney/ManageSubscription";
import ViewProfile from "pages/protected/Attorney/ViewProfile";
import Notifications from "pages/protected/Notifications";
import AffiliateDashboard from "pages/protected/Affiliate/AffiliateDashboard";
import TrainingProgram from "pages/protected/TrainingProgram";
import { errorHandler } from "helper-methods";
import { getAllNotifications } from "http/http-calls";
import { useDispatch } from "react-redux";
import {
  setNotifications,
  setNotificationsCount,
  setNotificationsUnreadCount,
} from "redux/actions";
import SentLetters from "pages/protected/SentLetters";
import MediaQueueViewer from "containers/MediaQueueViewer";
import LeadsDetails from "pages/protected/Attorney/LeadsDetails";

const DashboardLayout = ({ props }) => {
  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();

  // get all notifications
  useEffect(() => {
    const _fetchNotifications = async () => {
      try {
        const res = await getAllNotifications();
        if (!res?.error) {
          dispatch(setNotifications(res?.notifications));
          dispatch(setNotificationsCount(res?.notificationCount));
          dispatch(setNotificationsUnreadCount(res?.unreadNotificationCount));
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    _fetchNotifications();
  }, [dispatch]);

  return (
    <>
      <div className={`mainWrapper ${!isShow ? "show" : ""}`}>
        <DashboardSidebar isShow={isShow} setIsShow={setIsShow} />
        <DashboardHeader isShow={isShow} setIsShow={setIsShow} />
        <div className="innerWrapper">
          <Routes>
            <Route
              path=""
              element={<ProtectedRoute redirectRoute={"/login"} />}
            >
              <Route exact path="/dashboard" element={<DashboardPage />} />
              <Route exact path="/credit-report" element={<CreditReport />} />
              <Route exact path="/my-profile" element={<MyProfile />} />
              <Route exact path="/lead/:id" element={<LeadsDetails />} />
              <Route exact path="/disputes" element={<Disputes />} />
              <Route
                exact
                path="/generate-letter"
                element={<GenerateLetter />}
              />
              <Route exact path="/preview-letter" element={<PreviewLetter />} />
              <Route exact path="/payment" element={<Payment />} />
              <Route
                exact
                path="/account-verification"
                element={<AccountVerification />}
              />
              <Route
                exact
                path="/manage-subscription"
                element={<ManageSubscription />}
              />
              {/* Attorney */}
              <Route exact path="/leads" element={<Leads />} />
              <Route exact path="/unlocked-leads" element={<UnlockedLeads />} />
              <Route exact path="/notifications" element={<Notifications />} />
              <Route
                exact
                path="/manage-subscription-attorney"
                element={<ManageSubscriptionAttorney />}
              />
              <Route exact path="/view-profile" element={<ViewProfile />} />
              <Route
                exact
                path="/affiliate-dashboard"
                element={<AffiliateDashboard />}
              />
              <Route
                exact
                path="/training-program"
                element={<TrainingProgram />}
              />
              <Route exact path="/sent-letters" element={<SentLetters />} />
            </Route>

            <Route path="*" element={<Navigate replace to="/dashboard" />} />
          </Routes>

          <MediaQueueViewer />
        </div>

        <DashboardFooter />
      </div>
    </>
  );
};

export default DashboardLayout;
