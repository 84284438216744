import React from "react";
import {
  Button,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { subscriptionFeatures } from "config/helper-config";
import BillingAddressForm from "./BillingAddressForm";
import { FeatureList } from "components/common";

const BillingAddress = ({
  loading,
  onBuyMembership,
  formValues,
  formErrors,
  plan,
  onChange,
  onBlur,
  isAttorney = false,
}) => {
  const featureList = isAttorney
    ? subscriptionFeatures?.attorney?.[plan]
    : subscriptionFeatures?.user?.[plan];

  return (
    <Col md="12" xl="6">
      <CardTitle>Billing Address</CardTitle>

      <BillingAddressForm
        formValues={formValues}
        formErrors={formErrors}
        onChange={onChange}
        onBlur={onBlur}
      />

      <hr />

      <section className="mt-4 mb-0 features-list inline-list">
        <h6>Features</h6>
        <div className="features-list">
          <FeatureList list={featureList} />
        </div>
      </section>

      <section>
        <FormGroup check className="mt-5 align-items-start">
          <Input
            id="checkbox1"
            type="checkbox"
            className="mt-1"
            name="terms"
            checked={formValues["terms"]}
            onChange={onChange}
          />
          <Label
            for="checkbox1"
            style={{ lineHeight: "1.8" }}
            className="flex-wrap d-flex"
          >
            By clicking, you agree to our
            <a href={window.location.href} className="mx-1">
              Terms and Conditions
            </a>
            and
            <a href={window.location.href} className="mx-1">
              Privacy Policy
            </a>
            Statement.
          </Label>
        </FormGroup>

        {/* submit button */}
        <div className="inlineBtnWrapper">
          <Button
            color="primary"
            className="mt-4 btn-submit"
            onClick={onBuyMembership}
            disabled={loading || !formValues["terms"]}
          >
            {loading ? (
              <div className="gap-2 d-flex justify-content-center align-items-center">
                <Spinner />
                Make Payment
              </div>
            ) : (
              "Make Payment"
            )}
          </Button>
        </div>

        {/* data secure lock icon */}
        <div className="mt-3 dataSecureLockIcon">
          <i className="mt-0 fas fa-shield-alt"></i>
          <p>
            Your data is safeguarded using the highest level of industry-grade
            encryption for protection.
          </p>
        </div>
      </section>
    </Col>
  );
};

export default BillingAddress;
