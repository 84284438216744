import moment from "moment";
import { Button, Input, Label } from "reactstrap";
import { useState } from "react";
import UploadedResonsesModal from "components/modals/UploadedResonsesModal";

const SentLetter = ({
  letter,
  onResponseUpload,
  onDownload,
  round,
  type,
  userType,
}) => {
  // states
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isResponseUploaded = letter?._responseLetter?.length > 0;
  const isNoResponseLetterSent = letter?._noResponseLetter;

  const has30DaysPassed = moment(new Date()).isAfter(
    moment(new Date(letter?.createdAt)).add(7, "minutes")
  );

  const _toggleModal = (isOpenModal = false) => {
    setIsOpenModal(isOpenModal);
  };

  return (
    <>
      <tr key={letter?._id}>
        <td>{moment(new Date(letter?.createdAt)).format("MMM DD")}</td>
        <td>Certified</td>
        <td>{round}</td>
        <td>
          {+round === 2 && letter?.isNoResponseLetter
            ? "No Response Letter"
            : "Dispute Letter"}
        </td>
        <td>
          {type === "bureau" ? letter?.bureauName : letter?.tradeLineName}
        </td>
        <td>
          <div className="action">
            <Button
              color="link"
              onClick={() => onDownload(letter?.letterAwsLocation)}
            >
              <img
                src={require("../../assets/img/download.png")}
                alt=""
                width="18px"
              />
            </Button>

            {isResponseUploaded ? (
              <Button color="link" onClick={() => setIsOpenModal(true)}>
                Preview Response
              </Button>
            ) : null}

            {userType !== "Attorney" &&
            round !== "4" &&
            !isNoResponseLetterSent &&
            !has30DaysPassed ? (
              <Button color="link" className="btn-file">
                <Label>
                  <Input
                    type="file"
                    className="d-none"
                    onChange={(e) => onResponseUpload(e, letter?._id)}
                    accept=".pdf"
                  />
                  Upload Response
                </Label>
              </Button>
            ) : null}

            {isNoResponseLetterSent ? (
              <Button color="link">No Response Letter Sent</Button>
            ) : null}
          </div>
        </td>
      </tr>

      {isOpenModal ? (
        <UploadedResonsesModal
          isOpen={isOpenModal}
          toggle={_toggleModal}
          responses={letter?._responseLetter}
        />
      ) : null}
    </>
  );
};

export default SentLetter;
